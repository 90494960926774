// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {
  _CandidateAnalysis,
  _CandidateAnalysisSubmission,
} from '@modules/CandidateAnalysis/types/candidateAnalysis.model';
import {useLanguage} from '@modules/Translations/hooks/language';

interface _Props {
  analysis: _CandidateAnalysis;
  result: _CandidateAnalysisSubmission;
}

export const CandidateAnalysisResultItem: React.FC<_Props> = ({result, analysis}) => {
  const {currentLanguage} = useLanguage();

  return 'TODO: For stefano, this is where the result of the ca will be displayed';
};
