// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {ListItem} from '@modules/Core/components/base/inputs/ListItem';
import {Typography} from '@modules/Core/components/base/Typography';
import {logger} from '@modules/Core/util/Logger';
import {TeamProfileAvatarList} from '@modules/Team/components/common/TeamProfileAvatarList';

interface _Props {
  channels: any;
  teamProfiles: any;
  transparencyAccepted: boolean;
  trans: (channel: string) => string;
  filterByKey?: string;
}

export const TeamCommunicationChannelsList: React.FC<_Props> = ({
  channels = [],
  teamProfiles,
  trans,
  transparencyAccepted,
  filterByKey,
}) => (
  <div className="flex flex-col gap-3 w-full">
    {Object.keys(channels || {}).map((channelName, index) => {
      let profiles = [];
      const channelValues = channels[channelName];

      for (const [key, value] of Object.entries(channelValues)) {
        if (key === 'defaultList') {
          continue;
        }
        if (filterByKey && key !== filterByKey) {
          continue;
        }
        profiles = profiles.concat(channelValues[key]);
      }

      if (profiles.length === 0) {
        return null;
      }

      return (
        <ListItem
          border
          suffix={
            <TeamProfileAvatarList
              transparencyAccepted={transparencyAccepted}
              profiles={profiles}
              limit={3}
              teamProfiles={teamProfiles}
              title={trans(channelName)}
            />
          }
          key={index}
          icon={channelValues.icon}
        >
          <Typography>{trans(channelName)}</Typography>
        </ListItem>
      );
    })}
  </div>
);
