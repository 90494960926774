// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {addCN} from '@/_core/util/cssHelper';
import {IconProps, getSize} from '../types';

export const IconMotivation: React.FC<IconProps> = ({size = 'md', color = 'fill-grey700'}) => {
  const finSize = getSize(size);
  return (
    <div>
      <svg
        className={addCN(finSize, color, 'transition')}
        width="32"
        height="32"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M15.0002 20.9229H17.0129V14.8486L19.4796 17.3152L20.8719 15.923L16.013 11.064L11.1412 15.923L12.5335 17.3152L15.0002 14.8486V20.9229ZM16.0024 28.6665C14.2505 28.6665 12.6037 28.3341 11.0622 27.6692C9.5207 27.0043 8.17979 26.102 7.0395 24.9622C5.89919 23.8224 4.99644 22.4821 4.33126 20.9412C3.66609 19.4004 3.3335 17.754 3.3335 16.0021C3.3335 14.2502 3.66594 12.6035 4.33083 11.062C4.99572 9.52045 5.89805 8.17954 7.03783 7.03925C8.17763 5.89894 9.51795 4.9962 11.0588 4.33102C12.5996 3.66584 14.246 3.33325 15.9979 3.33325C17.7498 3.33325 19.3965 3.6657 20.938 4.33059C22.4796 4.99548 23.8205 5.89781 24.9608 7.03759C26.1011 8.17739 27.0038 9.51771 27.669 11.0586C28.3342 12.5994 28.6668 14.2457 28.6668 15.9977C28.6668 17.7496 28.3343 19.3963 27.6694 20.9378C27.0045 22.4793 26.1022 23.8202 24.9624 24.9605C23.8226 26.1008 22.4823 27.0036 20.9415 27.6688C19.4006 28.3339 17.7543 28.6665 16.0024 28.6665ZM16.0001 26.6666C18.9779 26.6666 21.5001 25.6332 23.5668 23.5666C25.6335 21.4999 26.6668 18.9777 26.6668 15.9999C26.6668 13.0221 25.6335 10.4999 23.5668 8.43322C21.5001 6.36655 18.9779 5.33322 16.0001 5.33322C13.0224 5.33322 10.5001 6.36655 8.43346 8.43322C6.3668 10.4999 5.33346 13.0221 5.33346 15.9999C5.33346 18.9777 6.3668 21.4999 8.43346 23.5666C10.5001 25.6332 13.0224 26.6666 16.0001 26.6666Z" />
      </svg>
    </div>
  );
};
