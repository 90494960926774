// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {addCN} from '@/_core/util/cssHelper';
import {IconProps, getSize} from '../types';

export const IconLearning: React.FC<IconProps> = ({size = 'md', color = 'fill-grey700'}) => {
  const finSize = getSize(size);
  return (
    <div>
      <svg
        className={addCN(finSize, color, 'transition')}
        width="32"
        height="32"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M16.0002 26.4614L7.3336 21.7538V14.523L2.71826 12L16.0002 4.76929L29.2822 12V21.5897H27.2822V13.1077L24.6669 14.523V21.7538L16.0002 26.4614ZM16.0002 16.9333L25.1207 12L16.0002 7.06665L6.87973 12L16.0002 16.9333ZM16.0002 24.1846L22.6669 20.5846V15.5897L16.0002 19.2283L9.33356 15.5897V20.5846L16.0002 24.1846Z" />
      </svg>
    </div>
  );
};
