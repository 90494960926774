// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {addCN} from '@/_core/util/cssHelper';
import {IconProps, getSize} from '../types';

export const IconTeamworkSupporter: React.FC<IconProps> = ({size = 'md', color = 'fill-grey700'}) => {
  const finSize = getSize(size);
  return (
    <div>
      <svg
        className={addCN(finSize, color, 'transition')}
        width="32"
        height="32"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M16 29C15.7931 29 15.6051 28.9471 15.4359 28.8411C15.2666 28.7351 15.1393 28.565 15.0538 28.3308L8.5 8.56162L12.7436 9.59238L15 7.86672V4.53338C15 3.73852 15.2902 3.06203 15.8705 2.50392C16.4508 1.94581 17.1607 1.66675 18 1.66675C18.8393 1.66675 19.5491 1.94581 20.1294 2.50392C20.7098 3.06203 20.9999 3.73852 20.9999 4.53338V5.33338H19V4.53338C19 4.25303 18.9013 4.02183 18.7038 3.83978C18.5064 3.65772 18.2718 3.56668 18 3.56668C17.7282 3.56668 17.4935 3.66198 17.2961 3.85258C17.0987 4.0432 16.9999 4.27014 16.9999 4.53338V7.86672L19.2564 9.59238L23.4999 8.56162L16.9461 28.2975C16.8606 28.5317 16.7333 28.7073 16.5641 28.8244C16.3948 28.9415 16.2068 29 16 29ZM16.9999 21.8233L20.4333 11.3256L18.8102 11.741L16.9999 10.4V21.8233ZM15 21.8233V10.4L13.1897 11.7744L11.5204 11.3128L15 21.8233Z" />
      </svg>
    </div>
  );
};
