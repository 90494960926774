// @ts-nocheck
/* eslint-disable */

import {useEffect, useRef, useState} from 'react';
import {useFeatureFlags} from '@modules/Core/hooks/featureFlags';
import useSnackbar from '@modules/Core/hooks/ui/snackbar';
import {logger} from '@modules/Core/util/Logger';
import {useNavigateToFlow} from '@modules/FlowEngine/hooks/navigateToFlow';
import {_FlowInput, _FlowSchema} from '@modules/FlowEngine/types/core.model';
import {FlowManager} from '@modules/FlowEngine/util/managers/flowManager';
import {useAppNavigate} from '@modules/Navigation/hooks/appNavigate';
import {useProfile} from '@modules/Profile/hooks/profile';

/**
 * This hook is responsible for reinitializing the flow manager whenever the flow schema or input changes
 * @param flowName
 * @param flowSchema
 * @param flowInput
 * @param stepName
 * @param onFinish
 * @param instanceKey
 * @returns {unknown}
 */
export function useInitFlowManager(
  flowName: string,
  flowSchema: _FlowSchema,
  flowInput: _FlowInput | undefined,
  stepName: string | undefined,
  onFinish: (() => void) | null,
  instanceKey: string
): FlowManager | null {
  const [flowManagers, setFlowManagers] = useState<Record<string, FlowManager | null>>({});
  const snackbar = useSnackbar();
  const {isFeatureEnabled, flags} = useFeatureFlags();
  const {navigateWithAlert} = useAppNavigate();
  const navigateToFlow = useNavigateToFlow();

  const [currentProfile] = useProfile(); // Piece of code that checks if current step should show,and if not it cycles through steps to find correct one
  // const [currentTeams] = useTeams();
  // const [currentTeam] = useTeam();

  const prevFlowSchema = useRef<_FlowSchema>();
  const prevFlowInput = useRef<_FlowInput>();
  const prevFlowName = useRef<string>();
  const lastLoadedStepName = useRef<string>();
  const flowManager = flowManagers?.[instanceKey];

  // useEffect(() => {
  //   logger.info('[useInitFlowManager] Reinitializing flow manager with new step', {
  //     instanceKey,
  //     stepName,
  //     last: lastLoadedStepName.current,
  //     bool: stepName === lastLoadedStepName.current,
  //   });
  //   if (stepName === lastLoadedStepName.current) {
  //     return;
  //   }

  //   lastLoadedStepName.current = stepName;

  //   if (flowManager) {
  //     flowManager.reInitFlow(stepName);
  //   }
  // }, [stepName, flowManager]);

  useEffect(() => {
    if (flowName !== flowSchema?.name) {
      return;
    }

    if (flowSchema?.type !== 'unauthorized' && currentProfile?.id === undefined) {
      logger.info('[useInitFlowManager] Flow schema is unauthorized and current profile is undefined');
      return;
    }

    const schemaChanged = JSON.stringify(prevFlowSchema.current) !== JSON.stringify(flowSchema);
    const inputChanged = JSON.stringify(prevFlowInput.current) !== JSON.stringify(flowInput);

    const flowNameChanged = prevFlowName.current !== flowName;
    logger.info('[useInitFlowManager] Checking if flow manager should be reinitialized', {
      schemaChanged,
      inputChanged,
      flowNameChanged,
      instanceKey,
      flowInput,
    });

    if (schemaChanged || inputChanged || flowNameChanged) {
      logger.info('[useInitFlowManager] Initializing flow manager', instanceKey);

      const instanceFlowManager = new FlowManager(
        flowName,
        instanceKey,
        flowSchema,
        flowInput,
        navigateWithAlert,
        snackbar,
        undefined,
        onFinish
      );
      instanceFlowManager.initFlow();

      setFlowManagers({
        ...flowManagers,
        [instanceKey]: instanceFlowManager,
      });

      prevFlowSchema.current = flowSchema;
      prevFlowInput.current = flowInput;
      prevFlowName.current = flowName;
    }
  }, [
    flowSchema,
    flowInput,
    flowName,
    currentProfile,
    flowManagers,
    instanceKey,
    navigateWithAlert,
    stepName,
    snackbar,
    onFinish,
  ]);

  // useEffect(() => {
  //   if (flowManager) {
  //     logger.info('[useInitFlowManager] Reinitializing flow manager with new data', instanceKey);
  //     flowManager.reInitFlow();
  //   }
  // }, [currentProfile, flowManager, instanceKey, flowInput]);

  useEffect(() => {
    if (!flowSchema?.featureFlags?.length || flags === undefined) {
      return;
    }

    if (flags === null) {
      return;
    }
    const allRequiredFlagsEnabled = (flowSchema.featureFlags ?? []).every(flag => isFeatureEnabled(flag));
    if (!allRequiredFlagsEnabled) {
      logger.debug('[useInitFlowManager] Feature flags not enabled for flow', {allRequiredFlagsEnabled});
      navigateToFlow('Error', {
        type: 'feature_not_enabled',
      });
    }
  }, [flowSchema, flags]);

  return flowManager;
}
