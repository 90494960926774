// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {addCN} from '@/_core/util/cssHelper';
import {IconProps, getSize} from '../types';

export const IconRefresh: React.FC<IconProps> = ({size = 'md', color = 'fill-grey700'}) => {
  const finSize = getSize(size);
  return (
    <div>
      <svg
        className={addCN(finSize, color, 'transition')}
        width="32"
        height="32"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M6.88957 20.8359C6.48615 20.0855 6.18145 19.3107 5.97547 18.5116C5.76949 17.7124 5.6665 16.8974 5.6665 16.0667C5.6665 13.1829 6.66992 10.7287 8.67674 8.70389C10.6836 6.67911 13.1246 5.66672 15.9998 5.66672H17.0409L14.5742 3.20002L15.9793 1.79492L20.851 6.66669L15.9793 11.5385L14.5742 10.1334L17.0409 7.66666H15.9998C13.6836 7.66666 11.7156 8.48204 10.0959 10.1128C8.47627 11.7436 7.66644 13.7282 7.66644 16.0667C7.66644 16.6188 7.72669 17.1705 7.8472 17.7218C7.96771 18.2731 8.14848 18.8111 8.3895 19.3359L6.88957 20.8359ZM16.0203 30.2051L11.1486 25.3334L16.0203 20.4616L17.4254 21.8667L14.9587 24.3334H15.9998C18.316 24.3334 20.284 23.518 21.9037 21.8872C23.5233 20.2564 24.3332 18.2718 24.3332 15.9334C24.3332 15.3812 24.2729 14.8295 24.1524 14.2782C24.0319 13.727 23.8511 13.1889 23.6101 12.6641L25.11 11.1642C25.5135 11.9146 25.8182 12.6894 26.0241 13.4885C26.2301 14.2876 26.3331 15.1026 26.3331 15.9334C26.3331 18.8171 25.3297 21.2714 23.3229 23.2962C21.316 25.3209 18.875 26.3333 15.9998 26.3333H14.9587L17.4254 28.8L16.0203 30.2051Z" />
      </svg>
    </div>
  );
};
