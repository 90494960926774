// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {addCN} from '@/_core/util/cssHelper';
import {getSize, IconProps} from '../types';

export const IconShow: React.FC<IconProps> = ({size = 'md', color = 'fill-grey700'}) => {
  const finSize = getSize(size);
  return (
    <div>
      <svg
        className={addCN(finSize, color, 'transition')}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <mask
          id="mask0_16_1292"
          style={{maskType: 'alpha'}}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="24"
          height="24"
        >
          <rect width="24" height="24" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_16_1292)">
          <path d="M12.0023 15.5769C13.1354 15.5769 14.0977 15.1803 14.8894 14.3871C15.6811 13.5939 16.0769 12.6308 16.0769 11.4977C16.0769 10.3646 15.6803 9.40224 14.8871 8.61058C14.094 7.81891 13.1308 7.42308 11.9977 7.42308C10.8646 7.42308 9.90227 7.81966 9.1106 8.61282C8.31893 9.40601 7.9231 10.3692 7.9231 11.5023C7.9231 12.6353 8.31968 13.5977 9.11285 14.3894C9.90603 15.181 10.8692 15.5769 12.0023 15.5769ZM12 14.2C11.25 14.2 10.6125 13.9375 10.0875 13.4125C9.5625 12.8875 9.3 12.25 9.3 11.5C9.3 10.75 9.5625 10.1125 10.0875 9.58748C10.6125 9.06248 11.25 8.79998 12 8.79998C12.75 8.79998 13.3875 9.06248 13.9125 9.58748C14.4375 10.1125 14.7 10.75 14.7 11.5C14.7 12.25 14.4375 12.8875 13.9125 13.4125C13.3875 13.9375 12.75 14.2 12 14.2ZM12.0014 18.5C9.70176 18.5 7.60643 17.8657 5.7154 16.5971C3.82438 15.3285 2.43208 13.6295 1.5385 11.5C2.43208 9.37049 3.82393 7.67146 5.71405 6.40288C7.60415 5.13429 9.69901 4.5 11.9986 4.5C14.2982 4.5 16.3936 5.13429 18.2846 6.40288C20.1756 7.67146 21.5679 9.37049 22.4615 11.5C21.5679 13.6295 20.1761 15.3285 18.2859 16.5971C16.3958 17.8657 14.301 18.5 12.0014 18.5ZM12 17C13.8833 17 15.6125 16.5041 17.1875 15.5125C18.7625 14.5208 19.9667 13.1833 20.8 11.5C19.9667 9.81664 18.7625 8.47914 17.1875 7.48748C15.6125 6.49581 13.8833 5.99998 12 5.99998C10.1167 5.99998 8.3875 6.49581 6.8125 7.48748C5.2375 8.47914 4.03333 9.81664 3.2 11.5C4.03333 13.1833 5.2375 14.5208 6.8125 15.5125C8.3875 16.5041 10.1167 17 12 17Z" />
        </g>
      </svg>
    </div>
  );
};
