// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {addCN} from '@/_core/util/cssHelper';
import {IconProps, getSize} from '../types';

export const IconAsterisk: React.FC<IconProps> = ({size = 'md', color = 'fill-grey700'}) => {
  const finSize = getSize(size);
  return (
    <div>
      <svg
        className={addCN(finSize, color, 'transition')}
        width="25"
        height="24"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
      >
        <mask id="mask0_2728_33483" maskUnits="userSpaceOnUse" x="0" y="0" width="32" height="32">
          <rect width="32" height="32" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_2728_33483)">
          <path d="M15 27.3333V18.405L8.69735 24.728L7.27202 23.3026L13.595 17H4.66669V15H13.595L7.27202 8.69729L8.69735 7.28463L15 13.6076V4.66663H17V13.6076L23.3027 7.28463L24.7154 8.69729L18.3924 15H27.3334V17H18.3924L24.7154 23.3026L23.3027 24.728L17 18.405V27.3333H15Z" />
        </g>
      </svg>
    </div>
  );
};
