// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {addCN} from '@/_core/util/cssHelper';
import {IconProps, getSize} from '../types';

export const IconEnergyThieves: React.FC<IconProps> = ({size = 'md', color = 'fill-grey700'}) => {
  const finSize = getSize(size);
  return (
    <div>
      <svg
        className={addCN(finSize, color, 'transition')}
        width="32"
        height="32"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M11.1284 28.6665C10.787 28.6665 10.5008 28.551 10.2698 28.32C10.0388 28.089 9.92334 27.8028 9.92334 27.4614V6.85889C9.92334 6.51742 10.0388 6.2312 10.2698 6.00022C10.5008 5.76924 10.787 5.65375 11.1284 5.65375H13.5387V3.33325H18.4617V5.65375H20.8746C21.2164 5.65375 21.5023 5.76924 21.7322 6.00022C21.9621 6.2312 22.0771 6.51742 22.0771 6.85889V16.9589C21.7079 17.0665 21.3587 17.2003 21.0297 17.3601C20.7006 17.52 20.3831 17.7059 20.0771 17.9178V7.64089H11.9233V26.6666H16.8822C16.9677 27.0358 17.0882 27.3849 17.2438 27.714C17.3993 28.043 17.5788 28.3605 17.7822 28.6665H11.1284ZM21.6745 28.6665L20.2823 27.2742L23.0823 24.4742L20.2823 21.6742L21.6745 20.282L24.4745 23.082L27.2745 20.282L28.6668 21.6742L25.9002 24.4742L28.6668 27.2742L27.2745 28.6665L24.4745 25.8999L21.6745 28.6665Z" />
      </svg>
    </div>
  );
};
