// @ts-nocheck
/* eslint-disable */

import React, {useEffect, useRef, useState} from 'react';
import {_Tab, TabsItem} from '@modules/ContentEngine/components/items/TabsItem';
import {_ContentEngineContext} from '@modules/ContentEngine/types/contentEngineItem.model';
import {Section} from '@modules/Core/components/layout/Section';
import {logger} from '@modules/Core/util/Logger';
import {CustomSurveysOverviewCard} from '@modules/CustomSurveys/components/overview/CustomSurveyOverviewCard';
import {CustomSurveyOverviewPlaceholder} from '@modules/CustomSurveys/components/overview/CustomSurveyOverviewPlaceholder';
import {_CustomSurvey, _CustomSurveyStatus} from '@modules/CustomSurveys/types/customSurvey.model';
import {trans} from '@modules/Translations/util/i18n';

interface _Props {
  surveys: Record<_CustomSurveyStatus, _CustomSurvey[]>;
  initialTab?: 'draft' | 'published' | 'closed';
  context: _ContentEngineContext;
  tutorialKey?: string;
  tutorialService?: string;
}

const SurveyList = ({statusSurveys, setActiveTab}: {statusSurveys: _CustomSurvey[]; setActiveTab: any}) => {
  if (!statusSurveys?.length) return <CustomSurveyOverviewPlaceholder />;
  return (
    <Section>
      {statusSurveys?.map(survey => (
        <CustomSurveysOverviewCard onChangeTab={setActiveTab} key={survey.id} survey={survey} />
      ))}
    </Section>
  );
};

const tabsOrder = ['draft', 'published', 'closed'];
export const CustomSurveysOverviewItem: React.FC<_Props> = ({
  context,
  tutorialKey,
  tutorialService,
  initialTab = 'draft',
  surveys,
}) => {
  const [activeTab, setActiveTab] = useState<'draft' | 'published' | 'closed'>(initialTab);

  const tabs: _Tab[] = [
    {
      name: 'draft',
      title: trans('surveys.status.draft'),
      content: <SurveyList statusSurveys={surveys?.draft ?? []} setActiveTab={setActiveTab} />,
    },
    {
      name: 'published',
      title: trans('surveys.status.published'),
      content: <SurveyList statusSurveys={surveys?.published ?? []} setActiveTab={setActiveTab} />,
    },
    {
      name: 'closed',
      title: trans('surveys.status.closed'),
      content: <SurveyList statusSurveys={surveys?.closed ?? []} setActiveTab={setActiveTab} />,
    },
  ];

  useEffect(() => {
    if (!initialTab) {
      setActiveTab(surveys?.published ? 'published' : 'draft');
    }
  }, [surveys, initialTab]);

  logger.debug('[CustomSurveysOverviewItem] surveys', surveys);
  return (
    <TabsItem
      value={tabsOrder.indexOf(activeTab)}
      setValue={(value: number) => setActiveTab(tabsOrder[value] as 'draft' | 'published' | 'closed')}
      tabs={tabs}
      activeTab={tabsOrder.indexOf(activeTab)}
      context={context}
      tutorialKey={tutorialKey}
      tutorialService={tutorialService}
    />
  );
};
