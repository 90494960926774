// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {addCN} from '@/_core/util/cssHelper';
import {IconProps, getSize} from '../types';

export const IconLocked: React.FC<IconProps> = ({size = 'md', color = 'fill-grey700'}) => {
  const finSize = getSize(size);
  return (
    <div>
      <svg
        className={addCN(finSize, color, 'transition')}
        width="32"
        height="32"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M9.35898 27.0834C8.77715 27.0834 8.2802 26.8773 7.86813 26.4653C7.45604 26.0532 7.25 25.5562 7.25 24.9744V14.0258C7.25 13.4439 7.45604 12.947 7.86813 12.5349C8.2802 12.1228 8.77715 11.9168 9.35898 11.9168H10.75V9.58344C10.75 8.12662 11.2608 6.88743 12.2824 5.86586C13.3039 4.84428 14.5432 4.3335 16 4.3335C17.4568 4.3335 18.696 4.84428 19.7176 5.86586C20.7392 6.88743 21.2499 8.12662 21.2499 9.58344V11.9168H22.641C23.2228 11.9168 23.7197 12.1228 24.1318 12.5349C24.5439 12.947 24.7499 13.4439 24.7499 14.0258V24.9744C24.7499 25.5562 24.5439 26.0532 24.1318 26.4653C23.7197 26.8773 23.2228 27.0834 22.641 27.0834H9.35898ZM9.35898 25.3334H22.641C22.7457 25.3334 22.8317 25.2998 22.899 25.2324C22.9663 25.1651 23 25.0791 23 24.9744V14.0258C23 13.921 22.9663 13.835 22.899 13.7677C22.8317 13.7004 22.7457 13.6667 22.641 13.6667H9.35898C9.25427 13.6667 9.16826 13.7004 9.10095 13.7677C9.03363 13.835 8.99997 13.921 8.99997 14.0258V24.9744C8.99997 25.0791 9.03363 25.1651 9.10095 25.2324C9.16826 25.2998 9.25427 25.3334 9.35898 25.3334ZM16 21.5417C16.5669 21.5417 17.0488 21.3432 17.4459 20.946C17.8431 20.5489 18.0416 20.067 18.0416 19.5001C18.0416 18.9332 17.8431 18.4512 17.4459 18.0541C17.0488 17.657 16.5669 17.4584 16 17.4584C15.4331 17.4584 14.9511 17.657 14.554 18.0541C14.1569 18.4512 13.9583 18.9332 13.9583 19.5001C13.9583 20.067 14.1569 20.5489 14.554 20.946C14.9511 21.3432 15.4331 21.5417 16 21.5417ZM12.5 11.9168H19.5V9.58344C19.5 8.61122 19.1597 7.78483 18.4791 7.10427C17.7986 6.42372 16.9722 6.08344 16 6.08344C15.0277 6.08344 14.2014 6.42372 13.5208 7.10427C12.8402 7.78483 12.5 8.61122 12.5 9.58344V11.9168Z" />
      </svg>
    </div>
  );
};
