// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {addCN} from '@/_core/util/cssHelper';
import {IconProps, getSize} from '../types';

export const IconCandidate: React.FC<IconProps> = ({size = 'md', color = 'fill-grey700'}) => {
  const finSize = getSize(size);
  return (
    <div>
      <svg
        className={addCN(finSize, color, 'transition')}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <mask
          id="mask0_3178_65"
          style={{maskType: 'alpha'}}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="24"
          height="24"
        >
          <rect width="24" height="24" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_3178_65)">
          <path d="M4.25 22.1922V20.6922H19.75V22.1922H4.25ZM4.25 3.30774V1.80774H19.75V3.30774H4.25ZM12 13.5C12.7628 13.5 13.4118 13.2324 13.947 12.6972C14.4823 12.1619 14.75 11.5128 14.75 10.75C14.75 9.98716 14.4823 9.33816 13.947 8.80299C13.4118 8.26766 12.7628 7.99999 12 7.99999C11.2372 7.99999 10.5882 8.26766 10.053 8.80299C9.51767 9.33816 9.25 9.98716 9.25 10.75C9.25 11.5128 9.51767 12.1619 10.053 12.6972C10.5882 13.2324 11.2372 13.5 12 13.5ZM4.30775 19.5C3.80258 19.5 3.375 19.325 3.025 18.975C2.675 18.625 2.5 18.1974 2.5 17.6922V6.30774C2.5 5.80257 2.675 5.37499 3.025 5.02499C3.375 4.67499 3.80258 4.49999 4.30775 4.49999H19.6923C20.1974 4.49999 20.625 4.67499 20.975 5.02499C21.325 5.37499 21.5 5.80257 21.5 6.30774V17.6922C21.5 18.1974 21.325 18.625 20.975 18.975C20.625 19.325 20.1974 19.5 19.6923 19.5H4.30775ZM6.404 18C7.154 17.1948 8.00142 16.5769 8.94625 16.1462C9.89108 15.7154 10.909 15.5 12 15.5C13.091 15.5 14.1089 15.7154 15.0538 16.1462C15.9986 16.5769 16.846 17.1948 17.596 18H19.6923C19.7693 18 19.8398 17.9679 19.9038 17.9037C19.9679 17.8397 20 17.7692 20 17.6922V6.30774C20 6.23074 19.9679 6.16024 19.9038 6.09624C19.8398 6.03207 19.7693 5.99999 19.6923 5.99999H4.30775C4.23075 5.99999 4.16025 6.03207 4.09625 6.09624C4.03208 6.16024 4 6.23074 4 6.30774V17.6922C4 17.7692 4.03208 17.8397 4.09625 17.9037C4.16025 17.9679 4.23075 18 4.30775 18H6.404ZM8.7 18H15.3C14.8167 17.6667 14.2958 17.4167 13.7375 17.25C13.1792 17.0833 12.6 17 12 17C11.4 17 10.8208 17.0833 10.2625 17.25C9.70417 17.4167 9.18333 17.6667 8.7 18ZM12 12C11.6525 12 11.3573 11.8786 11.1145 11.6357C10.8715 11.3927 10.75 11.0975 10.75 10.75C10.75 10.4027 10.8715 10.1075 11.1145 9.86449C11.3573 9.62149 11.6525 9.49999 12 9.49999C12.3475 9.49999 12.6427 9.62149 12.8855 9.86449C13.1285 10.1075 13.25 10.4027 13.25 10.75C13.25 11.0975 13.1285 11.3927 12.8855 11.6357C12.6427 11.8786 12.3475 12 12 12Z" />
        </g>
      </svg>
    </div>
  );
};
