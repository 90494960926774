// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {_CandidateAnalysis, _CandidateAnalysisStatus} from '@modules/CandidateAnalysis/types/candidateAnalysis.model';
import {
  closeCandidateAnalysisAction,
  deleteCandidateAnalysisAction,
  duplicateCandidateAnalysisAction,
  publishCandidateAnalysisAction,
} from '@modules/CandidateAnalysis/util/candidateAnalysisFlowActions';
import {AvatarUser} from '@modules/Core/components/base/avatar/AvatarUser';
import {Badge} from '@modules/Core/components/base/badge/Badge';
import {Button} from '@modules/Core/components/base/buttons/Button';
import {Card} from '@modules/Core/components/base/cards/Card';
import {
  IconCancel,
  IconCopy,
  IconLink,
  IconMore,
  IconSend,
  IconShare,
  IconShow,
  IconSurvey,
  IconTrash,
} from '@modules/Core/components/base/Icons/Icons';
import {ContextMenu} from '@modules/Core/components/base/inputs/ContextMenu';
import useSnackbar from '@modules/Core/hooks/ui/snackbar';
import {fireConfirmationDialog} from '@modules/Core/util/dialogUtil';
import {reloadContentEngine} from '@modules/Core/util/eventsUtil';
import {useNavigateToFlow} from '@modules/FlowEngine/hooks/navigateToFlow';
import {getProfilePic} from '@modules/Profile/util/profileUtil';
import {trans} from '@modules/Translations/util/i18n';
import {shareCandidateAnalysisFlow} from '../util/dialogFlowsUtil';

interface _Props {
  analysis: _CandidateAnalysis;
  onChangeTab: (tab: 'draft' | 'published' | 'closed') => void;
}

const statusLangProps: Record<string, string> = {
  draft: 'candidate_analysis.status.draft',
  published: 'candidate_analysis.status.published',
  closed: 'candidate_analysis.status.closed',
};

const colors: Record<string, 'dark' | 'success'> = {
  draft: 'dark',
  published: 'success',
};
export const CandidateAnalysisOverviewCard: React.FC<_Props> = ({onChangeTab, analysis}) => {
  const snackbar = useSnackbar();
  const navigateToFlow = useNavigateToFlow();
  const onDuplicate = async (analysis: _CandidateAnalysis): Promise<void> => {
    const newId = await duplicateCandidateAnalysisAction(analysis.id);

    if (newId) {
      snackbar.success(trans('candidate_analysis.duplicate_success'));
      reloadContentEngine();
      onChangeTab(_CandidateAnalysisStatus.DRAFT);
    } else {
      snackbar.danger(trans('base.error'));
    }
  };
  const onPublish = async (analysis: _CandidateAnalysis): Promise<void> => {
    fireConfirmationDialog({
      title: trans('candidate_analysis.publish_confirm_title'),
      onConfirm: async () => {
        const success = await publishCandidateAnalysisAction(analysis.id);

        if (success) {
          snackbar.success(trans('candidate_analysis.publish_success'));
          reloadContentEngine();
          onChangeTab(_CandidateAnalysisStatus.PUBLISHED);
        } else {
          snackbar.danger(trans('base.error'));
        }
      },
    });
  };
  const onClose = async (analysis: _CandidateAnalysis): Promise<void> => {
    fireConfirmationDialog({
      title: trans('candidate_analysis.close_confirm_title'),
      onConfirm: async () => {
        const success = await closeCandidateAnalysisAction(analysis.id);

        if (success) {
          snackbar.success(trans('candidate_analysis.close_success'));
          reloadContentEngine();
        } else {
          snackbar.danger(trans('base.error'));
        }
      },
    });
  };
  const onEdit = (analysis: _CandidateAnalysis): void => {
    navigateToFlow('CandidateAnalysisSetup', {candidateAnalysisId: analysis.id, edit: true});
  };
  const onDelete = async (analysis: _CandidateAnalysis): Promise<void> => {
    fireConfirmationDialog({
      title: trans('candidate_analysis.delete_confirm_title'),
      onConfirm: async () => {
        const success = await deleteCandidateAnalysisAction(analysis.id);
        if (success) {
          snackbar.success(trans('candidate_analysis.delete_success'));
          reloadContentEngine();
        } else {
          snackbar.danger(trans('base.error'));
        }
      },
    });
  };
  const onShare = async (analysis: _CandidateAnalysis): Promise<void> => {
    shareCandidateAnalysisFlow(analysis);
  };

  const getActions = (): Array<{label: string; onChange: () => void; icon: React.FC}> => {
    const result = [];

    if (![_CandidateAnalysisStatus.PUBLISHED, _CandidateAnalysisStatus.CLOSED].includes(analysis.status)) {
      result.push({
        label: trans('candidate_analysis.actions.publish'),
        onChange: async () => await onPublish(analysis),
        icon: IconSend,
      });
    }

    if (analysis.status === _CandidateAnalysisStatus.DRAFT) {
      result.push({
        label: trans('candidate_analysis.actions.edit'),
        onChange: () => {
          onEdit(analysis);
        },
        icon: IconSurvey,
      });
    }

    if (analysis.status === _CandidateAnalysisStatus.PUBLISHED) {
      result.push({
        label: trans('candidate_analysis.actions.invite'),
        onChange: () => {
          onShare(analysis);
        },
        icon: IconShare,
      });

      if (analysis.link) {
        result.push({
          label: trans('candidate_analysis.actions.copy_link'),
          onChange: () => {
            navigator.clipboard.writeText(analysis.link);
            snackbar.success(trans('base.copied_successfully'));
          },
          icon: IconLink,
        });
      }

      result.push({
        label: trans('candidate_analysis.actions.close'),
        onChange: () => {
          onClose(analysis);
        },
        icon: IconCancel,
      });

      result.push({
        label: trans('candidate_analysis.actions.see_results'),
        onChange: () => {
          navigateToFlow('CandidateAnalysis', {candidateAnalysisId: analysis.id});
        },
        icon: IconShow,
      });
    }

    result.push({
      label: trans('candidate_analysis.actions.duplicate'),
      onChange: () => {
        onDuplicate(analysis);
      },
      icon: IconCopy,
    });
    result.push({
      label: trans('candidate_analysis.actions.delete'),
      onChange: () => {
        onDelete(analysis);
      },
      icon: IconTrash,
    });
    return result;
  };
  return (
    <Card
      titleVariant="h5"
      titleClassName="w-full"
      titleSuffix={
        <div className="flex flex-row flex-grow justify-end">
          <Badge value={trans(statusLangProps[analysis.status])} color={colors[analysis.status]} size="fit" />
        </div>
      }
      title={analysis.title}
      description={analysis.description}
    >
      <div className="flex flex-row h-full w-full justify-between">
        <div className="flex flex-col w-full h-full gap-2">
          <AvatarUser label={analysis.createdBy?.fullName} image={getProfilePic(analysis.createdBy)} />

          <div className="flex flex-row flex-grow gap-1">
            {Boolean(analysis.status === _CandidateAnalysisStatus.PUBLISHED && analysis.completedCount > 0) && (
              <>
                {trans('candidate_analysis.completed_count', {
                  count: analysis.completedCount,
                })}
              </>
            )}
          </div>
        </div>
        <div className="flex flex-row items-center gap-3">
          {Boolean(analysis.canSeeResults) && (
            <Button
              variant="secondary"
              onClick={() =>
                navigateToFlow('CandidateAnalysis', {
                  candidateAnalysisId: analysis.id,
                })
              }
            >
              <div>|</div>
              {trans('candidate_analysis.actions.see_results')}
            </Button>
          )}
          <ContextMenu triggerIcon={IconMore} menuItems={getActions()} />
        </div>
      </div>
    </Card>
  );
};
