// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {addCN} from '@/_core/util/cssHelper';
import {IconProps, getSize} from '../types';

export const IconArrowDoubleRight: React.FC<IconProps> = ({size = 'md', color = 'fill-grey700'}) => {
  const finSize = getSize(size);
  return (
    <div>
      <svg
        className={addCN(finSize, color, 'transition')}
        width="32"
        height="32"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M13.4077 16.0001L7.29492 9.86677L8.69999 8.46167L16.2384 16.0001L8.69999 23.5385L7.29492 22.1334L13.4077 16.0001ZM21.8744 16.0001L15.7616 9.86677L17.1667 8.46167L24.7051 16.0001L17.1667 23.5385L15.7616 22.1334L21.8744 16.0001Z" />
      </svg>
    </div>
  );
};
