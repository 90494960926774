// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {addCN} from '@/_core/util/cssHelper';
import {IconProps, getSize} from '../types';

export const IconCoordinator: React.FC<IconProps> = ({size = 'md', color = 'fill-grey700'}) => {
  const finSize = getSize(size);
  return (
    <div>
      <svg
        className={addCN(finSize, color, 'transition')}
        width="32"
        height="32"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M7.07677 28.6666C6.41182 28.6666 5.84387 28.4311 5.37294 27.9602C4.90198 27.4892 4.6665 26.9213 4.6665 26.2563V11.0769C4.6665 10.412 4.90198 9.84405 5.37294 9.37311C5.84387 8.90216 6.41182 8.66668 7.07677 8.66668H9.99984C9.99984 7.00175 10.5836 5.58553 11.7511 4.41802C12.9186 3.2505 14.3349 2.66675 15.9998 2.66675C17.6647 2.66675 19.081 3.2505 20.2485 4.41802C21.416 5.58553 21.9998 7.00175 21.9998 8.66668H24.9228C25.5878 8.66668 26.1557 8.90216 26.6267 9.37311C27.0976 9.84405 27.3331 10.412 27.3331 11.0769V26.2563C27.3331 26.9213 27.0976 27.4892 26.6267 27.9602C26.1557 28.4311 25.5878 28.6666 24.9228 28.6666H7.07677ZM7.07677 26.6666H24.9228C25.0254 26.6666 25.1194 26.6239 25.2049 26.5384C25.2904 26.453 25.3331 26.3589 25.3331 26.2563V11.0769C25.3331 10.9744 25.2904 10.8803 25.2049 10.7948C25.1194 10.7094 25.0254 10.6666 24.9228 10.6666H7.07677C6.97419 10.6666 6.88016 10.7094 6.79467 10.7948C6.7092 10.8803 6.66647 10.9744 6.66647 11.0769V26.2563C6.66647 26.3589 6.7092 26.453 6.79467 26.5384C6.88016 26.6239 6.97419 26.6666 7.07677 26.6666ZM15.9998 17.9999C17.6647 17.9999 19.081 17.4162 20.2485 16.2487C21.416 15.0811 21.9998 13.6649 21.9998 12H19.9998C19.9998 13.1111 19.6109 14.0555 18.8331 14.8333C18.0554 15.6111 17.1109 16 15.9998 16C14.8887 16 13.9442 15.6111 13.1665 14.8333C12.3887 14.0555 11.9998 13.1111 11.9998 12H9.99984C9.99984 13.6649 10.5836 15.0811 11.7511 16.2487C12.9186 17.4162 14.3349 17.9999 15.9998 17.9999ZM11.9998 8.66668H19.9998C19.9998 7.55557 19.6109 6.61113 18.8331 5.83335C18.0554 5.05557 17.1109 4.66668 15.9998 4.66668C14.8887 4.66668 13.9442 5.05557 13.1665 5.83335C12.3887 6.61113 11.9998 7.55557 11.9998 8.66668Z" />
      </svg>
    </div>
  );
};
