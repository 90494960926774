// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {addCN} from '@/_core/util/cssHelper';
import {IconProps, getSize} from '../types';

export const IconStarFilled: React.FC<IconProps> = ({size = 'md', color = 'fill-grey700', onClick}) => {
  const finSize = getSize(size);
  return (
    <div>
      <svg
        className={addCN(finSize, color, 'transition')}
        width="32"
        height="32"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
        onClick={onClick}
      >
        <path d="M8.47173 27.2433L10.382 19.0178L4 13.4871L12.4204 12.7563L15.705 5L18.9896 12.7563L27.41 13.4871L21.028 19.0178L22.9383 27.2433L15.705 22.8793L8.47173 27.2433Z" />
      </svg>
    </div>
  );
};
