// @ts-nocheck
/* eslint-disable */

import {_CandidateAnalysis} from '@modules/CandidateAnalysis/types/candidateAnalysis.model';
import {fireDialogFlow} from '@modules/FlowEngine/util/dialogFlowsUtil';

export function shareCandidateAnalysisFlow(analysis: _CandidateAnalysis): void {
  fireDialogFlow(
    'CandidateAnalysisInvite',
    null,
    {hideButtons: true, showCloseButton: true, size: 'lg'},
    {
      analysisId: analysis.id,
      analysisName: analysis.title,
      analysisLink: analysis.link,
    }
  );
}
