// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {addCN} from '@/_core/util/cssHelper';
import {IconProps, getSize} from '../types';

export const IconLocation: React.FC<IconProps> = ({size = 'md', color = 'fill-grey700'}) => {
  const finSize = getSize(size);
  return (
    <div>
      <svg
        className={addCN(finSize, color, 'transition')}
        width="32"
        height="32"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M16.0024 15.8204C16.6658 15.8204 17.233 15.5842 17.7039 15.1117C18.1749 14.6393 18.4103 14.0713 18.4103 13.4079C18.4103 12.7444 18.1741 12.1773 17.7017 11.7063C17.2292 11.2354 16.6613 10.9999 15.9978 10.9999C15.3344 10.9999 14.7672 11.2361 14.2963 11.7086C13.8253 12.181 13.5899 12.749 13.5899 13.4124C13.5899 14.0759 13.8261 14.6431 14.2985 15.114C14.771 15.5849 15.3389 15.8204 16.0024 15.8204ZM16.0001 26.0179C18.6086 23.6828 20.6048 21.4431 21.9886 19.2986C23.3723 17.1542 24.0642 15.276 24.0642 13.664C24.0642 11.2332 23.292 9.23494 21.7475 7.66912C20.2031 6.1033 18.2873 5.32039 16.0001 5.32039C13.7129 5.32039 11.7971 6.1033 10.2527 7.66912C8.70822 9.23494 7.936 11.2332 7.936 13.664C7.936 15.276 8.62788 17.1542 10.0116 19.2986C11.3954 21.4431 13.3916 23.6828 16.0001 26.0179ZM16.0001 28.6793C12.6446 25.7716 10.1283 23.0657 8.4514 20.5614C6.77449 18.0572 5.93604 15.758 5.93604 13.664C5.93604 10.5871 6.93134 8.09609 8.92194 6.19095C10.9125 4.28582 13.2719 3.33325 16.0001 3.33325C18.7283 3.33325 21.0877 4.28582 23.0783 6.19095C25.0689 8.09609 26.0642 10.5871 26.0642 13.664C26.0642 15.758 25.2257 18.0572 23.5488 20.5614C21.8719 23.0657 19.3556 25.7716 16.0001 28.6793Z" />
      </svg>
    </div>
  );
};
