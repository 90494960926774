// @ts-nocheck
/* eslint-disable */

import React, {useState, useEffect} from 'react';
import {useSoftFactGroups} from '@modules/ContentEngine/hooks/useSoftFactGroups';
import {Avatar} from '@modules/Core/components/base/avatar/Avatar';
import {Divider} from '@modules/Core/components/base/Divider';
import {Slider} from '@modules/Core/components/base/Slider';
import {Typography} from '@modules/Core/components/base/Typography';
import {Section} from '@modules/Core/components/layout/Section';
import {PROFILE_SERVICES_DESCRIPTIONS} from '@modules/Profile/config/profileServicesLangProps';
import {getServiceIcon} from '@modules/Profile/util/profileUtil';
import {trans} from '@modules/Translations/util/i18n';

interface RangeValue {
  min: number;
  max: number;
}

interface ConfigureSoftFactsProps {
  values?: Record<string, any>;
  onChange?: (values: Record<string, any>) => void;
}

export const ConfigureSoftFacts: React.FC<ConfigureSoftFactsProps> = ({values = {}, onChange}) => {
  const [selectedSoftFacts, setSelectedSoftFacts] = useState<string[]>(values?.selectedSoftFacts || []);
  const [softFactRanges, setSoftFactRanges] = useState<Record<string, RangeValue>>(values?.softFactRanges || {});
  const softFactGroups = useSoftFactGroups();

  useEffect(() => {
    setSelectedSoftFacts(values?.selectedSoftFacts || []);
    setSoftFactRanges(values?.softFactRanges || {});
  }, [values?.selectedSoftFacts, values?.softFactRanges]);

  // Set default ranges for soft facts that don't have ranges
  useEffect(() => {
    const newRanges = {...softFactRanges};
    let hasChanges = false;

    selectedSoftFacts.forEach(factId => {
      if (!softFactRanges[factId]) {
        newRanges[factId] = {min: 25, max: 75};
        hasChanges = true;
      }
    });

    if (hasChanges) {
      setSoftFactRanges(newRanges);
      onChange?.({
        ...values,
        softFactRanges: newRanges,
      });
    }
  }, [values?.selectedSoftFacts, onChange]);

  const handleRangeChange = (factId: string, range: RangeValue) => {
    const newRanges = {
      ...softFactRanges,
      [factId]: range,
    };
    setSoftFactRanges(newRanges);
    onChange?.({
      ...values,
      softFactRanges: newRanges,
    });
  };

  const renderSlider = (name: string) => {
    const range = softFactRanges[name] || {};
    const [groupId, dimensionId] = name.split(':');
    const group = softFactGroups.find(g => g.id === groupId);
    const dimension = group?.dimensions.find(d => d.id === dimensionId);

    const onSliderRangeChange = (changes: {id: string; progress: number} | {min: number; max: number}) => {
      if ('min' in changes) {
        // Handle range update (both points)
        handleRangeChange(name, {
          min: Math.round(changes.min),
          max: Math.round(changes.max),
        });
      } else {
        // Handle single point update
        const isMin = changes.id === `${name}-min`;
        const newRange = {
          ...range,
          [isMin ? 'min' : 'max']: Math.round(changes.progress),
        };

        // Ensure min doesn't exceed max and vice versa
        if (isMin && newRange.min > range.max) {
          newRange.min = range.max;
        } else if (!isMin && newRange.max < range.min) {
          newRange.max = range.min;
        }

        handleRangeChange(name, newRange);
      }
    };

    return (
      <Slider
        simpleStyle
        title={dimension?.name || dimensionId}
        leftText={dimension?.leftLabel || trans('profile_result.low')}
        rightText={dimension?.rightLabel || trans('profile_result.high')}
        rangeStart={range?.min}
        rangeEnd={range?.max}
        rangeColor="bg-green700"
        enableRangeSelection
        onRangeChange={onSliderRangeChange}
        components={[
          {
            id: `${name}-min`,
            progress: range.min,
            component: <Avatar size="md" backgroundColor="bg-blue700" />,
          },
          {
            id: `${name}-max`,
            progress: range.max,
            component: <Avatar size="md" backgroundColor="bg-blue700" />,
          },
        ]}
        showExpand={false}
      />
    );
  };

  const groupedSoftFacts = selectedSoftFacts.reduce((acc: Record<string, string[]>, softFact: string) => {
    const [group] = softFact.split(':');
    if (!acc[group]) {
      acc[group] = [];
    }
    acc[group].push(softFact);
    return acc;
  }, {});

  // Sort soft facts within each group according to their definition order
  const sortSoftFactsByDefinition = (group: string, softFacts: string[]) => {
    const groupConfig = softFactGroups.find(g => g.id === group);
    if (!groupConfig) return softFacts;

    return softFacts.sort((a, b) => {
      const [, dimensionA] = a.split(':');
      const [, dimensionB] = b.split(':');

      const indexA = groupConfig.dimensions.findIndex(d => d.id === dimensionA);
      const indexB = groupConfig.dimensions.findIndex(d => d.id === dimensionB);

      return indexA - indexB;
    });
  };

  return (
    <>
      {Object.entries(groupedSoftFacts).map(([group, softFacts]) => {
        const groupConfig = softFactGroups.find(g => g.id === group);
        const sortedSoftFacts = sortSoftFactsByDefinition(group, softFacts);

        return (
          <Section
            key={group}
            gap={4}
            titleIcon={getServiceIcon(group)}
            title={groupConfig?.name || group}
            description={trans(PROFILE_SERVICES_DESCRIPTIONS[group])}
          >
            {sortedSoftFacts.map(softFact => renderSlider(softFact))}
          </Section>
        );
      })}
    </>
  );
};
