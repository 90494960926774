// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {addCN} from '@/_core/util/cssHelper';
import {IconProps, getSize} from '../types';

export const IconTaskCompleter: React.FC<IconProps> = ({size = 'md', color = 'fill-grey700'}) => {
  const finSize = getSize(size);
  return (
    <div>
      <svg
        className={addCN(finSize, color, 'transition')}
        width="32"
        height="32"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M10.2563 27.3334V25.3334H14.9998V20.7641C13.8682 20.5368 12.8682 20.0607 11.9998 19.3359C11.1315 18.6111 10.5144 17.7077 10.1485 16.6257C8.61009 16.4427 7.31182 15.7898 6.2537 14.6667C5.19557 13.5436 4.6665 12.2103 4.6665 10.6667V9.33338C4.6665 8.78809 4.86351 8.31845 5.25754 7.92445C5.65154 7.53043 6.12118 7.33342 6.66647 7.33342H9.76907V4.66675H22.2305V7.33342H25.3331C25.8784 7.33342 26.3481 7.53043 26.7421 7.92445C27.1361 8.31845 27.3331 8.78809 27.3331 9.33338V10.6667C27.3331 12.2103 26.804 13.5436 25.7459 14.6667C24.6878 15.7898 23.3895 16.4427 21.8511 16.6257C21.4852 17.7077 20.8682 18.6111 19.9998 19.3359C19.1314 20.0607 18.1314 20.5368 16.9998 20.7641V25.3334H21.7433V27.3334H10.2563ZM9.76907 14.477V9.33338H6.66647V10.6667C6.66647 11.5966 6.95793 12.4133 7.54084 13.1167C8.12375 13.8201 8.86649 14.2736 9.76907 14.477ZM15.9998 18.8462C17.1708 18.8462 18.1643 18.4381 18.9806 17.6219C19.7968 16.8056 20.2049 15.812 20.2049 14.6411V6.66672H11.7947V14.6411C11.7947 15.812 12.2028 16.8056 13.019 17.6219C13.8353 18.4381 14.8288 18.8462 15.9998 18.8462ZM22.2305 14.477C23.1331 14.2736 23.8759 13.8201 24.4588 13.1167C25.0417 12.4133 25.3331 11.5966 25.3331 10.6667V9.33338H22.2305V14.477Z" />
      </svg>
    </div>
  );
};
