// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {addCN} from '@/_core/util/cssHelper';
import {IconProps, getSize} from '../types';

export const IconRadio: React.FC<IconProps> = ({size = 'md', color = 'fill-grey700'}) => {
  const finSize = getSize(size);
  return (
    <div>
      <svg
        className={addCN(finSize, color, 'transition')}
        width="25"
        height="24"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
      >
        <mask id="mask0_7135_6004" maskUnits="userSpaceOnUse" x="0" y="0" width="32" height="32">
          <rect width="32" height="32" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_7135_6004)">
          <path d="M15.9987 22C17.6636 22 19.0798 21.4163 20.2474 20.2487C21.4149 19.0812 21.9987 17.6649 21.9987 16C21.9987 14.3352 21.4149 12.9189 20.2474 11.7514C19.0798 10.5838 17.6636 10 15.9987 10C14.3338 10 12.9176 10.5838 11.75 11.7514C10.5825 12.9189 9.9987 14.3352 9.9987 16C9.9987 17.6649 10.5825 19.0812 11.75 20.2487C12.9176 21.4163 14.3338 22 15.9987 22ZM16.001 28.6667C14.249 28.6667 12.6023 28.3343 11.0607 27.6694C9.51914 27.0045 8.17825 26.1022 7.03803 24.9624C5.89781 23.8226 4.99503 22.4823 4.3297 20.9414C3.66459 19.4005 3.33203 17.7542 3.33203 16.0024C3.33203 14.2504 3.66448 12.6036 4.32936 11.062C4.99425 9.52048 5.89659 8.1796 7.03637 7.03937C8.17614 5.89915 9.51648 4.99637 11.0574 4.33104C12.5983 3.66593 14.2446 3.33337 15.9964 3.33337C17.7484 3.33337 19.3951 3.66582 20.9367 4.33071C22.4783 4.9956 23.8191 5.89793 24.9594 7.03771C26.0996 8.17749 27.0024 9.51782 27.6677 11.0587C28.3328 12.5996 28.6654 14.2459 28.6654 15.9977C28.6654 17.7497 28.3329 19.3965 27.668 20.938C27.0031 22.4796 26.1008 23.8205 24.961 24.9607C23.8213 26.1009 22.4809 27.0037 20.94 27.669C19.3991 28.3342 17.7528 28.6667 16.001 28.6667ZM15.9987 26.6667C18.9765 26.6667 21.4987 25.6334 23.5654 23.5667C25.632 21.5 26.6654 18.9778 26.6654 16C26.6654 13.0223 25.632 10.5 23.5654 8.43337C21.4987 6.36671 18.9765 5.33337 15.9987 5.33337C13.0209 5.33337 10.4987 6.36671 8.43203 8.43337C6.36536 10.5 5.33203 13.0223 5.33203 16C5.33203 18.9778 6.36536 21.5 8.43203 23.5667C10.4987 25.6334 13.0209 26.6667 15.9987 26.6667Z" />
        </g>
      </svg>
    </div>
  );
};
