// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {addCN} from '@/_core/util/cssHelper';
import {IconProps, getSize} from '../types';

export const IconCopy: React.FC<IconProps> = ({size = 'md', color = 'fill-grey700'}) => {
  const finSize = getSize(size);
  return (
    <div>
      <svg
        className={addCN(finSize, color, 'transition')}
        width="32"
        height="32"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M12.0769 23.3332C11.4034 23.3332 10.8333 23.0999 10.3666 22.6332C9.89997 22.1665 9.66663 21.5964 9.66663 20.9229V5.74352C9.66663 5.07001 9.89997 4.49992 10.3666 4.03325C10.8333 3.56659 11.4034 3.33325 12.0769 3.33325H23.2563C23.9298 3.33325 24.4999 3.56659 24.9665 4.03325C25.4332 4.49992 25.6665 5.07001 25.6665 5.74352V20.9229C25.6665 21.5964 25.4332 22.1665 24.9665 22.6332C24.4999 23.0999 23.9298 23.3332 23.2563 23.3332H12.0769ZM12.0769 21.3332H23.2563C23.3589 21.3332 23.4529 21.2905 23.5384 21.205C23.6238 21.1195 23.6666 21.0255 23.6666 20.9229V5.74352C23.6666 5.64094 23.6238 5.54691 23.5384 5.46142C23.4529 5.37595 23.3589 5.33322 23.2563 5.33322H12.0769C11.9743 5.33322 11.8803 5.37595 11.7948 5.46142C11.7093 5.54691 11.6666 5.64094 11.6666 5.74352V20.9229C11.6666 21.0255 11.7093 21.1195 11.7948 21.205C11.8803 21.2905 11.9743 21.3332 12.0769 21.3332ZM7.41023 27.9998C6.73674 27.9998 6.16667 27.7665 5.7 27.2998C5.23333 26.8331 5 26.263 5 25.5896V8.41019H6.99997V25.5896C6.99997 25.6921 7.0427 25.7862 7.12817 25.8716C7.21366 25.9571 7.30768 25.9999 7.41023 25.9999H20.5896V27.9998H7.41023Z" />
      </svg>
    </div>
  );
};
