// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {addCN} from '@/_core/util/cssHelper';
import {IconProps, getSize} from '../types';

export const IconRanking: React.FC<IconProps> = ({size = 'md', color = 'fill-grey700'}) => {
  const finSize = getSize(size);
  return (
    <div>
      <svg
        className={addCN(finSize, color, 'transition')}
        width="32"
        height="32"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M19.072 13.8205L17.6797 12.4282L21.3002 8.80769L17.6797 5.18716L19.072 3.78209L22.6925 7.40259L26.313 3.78209L27.7053 5.18716L24.0847 8.80769L27.7053 12.4282L26.313 13.8205L22.6925 10.2L19.072 13.8205ZM3.17969 14.282L9.35912 3.32056L15.5386 14.282H3.17969ZM9.37195 27.6795C7.98222 27.6795 6.79932 27.1914 5.82325 26.2154C4.84719 25.2393 4.35915 24.0582 4.35915 22.6722C4.35915 21.2651 4.84719 20.0769 5.82325 19.1077C6.79932 18.1385 7.98222 17.6539 9.37195 17.6539C10.7617 17.6539 11.9446 18.1419 12.9207 19.118C13.8967 20.094 14.3848 21.2769 14.3848 22.6667C14.3848 24.0564 13.8967 25.2393 12.9207 26.2154C11.9446 27.1914 10.7617 27.6795 9.37195 27.6795ZM9.37195 25.6795C10.2079 25.6795 10.919 25.3863 11.5053 24.8C12.0916 24.2137 12.3848 23.5026 12.3848 22.6667C12.3848 21.8307 12.0916 21.1196 11.5053 20.5333C10.919 19.947 10.2079 19.6538 9.37195 19.6538C8.53604 19.6538 7.82492 19.947 7.23859 20.5333C6.65228 21.1196 6.35912 21.8307 6.35912 22.6667C6.35912 23.5026 6.65228 24.2137 7.23859 24.8C7.82492 25.3863 8.53604 25.6795 9.37195 25.6795ZM6.59755 12.2821H12.1335L9.35912 7.42302L6.59755 12.2821ZM17.6797 27.6795V17.6539H27.7053V27.6795H17.6797ZM19.6796 25.6795H25.7053V19.6538H19.6796V25.6795Z" />
      </svg>
    </div>
  );
};
