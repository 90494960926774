// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {addCN} from '@/_core/util/cssHelper';
import {IconProps, getSize} from '../types';

export const IconLogout: React.FC<IconProps> = ({size = 'md', color = 'fill-grey700'}) => {
  const finSize = getSize(size);
  return (
    <div>
      <svg
        className={addCN(finSize, color, 'transition')}
        width="32"
        height="32"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M7.07677 27.3334C6.40326 27.3334 5.83317 27.1 5.3665 26.6334C4.89984 26.1667 4.6665 25.5966 4.6665 24.9231V7.07702C4.6665 6.40351 4.89984 5.83341 5.3665 5.36675C5.83317 4.90008 6.40326 4.66675 7.07677 4.66675H16.0126V6.66672H7.07677C6.97419 6.66672 6.88016 6.70945 6.79467 6.79492C6.7092 6.8804 6.66647 6.97444 6.66647 7.07702V24.9231C6.66647 25.0257 6.7092 25.1197 6.79467 25.2052C6.88016 25.2907 6.97419 25.3334 7.07677 25.3334H16.0126V27.3334H7.07677ZM21.6408 21.6923L20.2563 20.2462L23.5024 17H12.128V15.0001H23.5024L20.2563 11.7539L21.6408 10.3078L27.3331 16L21.6408 21.6923Z" />
      </svg>
    </div>
  );
};
