// @ts-nocheck
/* eslint-disable */

import {FC, useCallback, useEffect, useLayoutEffect, useRef, useState} from 'react';
import {logger} from '@modules/Core/util/Logger';
import {useUiStore} from '@modules/Core/util/logic/zustand';
import {TutorialCard} from '@modules/Tutorials/components/TutorialCard';
import {_TutorialStep} from '@modules/Tutorials/types/tutorial.model';

type TutorialDirection =
  | 'to-right'
  | 'to-left'
  | 'to-top'
  | 'to-bottom'
  | 'to-bottom-left'
  | 'to-bottom-right'
  | 'to-bottom-middle'
  | 'to-top-left'
  | 'to-top-right';

interface _GlobalTutorialCard {
  elementId: string | null;
  data: _TutorialStep & {direction?: TutorialDirection};
  onClose?: () => void;
  withScroll?: boolean;
}

interface Position {
  top: number;
  left: number;
}

// Utility to find the closest scrollable parent
const getScrollParent = (node: HTMLElement | null): HTMLElement | Window => {
  if (!node || node === document.body) return window;
  const {overflowY} = getComputedStyle(node);
  const isScrollable = overflowY !== 'visible' && overflowY !== 'hidden';
  return isScrollable && node.scrollHeight >= node.clientHeight ? node : getScrollParent(node.parentElement);
};

export const GlobalTutorialCard: FC<_GlobalTutorialCard> = ({withScroll = true, elementId, onClose, data}) => {
  const {direction = 'to-right'} = data;

  const [position, setPosition] = useState<Position | undefined>();
  const ref = useRef<HTMLDivElement | null>(null);
  const lastFocusedElementId = useRef<string | null>(null);
  const scaleFactor = useUiStore(state => state.scaleFactor);

  const updatePosition = useCallback(() => {
    logger.debug('updatePosition', {elementId});

    if (!elementId) return;
    const el = document.getElementById(elementId);
    const rect = el?.getBoundingClientRect();
    logger.debug('updatePosition', {elementId, rect, el});

    // if all coordinates are 0, chances are the element is dynamically created and not yet visible
    if (el && rect && rect.top + rect.left + rect.right + rect.bottom !== 0) {
      const tutorialCardHeight = ref.current?.clientHeight ?? 0;
      const tutorialCardWidth = ref.current?.clientWidth ?? 0;

      let newPos: Position | undefined;

      // Get the actual scroll position
      const {scrollX} = window;
      const {scrollY} = window;

      // Calculate positions considering both browser zoom and our UI scale factor
      const baseTop = rect.top + scrollY;
      const baseLeft = rect.left + scrollX;

      switch (direction) {
        case 'to-right':
          newPos = {
            top: baseTop + rect.height / 2 - 40 * scaleFactor,
            left: rect.right + scrollX + 25 * scaleFactor,
          };
          break;
        case 'to-left':
          newPos = {
            top: baseTop,
            left: baseLeft - tutorialCardWidth,
          };
          break;
        case 'to-top':
          newPos = {
            top: baseTop - tutorialCardHeight - 10 * scaleFactor,
            left: baseLeft + 10 * scaleFactor,
          };
          break;
        case 'to-bottom':
          newPos = {
            top: rect.bottom + scrollY + 25 * scaleFactor,
            left: baseLeft,
          };
          break;
        case 'to-bottom-left':
          newPos = {
            top: rect.bottom + scrollY,
            left: baseLeft - tutorialCardWidth,
          };
          break;
        case 'to-bottom-right':
          newPos = {
            top: rect.bottom + scrollY + 25 * scaleFactor,
            left: rect.right + scrollX - 25 * scaleFactor,
          };
          break;
        case 'to-bottom-middle':
          newPos = {
            top: rect.bottom + scrollY + 25 * scaleFactor,
            left: rect.right + scrollX - tutorialCardWidth - rect.width / 2,
          };
          break;
        case 'to-top-left':
          newPos = {
            top: baseTop - tutorialCardHeight,
            left: baseLeft - tutorialCardWidth,
          };
          break;
        case 'to-top-right':
          newPos = {
            top: baseTop - tutorialCardHeight,
            left: rect.right + scrollX,
          };
          break;
      }

      if (newPos && (!position || newPos.top !== position.top || newPos.left !== position.left)) {
        logger.debug('updatePosition will update', {elementId, rect, el, newPos});
        setPosition(newPos);
      } else {
        logger.debug('updatePosition will not update', {elementId, rect, el, newPos});
      }
    } else {
      setTimeout(updatePosition, 100);
    }
  }, [elementId, direction, position, scaleFactor]);

  useEffect(() => {
    if (elementId && lastFocusedElementId.current !== elementId) {
      const el = document.getElementById(elementId);
      logger.debug('tutorialtest: ', {elementId, withScroll});
      if (el && withScroll) {
        el.scrollIntoView({behavior: 'smooth', block: 'center'});
        lastFocusedElementId.current = elementId;
      }
    }
  }, [elementId, withScroll]);

  useEffect(() => {
    if (!elementId) return;

    const scrollParent = getScrollParent(document.getElementById(elementId));
    const handleScroll = () => {
      updatePosition();
    };

    scrollParent.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', updatePosition);

    // Also listen for zoom changes
    window.addEventListener('wheel', e => {
      if (e.ctrlKey) {
        setTimeout(updatePosition, 100);
      }
    });

    return () => {
      scrollParent.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', updatePosition);
    };
  }, [elementId, updatePosition]);

  useLayoutEffect(() => {
    updatePosition();
  }, [data, updatePosition]);

  return position ? (
    <div
      className="fixed z-[999]"
      style={{
        top: `${position.top}px`,
        left: `${position.left}px`,
      }}
      ref={ref}
    >
      <TutorialCard onClose={onClose} divRef={ref} data={data} />
    </div>
  ) : null;
};
