// @ts-nocheck
/* eslint-disable */

import React, {useState} from 'react';
import {Card} from '@modules/Core/components/base/cards/Card';
import {IconList, IconStarEmpty} from '@modules/Core/components/base/Icons/Icons';
import {Tabs} from '@modules/Core/components/base/Tabs';
import {TextLink} from '@modules/Core/components/base/TextLink';
import {Section} from '@modules/Core/components/layout/Section';
import {isSpecialFieldAnswer} from '@modules/SurveyEngine/util/questionUtil';
import {TeamCommunicationChannelsList} from '@modules/Team/components/team_analysis/way_of_working/TeamCommunicationChannelsList';
import {TeamCommunicationChannelsRanking} from '@modules/Team/components/team_analysis/way_of_working/TeamCommunicationChannelsRanking';
import {TeamCommunicationModal} from '@modules/Team/components/team_analysis/way_of_working/TeamCommunicationModal';
import {trans} from '@modules/Translations/util/i18n';

interface _Props {
  channels: string[];
  teamProfiles: any;
  transparencyAccepted: boolean;
  mode: 'communication' | 'timeManagement';
}

export const TeamCommunicationChannels: React.FC<_Props> = ({
  channels = [],
  teamProfiles,
  transparencyAccepted,
  mode,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [open, setOpen] = useState(false);

  function translateChannel(channel: string): string {
    if (isSpecialFieldAnswer(channel)) {
      return channel;
    }
    if (mode === 'communication') {
      return trans(`way_of_working.communication_channels.values.${channel}`);
    }

    return trans(`way_of_working.time_management_methods.values.${channel}`);
  }

  function body() {
    return (
      <TeamCommunicationChannelsRanking
        transparencyAccepted={transparencyAccepted}
        trans={translateChannel}
        channels={channels}
        teamProfiles={teamProfiles}
      />
    );
  }

  if (mode === 'timeManagement') {
    return (
      <Section>
        <Card>
          <div className="w-full">
            <TeamCommunicationChannelsList
              transparencyAccepted={transparencyAccepted}
              trans={translateChannel}
              channels={channels}
              teamProfiles={teamProfiles}
            />
          </div>
        </Card>
      </Section>
    );
  }

  return (
    <Section mode="columns">
      <Card title={trans('way_of_working.communication_channels.values.l_0')}>
        <TeamCommunicationChannelsList
          transparencyAccepted={transparencyAccepted}
          trans={translateChannel}
          channels={channels}
          teamProfiles={teamProfiles}
          filterByKey="l_0"
        />
      </Card>
      <Card title={trans('way_of_working.communication_channels.values.l_1')}>
        <TeamCommunicationChannelsList
          transparencyAccepted={transparencyAccepted}
          trans={translateChannel}
          channels={channels}
          teamProfiles={teamProfiles}
          filterByKey="l_1"
        />
      </Card>
      <Card title={trans('way_of_working.communication_channels.values.l_2')}>
        <TeamCommunicationChannelsList
          transparencyAccepted={transparencyAccepted}
          trans={translateChannel}
          channels={channels}
          teamProfiles={teamProfiles}
          filterByKey="l_2"
        />
      </Card>
    </Section>
  );

  return (
    <>
      <TeamCommunicationModal
        transparencyAccepted={transparencyAccepted}
        teamProfiles={teamProfiles}
        channels={channels}
        open={open}
        setOpen={setOpen}
        showModal={showModal}
        setShowModal={setShowModal}
        transChannelName={translateChannel}
      />
      <Section title={trans('way_of_working.communication')}>
        <Card>
          <div className="w-full">{body()}</div>

          <div className="flex flex-row justify-end">
            <TextLink
              label={trans('way_of_working.communication_channels.how_to_use')}
              onClick={() => setShowModal(true)}
            />
          </div>
        </Card>
      </Section>
    </>
  );
};
