// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {addCN} from '@/_core/util/cssHelper';
import {IconProps, getSize} from '../types';

export const IconRedo: React.FC<IconProps> = ({size = 'md', color = 'fill-grey700'}) => {
  const finSize = getSize(size);
  return (
    <div>
      <svg
        className={addCN(finSize, color, 'transition')}
        xmlns="http://www.w3.org/2000/svg"
        width="25"
        height="24"
        viewBox="0 0 32 32"
        fill="none"
      >
        <mask id="mask0_2731_34793" maskUnits="userSpaceOnUse" x="0" y="0" width="32" height="32">
          <rect width="32" height="32" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_2731_34793)">
          <path d="M12.8284 24.6667C10.8779 24.6667 9.20738 24.0137 7.81671 22.7077C6.42605 21.4017 5.73071 19.794 5.73071 17.8847C5.73071 15.9753 6.42605 14.3698 7.81671 13.068C9.20738 11.7662 10.8779 11.1153 12.8284 11.1153H22.1897L18.4797 7.405L19.8847 6L26 12.1153L19.8847 18.2307L18.4797 16.8257L22.1897 13.1153H12.8284C11.4368 13.1153 10.2394 13.5726 9.23605 14.487C8.23249 15.4017 7.73071 16.5342 7.73071 17.8847C7.73071 19.2351 8.23249 20.3697 9.23605 21.2883C10.2394 22.2072 11.4368 22.6667 12.8284 22.6667H22.3974V24.6667H12.8284Z" />
        </g>
      </svg>
    </div>
  );
};
