// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {addCN} from '@/_core/util/cssHelper';
import {IconProps, getSize} from '../types';

export const IconEditText: React.FC<IconProps> = ({size = 'md', color = 'fill-grey700'}) => {
  const finSize = getSize(size);
  return (
    <div>
      <svg
        className={addCN(finSize, color, 'transition')}
        width="32"
        height="32"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M7.07677 27.3332C6.40326 27.3332 5.83317 27.0999 5.3665 26.6332C4.89984 26.1666 4.6665 25.5965 4.6665 24.923V7.0769C4.6665 6.40339 4.89984 5.8333 5.3665 5.36663C5.83317 4.89996 6.40326 4.66663 7.07677 4.66663H18.6178L16.6178 6.6666H7.07677C6.97419 6.6666 6.88016 6.70933 6.79467 6.7948C6.7092 6.88029 6.66647 6.97432 6.66647 7.0769V24.923C6.66647 25.0255 6.7092 25.1196 6.79467 25.2051C6.88016 25.2905 6.97419 25.3333 7.07677 25.3333H24.9228C25.0254 25.3333 25.1194 25.2905 25.2049 25.2051C25.2904 25.1196 25.3331 25.0255 25.3331 24.923V15.2973L27.3331 13.2974V24.923C27.3331 25.5965 27.0998 26.1666 26.6331 26.6332C26.1664 27.0999 25.5963 27.3332 24.9228 27.3332H7.07677ZM12.6665 19.3332V14.7564L24.746 2.6769C24.9528 2.47005 25.1802 2.3192 25.428 2.22433C25.6759 2.12946 25.928 2.08203 26.1844 2.08203C26.4459 2.08203 26.6972 2.12946 26.9382 2.22433C27.1793 2.3192 27.3989 2.46578 27.5972 2.66406L29.2715 4.3333C29.4647 4.54012 29.613 4.76832 29.7164 5.0179C29.8198 5.26745 29.8715 5.52043 29.8715 5.77683C29.8715 6.03325 29.8275 6.28198 29.7395 6.523C29.6515 6.764 29.504 6.98792 29.2972 7.19476L17.1793 19.3332H12.6665ZM14.6665 17.3333H16.328L24.6383 9.02296L23.8075 8.1922L22.9178 7.3358L14.6665 15.5871V17.3333Z" />
      </svg>
    </div>
  );
};
