// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {addCN} from '@/_core/util/cssHelper';
import {IconProps, getSize} from '../types';

export const IconApple: React.FC<IconProps> = ({size = 'md', color = 'fill-grey700'}) => {
  const finSize = getSize(size);
  return (
    <div>
      <svg
        className={addCN(finSize, color, 'transition')}
        width="25"
        height="24"
        viewBox="0 0 25 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M14.6994 6.55183C15.0018 6.20328 15.2324 5.79842 15.3779 5.3605C15.5234 4.92258 15.581 4.46023 15.5473 4C14.6189 4.07494 13.7571 4.51161 13.1475 5.21592C12.8556 5.55348 12.6344 5.94617 12.497 6.37076C12.3596 6.79534 12.3088 7.24318 12.3475 7.68776C12.8004 7.69153 13.2481 7.59104 13.6559 7.39406C14.0638 7.19708 14.4208 6.90891 14.6994 6.55183ZM16.7153 12.5034C16.7206 11.8905 16.8821 11.289 17.1844 10.7557C17.4867 10.2225 17.9199 9.77502 18.4431 9.45564C18.1129 8.97986 17.6764 8.58756 17.1682 8.30985C16.66 8.03214 16.0941 7.87666 15.5153 7.85574C14.2674 7.72775 13.1155 8.5837 12.4515 8.5837C11.7876 8.5837 10.8516 7.87174 9.81171 7.88774C9.13186 7.91014 8.46942 8.10835 7.889 8.46305C7.30858 8.81775 6.83 9.31683 6.49993 9.91161C5.09203 12.3594 6.13996 15.9992 7.54786 17.9751C8.18782 18.943 8.98777 20.0389 10.0437 19.9989C11.0996 19.9589 11.4436 19.343 12.6675 19.343C13.8914 19.343 14.2674 19.9989 15.3073 19.9749C16.3473 19.9509 17.0832 18.983 17.7552 18.0151C18.2312 17.3123 18.603 16.5444 18.8591 15.7352C18.2251 15.4649 17.6842 15.0147 17.3032 14.4404C16.9222 13.866 16.7178 13.1927 16.7153 12.5034Z" />
      </svg>
    </div>
  );
};
