// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {addCN} from '@/_core/util/cssHelper';
import {IconProps, getSize} from '../types';

export const IconRating: React.FC<IconProps> = ({size = 'md', color = 'fill-grey700'}) => {
  const finSize = getSize(size);
  return (
    <div>
      <svg
        className={addCN(finSize, color, 'transition')}
        width="25"
        height="24"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
      >
        <mask id="mask0_7135_4403" maskUnits="userSpaceOnUse" x="0" y="0" width="32" height="32">
          <rect width="32" height="32" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_7135_4403)">
          <path d="M11.505 23.0614L15.705 20.5281L19.905 23.0947L18.805 18.2947L22.505 15.0947L17.6383 14.6614L15.705 10.1281L13.7717 14.6281L8.905 15.0614L12.605 18.2947L11.505 23.0614ZM8.47173 27.2433L10.382 19.0178L4 13.4871L12.4204 12.7563L15.705 5L18.9896 12.7563L27.41 13.4871L21.028 19.0178L22.9383 27.2433L15.705 22.8793L8.47173 27.2433Z" />
        </g>
      </svg>
    </div>
  );
};
