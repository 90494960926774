// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {addCN} from '@/_core/util/cssHelper';
import {IconProps, getSize} from '../types';

export const IconCreditCard: React.FC<IconProps> = ({size = 'md', color = 'fill-grey700'}) => {
  const finSize = getSize(size);
  return (
    <div>
      <svg
        className={addCN(finSize, color, 'transition')}
        width="32"
        height="32"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M28.6668 8.41027V23.5897C28.6668 24.2632 28.4334 24.8333 27.9668 25.2999C27.5001 25.7666 26.93 25.9999 26.2565 25.9999H5.74376C5.07025 25.9999 4.50016 25.7666 4.0335 25.2999C3.56683 24.8333 3.3335 24.2632 3.3335 23.5897V8.41027C3.3335 7.73676 3.56683 7.16667 4.0335 6.7C4.50016 6.23333 5.07025 6 5.74376 6H26.2565C26.93 6 27.5001 6.23333 27.9668 6.7C28.4334 7.16667 28.6668 7.73676 28.6668 8.41027ZM5.33346 11.2051H26.6668V8.41027C26.6668 8.30769 26.6241 8.21366 26.5386 8.12817C26.4531 8.0427 26.3591 7.99997 26.2565 7.99997H5.74376C5.64119 7.99997 5.54715 8.0427 5.46166 8.12817C5.3762 8.21366 5.33346 8.30769 5.33346 8.41027V11.2051ZM5.33346 15.4615V23.5897C5.33346 23.6922 5.3762 23.7863 5.46166 23.8718C5.54715 23.9572 5.64119 24 5.74376 24H26.2565C26.3591 24 26.4531 23.9572 26.5386 23.8718C26.6241 23.7863 26.6668 23.6922 26.6668 23.5897V15.4615H5.33346Z" />
      </svg>
    </div>
  );
};
