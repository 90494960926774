// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {addCN} from '@/_core/util/cssHelper';
import {IconProps, getSize} from '../types';

export const IconDragArrows: React.FC<IconProps> = ({size = 'md', color = 'fill-grey700'}) => {
  const finSize = getSize(size);
  return (
    <div>
      <svg
        className={addCN(finSize, color, 'transition')}
        width="25"
        height="24"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
      >
        <mask id="mask0_2733_34832" maskUnits="userSpaceOnUse" x="0" y="0" width="32" height="32">
          <rect width="32" height="32" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_2733_34832)">
          <path d="M16 28.8716L10.795 23.6666L12.2206 22.241L15 25.0206V19H17V25L19.759 22.2206L21.205 23.6666L16 28.8716ZM8.3333 21.205L3.1283 16L8.31263 10.8153L9.7383 12.241L6.9793 15H13V17H6.99996L9.7793 19.759L8.3333 21.205ZM23.6666 21.205L22.241 19.7793L25.0206 17H19V15H25L22.2206 12.241L23.6666 10.795L28.8716 16L23.6666 21.205ZM15 13V6.9793L12.2206 9.75896L10.795 8.3333L16 3.1283L21.205 8.3333L19.7793 9.75896L17 6.9793V13H15Z" />
        </g>
      </svg>
    </div>
  );
};
