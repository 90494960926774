// @ts-nocheck
/* eslint-disable */

import React from 'react';

interface _DebugItemProps {
  data: any;
}

export const DebugItem: React.FC<_DebugItemProps> = ({data}) => {
  return (
    <pre
      style={{
        backgroundColor: '#f5f5f5',
        padding: '1rem',
        borderRadius: '4px',
        overflow: 'auto',
        maxHeight: '500px',
        fontSize: '10px',
      }}
    >
      {JSON.stringify(data, null, 2)}
    </pre>
  );
};
