// @ts-nocheck
/* eslint-disable */

import React, {JSX} from 'react';
import {twMerge} from 'tailwind-merge';
import {Button} from '@modules/Core/components/base/buttons/Button';
import {_FlowStep} from '@modules/FlowEngine/types/step.model';
import {trans} from '@modules/Translations/util/i18n';

interface _Props {
  currentStep?: _FlowStep | null;
  stickyFooter: boolean;
  onNextClicked: () => void;
  onPreviousClicked: () => void;
  onSaveClicked: () => void;
  onFinishClicked: () => void;
}

const FlowActions: React.FC<_Props> = ({
  currentStep,
  stickyFooter,
  onNextClicked,
  onPreviousClicked,
  onSaveClicked,
  onFinishClicked,
}) => {
  function next(): void {
    onNextClicked();
  }

  function previous(): void {
    onPreviousClicked();
  }

  function save(): void {
    onSaveClicked();
  }

  function finish(): void {
    onFinishClicked();
  }

  function previousButton(): JSX.Element | null {
    if (currentStep?.options?.showBackButton !== false && currentStep?.index) {
      return (
        <div className="relative-col-width-2">
          <Button
            onClick={e => previous()}
            size={currentStep?.options?.backButtonSize ?? null}
            dusk="btn-back"
            variant="secondary"
          >
            {currentStep?.options?.labelBackButton ?? trans('base.back')}
          </Button>
        </div>
      );
    }
    return null;
  }

  function nextButton(): JSX.Element | null {
    if (currentStep?.options?.showNextButton !== false && !currentStep?.isLast) {
      return (
        <div className="relative-col-width-2">
          <Button onClick={e => next()} size={currentStep?.options?.nextButtonSize ?? null} dusk="btn-next">
            {currentStep?.options?.labelNextButton ?? trans('base.next')}
          </Button>
        </div>
      );
    }
    return null;
  }

  function saveButton(): JSX.Element | null {
    if (currentStep?.options?.showSaveButton) {
      return (
        <div className="relative-col-width-2">
          <Button onClick={e => save()} dusk="btn-next">
            {trans('base.save')}
          </Button>
        </div>
      );
    }
    return null;
  }

  function finishButton(): JSX.Element | null {
    if (currentStep?.isLast && currentStep?.options?.showFinishButton) {
      return (
        <div className="relative-col-width-2">
          <Button size={currentStep?.options?.finishButtonSize ?? null} onClick={e => finish()} dusk="btn-next">
            {currentStep?.options?.labelFinishButton ?? trans('base.finish')}
          </Button>
        </div>
      );
    }
    return null;
  }

  function body(): JSX.Element {
    return (
      <>
        {previousButton()}
        {saveButton()}
        {nextButton()}
        {finishButton()}
      </>
    );
  }

  return (
    <>
      {stickyFooter && currentStep?.options?.showFooter !== false && (
        <div className="fixed-bottom">
          <div className="flex flex-row-reverse">
            <div className="flex col-lg-9 justify-center">
              <div className="flex justify-between flex-row bg-blue500">
                <div className="flex justify-between flex-col gap-3">{body()}</div>
              </div>
            </div>
          </div>
        </div>
      )}

      {!stickyFooter && currentStep?.options?.showFooter !== false && (
        <div
          className={twMerge(
            'flex flex-row gap-3',
            currentStep?.options?.centerButtons ? 'justify-center' : 'justify-end'
          )}
        >
          {body()}
        </div>
      )}
    </>
  );
};

FlowActions.defaultProps = {
  currentStep: null,
};

export default FlowActions;
