// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {addCN} from '@/_core/util/cssHelper';
import {IconProps, getSize} from '../types';

export const IconPassive: React.FC<IconProps> = ({size = 'md', color = 'fill-grey700'}) => {
  const finSize = getSize(size);
  return (
    <div>
      <svg
        className={addCN(finSize, color, 'transition')}
        width="32"
        height="32"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M10.5384 26.6666H21.4615V22.6666C21.4615 21.1486 20.9307 19.8503 19.8692 18.7717C18.8077 17.693 17.5179 17.1537 16 17.1537C14.482 17.1537 13.1923 17.693 12.1307 18.7717C11.0692 19.8503 10.5384 21.1486 10.5384 22.6666V26.6666ZM16 14.8461C17.5179 14.8461 18.8077 14.3067 19.8692 13.2281C20.9307 12.1495 21.4615 10.8512 21.4615 9.33322V5.33322H10.5384V9.33322C10.5384 10.8512 11.0692 12.1495 12.1307 13.2281C13.1923 14.3067 14.482 14.8461 16 14.8461ZM6 28.6665V26.6666H8.53847V22.6666C8.53847 21.1657 8.94916 19.7994 9.77053 18.5678C10.5919 17.3362 11.6804 16.4802 13.036 15.9999C11.6804 15.511 10.5919 14.6529 9.77053 13.4256C8.94916 12.1982 8.53847 10.8341 8.53847 9.33322V5.33322H6V3.33325H25.9999V5.33322H23.4615V9.33322C23.4615 10.8341 23.0508 12.1982 22.2294 13.4256C21.408 14.6529 20.3195 15.511 18.964 15.9999C20.3195 16.4802 21.408 17.3362 22.2294 18.5678C23.0508 19.7994 23.4615 21.1657 23.4615 22.6666V26.6666H25.9999V28.6665H6Z" />
      </svg>
    </div>
  );
};
