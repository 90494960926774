// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {addCN} from '@/_core/util/cssHelper';
import {IconProps, getSize} from '../types';

export const IconHeart: React.FC<IconProps> = ({size = 'md', color = 'fill-grey700'}) => {
  const finSize = getSize(size);
  return (
    <div>
      <svg
        className={addCN(finSize, color, 'transition')}
        width="32"
        height="32"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M16.0001 27.1024L14.5284 25.7794C12.3181 23.7743 10.4903 22.0512 9.04503 20.6102C7.59974 19.1691 6.45445 17.8867 5.60916 16.7627C4.76385 15.6388 4.17325 14.6136 3.83736 13.6871C3.50145 12.7606 3.3335 11.8204 3.3335 10.8666C3.3335 8.9743 3.97153 7.39012 5.2476 6.11405C6.52366 4.83799 8.10784 4.19995 10.0001 4.19995C11.1642 4.19995 12.2642 4.47219 13.3001 5.01665C14.336 5.5611 15.236 6.34186 16.0001 7.35895C16.7642 6.34186 17.6642 5.5611 18.7001 5.01665C19.736 4.47219 20.836 4.19995 22.0001 4.19995C23.8924 4.19995 25.4766 4.83799 26.7527 6.11405C28.0287 7.39012 28.6668 8.9743 28.6668 10.8666C28.6668 11.8204 28.4988 12.7606 28.1629 13.6871C27.827 14.6136 27.2364 15.6388 26.3911 16.7627C25.5458 17.8867 24.4027 19.1691 22.9616 20.6102C21.5206 22.0512 19.6907 23.7743 17.4719 25.7794L16.0001 27.1024ZM16.0001 24.3999C18.1335 22.4803 19.889 20.835 21.2668 19.464C22.6446 18.0931 23.7335 16.9021 24.5335 15.891C25.3335 14.8798 25.889 13.982 26.2001 13.1974C26.5112 12.4127 26.6668 11.6358 26.6668 10.8666C26.6668 9.53325 26.2224 8.42214 25.3335 7.53325C24.4446 6.64436 23.3335 6.19992 22.0001 6.19992C20.9471 6.19992 19.9741 6.49864 19.0809 7.09609C18.1877 7.69351 17.4805 8.52471 16.9591 9.58969H15.0412C14.5113 8.51617 13.8019 7.68283 12.913 7.08965C12.0241 6.4965 11.0531 6.19992 10.0001 6.19992C8.67535 6.19992 7.56637 6.64436 6.6732 7.53325C5.78004 8.42214 5.33346 9.53325 5.33346 10.8666C5.33346 11.6358 5.48902 12.4127 5.80013 13.1974C6.11124 13.982 6.6668 14.8798 7.4668 15.891C8.2668 16.9021 9.35569 18.091 10.7335 19.4576C12.1112 20.8243 13.8668 22.4717 16.0001 24.3999Z" />
      </svg>
    </div>
  );
};
