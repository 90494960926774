// @ts-nocheck
/* eslint-disable */

import {logger} from '@modules/Core/util/Logger';
import {_CustomSurvey, _CustomSurveyQuestion} from '@modules/CustomSurveys/types/customSurvey.model';
import {SurveyJSJson} from '@modules/CustomSurveys/types/surveyJs.model';
import {currentLanguage} from '@modules/Translations/util/i18n';

const modelFields = ['title', 'type', 'options', 'meta'];

const supportedLangs = ['de', 'en'];

interface LocalizedText {
  [key: string]: string | undefined;
  default?: string;
  en?: string;
  de?: string;
}

export function getLocalizedText(text: LocalizedText | string | undefined): string {
  if (!text) return '';
  if (typeof text === 'string') return text;

  const lang = currentLanguage();

  // Try default
  if (text.default) {
    return text.default;
  }

  // Try current language
  if (text[lang]) {
    return text[lang] || '';
  }

  // Try English
  if (text.en) {
    return text.en;
  }

  // Try German
  if (text.de) {
    return text.de;
  }

  // Get first available value
  const availableText = Object.values(text).find(value => value);
  return availableText || '';
}

export function mapSurveyCreatorToBackend(surveyCreatorObject?: SurveyJSJson): _CustomSurvey {
  if (!surveyCreatorObject) return {} as _CustomSurvey;

  const {locale} = surveyCreatorObject;
  // survey title & description + questions.title should use surveyCreatorObject.locale,
  // and set en, de, default to the same value
  const survey: _CustomSurvey = {
    id: surveyCreatorObject.id ?? '',
    title: getLocalizedText(surveyCreatorObject.title),
    description: getLocalizedText(surveyCreatorObject.description),
    questions: surveyCreatorObject.elements?.map((element, index) => {
      const question: _CustomSurveyQuestion = {
        title: element.title || '',
        type: element.type,
        options: {},
        meta: {},
        page: 0,
        order: index,
      };

      // Extract everything else to options
      Object.keys(element).forEach(key => {
        if (!modelFields.includes(key)) {
          question.options[key] = element[key];
        }
      });

      // If 'meta' exists in the element, use it directly
      if ('meta' in element && typeof element.meta === 'object') {
        question.meta = element.meta;
      }

      return question;
    }),
  };

  return survey;
}

export function mapBackendToSurveyCreator(customSurvey: _CustomSurvey | null, currentLanguage: string): SurveyJSJson {
  if (!customSurvey) return {} as SurveyJSJson;
  logger.debug('mapBackendToSurveyCreator', customSurvey);
  const surveyJS: SurveyJSJson = {
    id: customSurvey.id,
    title: getLocalizedText(customSurvey.title),
    description: getLocalizedText(customSurvey.description),
    pages: customSurvey.pages?.map(page => ({name: page.name})) ?? [],
  };

  customSurvey.questions?.forEach(question => {
    const pageIndex = question.page;
    if (!surveyJS.pages[pageIndex]) {
      surveyJS.pages[pageIndex] = {elements: []};
    }

    // For title (looks like :{$langKey:'textValue'}) we need to extract the textValue

    const element: any = {
      ...question.options,
      title: getLocalizedText(question.title),
      type: question.type,
      meta: question.meta,
    };

    const {options} = question;

    // For each options.choices.text (looks like :{$langKey:'textValue'}) we need to extract the textValue
    if (options?.choices) {
      logger.debug('options.choices', options.choices);
      options.choices = options.choices.map((choice: any) => {
        if (typeof choice.text === 'object') {
          // Set all languages to text of current language.
          const newText = {
            default: choice.text[currentLanguage] ?? choice.text.default,
            de: choice.text[currentLanguage] ?? choice.text.default,
            en: choice.text[currentLanguage] ?? choice.text.default,
          };
          return {
            ...choice,
            text: newText,
          };
        }

        return choice;
      });
      logger.debug('options.choices after', options.choices);
    }

    if (options?.otherText && typeof options.otherText === 'object') {
      options.otherText = {
        default: options.otherText[currentLanguage] ?? options.otherText.default,
        de: options.otherText[currentLanguage] ?? options.otherText.default,
        en: options.otherText[currentLanguage] ?? options.otherText.default,
      };
    }

    element.choices = options.choices;

    if (!surveyJS.pages[pageIndex].elements) {
      surveyJS.pages[pageIndex].elements = [];
    }

    surveyJS.pages[pageIndex].elements.push(element);
  });

  return surveyJS;
}
