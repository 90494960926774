// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {addCN} from '@/_core/util/cssHelper';
import {IconProps, getSize} from '../types';

export const IconCheckTicked: React.FC<IconProps> = ({size = 'md', color = 'fill-grey700'}) => {
  const finSize = getSize(size);
  return (
    <div>
      <svg
        className={addCN(finSize, color, 'transition')}
        width="32"
        height="32"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M12.7334 23.5383L5.62061 16.4255L7.04624 14.9999L12.7334 20.6871L24.9539 8.46655L26.3795 9.89215L12.7334 23.5383Z" />
      </svg>
    </div>
  );
};
