// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {addCN} from '@/_core/util/cssHelper';
import {IconProps, getSize} from '../types';

export const IconPerson: React.FC<IconProps> = ({size = 'md', color = 'fill-grey700'}) => {
  const finSize = getSize(size);
  return (
    <div>
      <svg
        className={addCN(finSize, color, 'transition')}
        width="32"
        height="32"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M8.03093 23.0563C9.16426 22.2153 10.3989 21.5512 11.7348 21.064C13.0707 20.5768 14.4924 20.3333 16.0001 20.3333C17.5078 20.3333 18.9296 20.5768 20.2655 21.064C21.6014 21.5512 22.836 22.2153 23.9693 23.0563C24.7984 22.1452 25.4552 21.0905 25.9399 19.8922C26.4245 18.6939 26.6668 17.3965 26.6668 15.9999C26.6668 13.0443 25.6279 10.5277 23.5501 8.44989C21.4724 6.37211 18.9557 5.33322 16.0001 5.33322C13.0446 5.33322 10.5279 6.37211 8.45013 8.44989C6.37235 10.5277 5.33346 13.0443 5.33346 15.9999C5.33346 17.3965 5.57577 18.6939 6.0604 19.8922C6.54502 21.0905 7.20186 22.1452 8.03093 23.0563ZM16.0001 16.9999C14.783 16.9999 13.7566 16.5819 12.9207 15.746C12.0848 14.9101 11.6668 13.8836 11.6668 12.6666C11.6668 11.4495 12.0848 10.423 12.9207 9.58709C13.7566 8.7512 14.783 8.33325 16.0001 8.33325C17.2172 8.33325 18.2437 8.7512 19.0796 9.58709C19.9155 10.423 20.3334 11.4495 20.3334 12.6666C20.3334 13.8836 19.9155 14.9101 19.0796 15.746C18.2437 16.5819 17.2172 16.9999 16.0001 16.9999ZM16.0001 28.6665C14.2412 28.6665 12.5912 28.3357 11.0502 27.6742C9.50914 27.0127 8.16854 26.1118 7.02836 24.9717C5.88821 23.8315 4.98736 22.4909 4.32583 20.9499C3.66427 19.4089 3.3335 17.7589 3.3335 15.9999C3.3335 14.2409 3.66427 12.5909 4.32583 11.0499C4.98736 9.5089 5.88821 8.1683 7.02836 7.02812C8.16854 5.88796 9.50914 4.98712 11.0502 4.32559C12.5912 3.66403 14.2412 3.33325 16.0001 3.33325C17.7591 3.33325 19.4091 3.66403 20.9501 4.32559C22.4911 4.98712 23.8317 5.88796 24.9719 7.02812C26.1121 8.1683 27.0129 9.5089 27.6744 11.0499C28.336 12.5909 28.6668 14.2409 28.6668 15.9999C28.6668 17.7589 28.336 19.4089 27.6744 20.9499C27.0129 22.4909 26.1121 23.8315 24.9719 24.9717C23.8317 26.1118 22.4911 27.0127 20.9501 27.6742C19.4091 28.3357 17.7591 28.6665 16.0001 28.6665Z" />
      </svg>
    </div>
  );
};
