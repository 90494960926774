// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {addCN} from '@/_core/util/cssHelper';
import {IconProps, getSize} from '../types';

export const IconTeamLeader: React.FC<IconProps> = ({size = 'md', color = 'fill-grey700'}) => {
  const finSize = getSize(size);
  return (
    <div>
      <svg
        className={addCN(finSize, color, 'transition')}
        width="32"
        height="32"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M6.81051 9.64105C6.52844 9.30603 6.31006 8.94194 6.15537 8.54878C6.00068 8.1556 5.92334 7.74193 5.92334 7.30775C5.92334 6.28924 6.27967 5.4235 6.99234 4.71055C7.70498 3.99759 8.57036 3.64111 9.58847 3.64111C10.6066 3.64111 11.4724 3.99759 12.1861 4.71055C12.8998 5.4235 13.2566 6.28924 13.2566 7.30775C13.2566 7.74193 13.1793 8.1556 13.0246 8.54878C12.8699 8.94194 12.6515 9.30603 12.3694 9.64105H6.81051ZM13.4631 28.3589C12.1238 28.3589 10.9767 27.8819 10.022 26.9278C9.06735 25.9738 8.59001 24.8269 8.59001 23.4871H7.41051L6.15411 11.0257H13.0258L11.7694 23.4871H10.5899C10.5899 24.2803 10.8711 24.9573 11.4335 25.5179C11.9959 26.0786 12.672 26.359 13.4617 26.359C14.2515 26.359 14.9276 26.0778 15.49 25.5154C16.0524 24.953 16.3336 24.2769 16.3336 23.4871V8.51288C16.3336 7.17315 16.8105 6.02625 17.7642 5.07218C18.718 4.11814 19.8646 3.64111 21.204 3.64111C22.5433 3.64111 23.6904 4.11814 24.645 5.07218C25.5997 6.02625 26.0771 7.17315 26.0771 8.51288V28.3589H24.0771V8.51288C24.0771 7.72312 23.7959 7.04705 23.2335 6.48465C22.6711 5.92225 21.9951 5.64105 21.2053 5.64105C20.4156 5.64105 19.7395 5.92225 19.1771 6.48465C18.6147 7.04705 18.3335 7.72312 18.3335 8.51288V23.4871C18.3335 24.8269 17.8566 25.9738 16.9028 26.9278C15.949 27.8819 14.8025 28.3589 13.4631 28.3589ZM9.20791 21.4872H9.97204L10.8105 13.0256H8.36944L9.20791 21.4872Z" />
      </svg>
    </div>
  );
};
