// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {addCN} from '@/_core/util/cssHelper';
import {IconProps, getSize} from '../types';

export const IconUnlock: React.FC<IconProps> = ({size = 'md', color = 'fill-grey700'}) => {
  const finSize = getSize(size);
  return (
    <div>
      <svg
        className={addCN(finSize, color, 'transition')}
        width="32"
        height="32"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M8.41027 11.3333H20V8.66668C20 7.55557 19.6111 6.61113 18.8333 5.83335C18.0555 5.05557 17.1111 4.66668 16 4.66668C14.8889 4.66668 13.9444 5.05557 13.1666 5.83335C12.3889 6.61113 12 7.55557 12 8.66668H10C10 7.00175 10.5838 5.58553 11.7513 4.41802C12.9188 3.2505 14.335 2.66675 16 2.66675C17.6649 2.66675 19.0811 3.2505 20.2487 4.41802C21.4162 5.58553 21.9999 7.00175 21.9999 8.66668V11.3333H23.5897C24.2546 11.3333 24.8226 11.5688 25.2935 12.0398C25.7645 12.5107 25.9999 13.0787 25.9999 13.7436V26.2563C25.9999 26.9213 25.7645 27.4892 25.2935 27.9602C24.8226 28.4311 24.2546 28.6666 23.5897 28.6666H8.41027C7.74531 28.6666 7.17737 28.4311 6.70643 27.9602C6.23548 27.4892 6 26.9213 6 26.2563V13.7436C6 13.0787 6.23548 12.5107 6.70643 12.0398C7.17737 11.5688 7.74531 11.3333 8.41027 11.3333ZM8.41027 26.6666H23.5897C23.7093 26.6666 23.8076 26.6282 23.8846 26.5512C23.9615 26.4743 24 26.376 24 26.2563V13.7436C24 13.6239 23.9615 13.5256 23.8846 13.4487C23.8076 13.3718 23.7093 13.3333 23.5897 13.3333H8.41027C8.2906 13.3333 8.1923 13.3718 8.11537 13.4487C8.03843 13.5256 7.99997 13.6239 7.99997 13.7436V26.2563C7.99997 26.376 8.03843 26.4743 8.11537 26.5512C8.1923 26.6282 8.2906 26.6666 8.41027 26.6666ZM16 22.3333C16.6478 22.3333 17.1987 22.1064 17.6525 21.6525C18.1063 21.1987 18.3333 20.6478 18.3333 20C18.3333 19.3521 18.1063 18.8013 17.6525 18.3474C17.1987 17.8936 16.6478 17.6667 16 17.6667C15.3521 17.6667 14.8013 17.8936 14.3474 18.3474C13.8936 18.8013 13.6667 19.3521 13.6667 20C13.6667 20.6478 13.8936 21.1987 14.3474 21.6525C14.8013 22.1064 15.3521 22.3333 16 22.3333Z" />
      </svg>
    </div>
  );
};
