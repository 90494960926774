// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {addCN} from '@/_core/util/cssHelper';
import {IconProps, getSize} from '../types';

export const IconScrollable: React.FC<IconProps> = ({size = 'md', color = 'fill-grey700'}) => {
  const finSize = getSize(size);
  return (
    <div>
      <svg
        className={addCN(finSize, color, 'transition')}
        width="32"
        height="32"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M22.4745 22.2051L21.0566 20.8129L24.8361 17.0128H17.3335V15.0001H24.8361L21.0694 11.2129L22.4745 9.82056L28.6668 16.0129L22.4745 22.2051ZM9.5386 22.2051L3.3335 16.0129L9.5258 9.82056L10.9309 11.2129L7.1642 15.0001H14.6668V17.0128H7.15136L10.9309 20.8129L9.5386 22.2051Z" />
      </svg>
    </div>
  );
};
