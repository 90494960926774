// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {addCN} from '@/_core/util/cssHelper';
import {IconProps, getSize} from '../types';

export const IconUndo: React.FC<IconProps> = ({size = 'md', color = 'fill-grey700'}) => {
  const finSize = getSize(size);
  return (
    <div>
      <svg
        className={addCN(finSize, color, 'transition')}
        width="25"
        height="24"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
      >
        <mask id="mask0_7137_11911" maskUnits="userSpaceOnUse" x="0" y="0" width="32" height="32">
          <rect width="32" height="32" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_7137_11911)">
          <path d="M9.60267 24.6667V22.6667H19.1717C20.5632 22.6667 21.7607 22.2072 22.764 21.2883C23.7676 20.3697 24.2693 19.2351 24.2693 17.8847C24.2693 16.5342 23.7676 15.4017 22.764 14.487C21.7607 13.5726 20.5632 13.1153 19.1717 13.1153H9.81033L13.5203 16.8257L12.1153 18.2307L6 12.1153L12.1153 6L13.5203 7.405L9.81033 11.1153H19.1717C21.1221 11.1153 22.7927 11.7662 24.1833 13.068C25.574 14.3698 26.2693 15.9753 26.2693 17.8847C26.2693 19.794 25.574 21.4017 24.1833 22.7077C22.7927 24.0137 21.1221 24.6667 19.1717 24.6667H9.60267Z" />
        </g>
      </svg>
    </div>
  );
};
