// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {addCN} from '@/_core/util/cssHelper';
import {IconProps, getSize} from '../types';

export const IconFlow: React.FC<IconProps> = ({size = 'md', color = 'fill-grey700'}) => {
  const finSize = getSize(size);
  return (
    <div>
      <svg
        className={addCN(finSize, color, 'transition')}
        width="32"
        height="32"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M20.3333 27.6665V23.6665H15V10.3332H11.6666V14.3332H3V4.33325H11.6666V8.33325H20.3333V4.33325H28.9999V14.3332H20.3333V10.3332H16.9999V21.6666H20.3333V17.6666H28.9999V27.6665H20.3333ZM22.3333 12.3333H27V6.33319H22.3333V12.3333ZM22.3333 25.6666H27V19.6665H22.3333V25.6666ZM4.99993 12.3333H9.66667V6.33319H4.99993V12.3333Z" />
      </svg>
    </div>
  );
};
