// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {addCN} from '@/_core/util/cssHelper';
import {IconProps, getSize} from '../types';

export const IconSmileyHappy: React.FC<IconProps> = ({size = 'md', color = 'fill-grey700'}) => {
  const finSize = getSize(size);
  return (
    <div>
      <svg
        className={addCN(finSize, color, 'transition')}
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <g clipPath="url(#clip0_8304_6453)">
          <mask id="mask0_8304_6453" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
            <path d="M24 0H0V24H24V0Z" fill="white" />
          </mask>
          <g mask="url(#mask0_8304_6453)">
            <path
              d="M20.4069 12.0003C20.4069 16.645 16.6423 20.4069 12.0003 20.4069C7.35833 20.4069 3.59375 16.645 3.59375 12.0003C3.59375 7.35833 7.35833 3.59375 12.0003 3.59375C16.6423 3.59375 20.4069 7.35833 20.4069 12.0003Z"
              fill="#F0B856"
            />
            <path
              d="M20.4066 12.001C20.4066 16.6457 16.642 20.4076 12 20.4076C9.35721 20.4076 6.99813 19.1886 5.45605 17.2787C6.89829 18.4451 8.73458 19.1414 10.7338 19.1414C15.3758 19.1414 19.1403 15.3794 19.1403 10.7348C19.1403 8.73559 18.4442 6.89927 17.2778 5.45703C19.185 6.99911 20.4066 9.35818 20.4066 12.001Z"
              fill="#F4C673"
            />
            <path
              d="M7.58887 11.0008C7.58887 11.7294 8.17949 12.32 8.90807 12.32C9.63661 12.32 10.2272 11.7294 10.2272 11.0008C10.2272 10.2722 9.63661 9.68164 8.90807 9.68164C8.17949 9.68164 7.58887 10.2722 7.58887 11.0008Z"
              fill="#0D212E"
            />
            <path
              d="M8.91718 10.2555C8.85932 10.3861 8.97583 10.5643 9.17743 10.6538C9.37903 10.7431 9.58934 10.7098 9.64723 10.5793C9.70511 10.4487 9.58867 10.2704 9.38707 10.181C9.18547 10.0916 8.97507 10.125 8.91718 10.2555Z"
              fill="#263344"
            />
            <path
              d="M13.7734 11.0008C13.7734 11.7294 14.3641 12.32 15.0926 12.32C15.8212 12.32 16.4118 11.7294 16.4118 11.0008C16.4118 10.2722 15.8212 9.68164 15.0926 9.68164C14.3641 9.68164 13.7734 10.2722 13.7734 11.0008Z"
              fill="#0D212E"
            />
            <path
              d="M15.1018 10.2555C15.0439 10.3861 15.1604 10.5643 15.362 10.6538C15.5636 10.7431 15.7739 10.7098 15.8318 10.5793C15.8897 10.4487 15.7732 10.2704 15.5716 10.181C15.3701 10.0916 15.1597 10.125 15.1018 10.2555Z"
              fill="#263344"
            />
            <path
              d="M8.55654 15.3196C8.39814 15.1704 8.39055 14.9211 8.53977 14.7625C8.68892 14.6042 8.93807 14.5967 9.09689 14.7457C10.7249 16.2783 13.2755 16.2783 14.9035 14.7457C15.0619 14.5967 15.3115 14.6042 15.4606 14.7625C15.6098 14.9211 15.6023 15.1704 15.4438 15.3196C13.5127 17.1374 10.4876 17.1373 8.55654 15.3196Z"
              fill="#0D212E"
            />
          </g>
        </g>
        <defs>
          <clipPath id="clip0_8304_6453">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};
