// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {ProfileAccountDetailsStep} from '@modules/FlowEngine/components/Steps/ProfileAccountDetailsStep';
import {ProfileInfoStep} from '@modules/FlowEngine/components/Steps/ProfileInfoStep';
import {SelectionEmailsStep} from '@modules/FlowEngine/components/Steps/SelectionEmailsStep';
import {SelectStep} from '@modules/FlowEngine/components/Steps/SelectStep';
import {SurveyResultStep} from '@modules/FlowEngine/components/Steps/SurveyResultStep';
import {ComponentStep} from '../components/Steps/ComponentStep';
import {ContentEngineItemStep} from '../components/Steps/ContentEngineItemStep';
import {ContentEngineStep} from '../components/Steps/ContentEngineStep';
import {ContentStep} from '../components/Steps/ContentStep';
import {EmailsStep} from '../components/Steps/EmailsStep';
import {EmptyStep} from '../components/Steps/EmptyStep';
import {FormStep} from '../components/Steps/FormStep';
import {MultiselectStep} from '../components/Steps/MultiselectStep';
import {ProfileExercisesOverviewStep} from '../components/Steps/ProfileExercisesOverviewStep';
import {ProfileRoundStep} from '../components/Steps/ProfileRoundStep';
import {RegisterLoginStep} from '../components/Steps/RegisterLoginStep';
import {ResetPasswordStep} from '../components/Steps/ResetPasswordStep';
import {ServiceInformationStep} from '../components/Steps/ServiceInformationStep';
import {ServiceResultStep} from '../components/Steps/ServiceResultStep';
import {SurveyStep} from '../components/Steps/SurveyStep';
import {TextStep} from '../components/Steps/TextStep';

export const RegisteredSteps: Record<string, React.FC<any>> = {
  text: TextStep,
  emails: EmailsStep,
  form: FormStep,
  // email: EmailStep,
  empty: EmptyStep,
  content: ContentStep,
  selectionEmails: SelectionEmailsStep,
  select: SelectStep,
  // question: QuestionStep,
  registerLogin: RegisterLoginStep,
  resetPassword: ResetPasswordStep,
  surveyResult: SurveyResultStep,
  // addNewAddress: ProfileEditBusinessAddressStep,
  // content: ContentStep,
  // privacy: PrivacyStep,
  component: ComponentStep,
  // membersList: MembersListStep,
  // projectOverview: ProjectOverviewStep,
  multiselect: MultiselectStep,

  /**
   * Profile
   */
  profileAccountDetails: ProfileAccountDetailsStep,
  profileInfo: ProfileInfoStep,

  /**
   * Surveys
   */
  serviceInformation: ServiceInformationStep,
  survey: SurveyStep,
  serviceResult: ServiceResultStep,

  // teamDevelopmentWait: TeamDevelopmentWaitStep,
  profileExercisesOverview: ProfileExercisesOverviewStep,
  profileRound: ProfileRoundStep,

  /**
   * Engines
   */

  contentEngine: ContentEngineStep,
  contentEngineItem: ContentEngineItemStep,
};
