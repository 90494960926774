// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {addCN} from '@/_core/util/cssHelper';
import {IconProps, getSize} from '../types';

export const IconExpandSettings: React.FC<IconProps> = ({size = 'md', color = 'fill-grey700'}) => {
  const finSize = getSize(size);
  return (
    <div>
      <svg
        className={addCN(finSize, color, 'transition')}
        width="25"
        height="24"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
      >
        <mask id="mask0_7135_5839" maskUnits="userSpaceOnUse" x="0" y="0" width="32" height="32">
          <rect width="32" height="32" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_7135_5839)">
          <path d="M5.33203 23.6667H3.33203V8.33337H5.33203V23.6667ZM15.7807 23.5257L8.25503 16L15.7807 8.47437L17.173 9.86671L12.0857 15H28.6654V17H12.073L17.2064 22.1334L15.7807 23.5257Z" />
        </g>
      </svg>
    </div>
  );
};
