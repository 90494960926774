// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {addCN} from '@/_core/util/cssHelper';
import {IconProps, getSize} from '../types';

export const IconEmotionalIntelligence: React.FC<IconProps> = ({size = 'md', color = 'fill-grey700'}) => {
  const finSize = getSize(size);
  return (
    <div>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <mask
          id="mask0_6417_4954"
          className={addCN(finSize, color, 'transition')}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="24"
          height="24"
        >
          <rect width="24" height="24" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_6417_4954)">
          <path d="M12 20.3272L10.8962 19.335C9.23847 17.8311 7.86763 16.5388 6.78365 15.458C5.69968 14.3773 4.84072 13.4154 4.20675 12.5725C3.57277 11.7295 3.12982 10.9606 2.8779 10.2657C2.62597 9.57087 2.5 8.86575 2.5 8.15037C2.5 6.73115 2.97852 5.54302 3.93558 4.58597C4.89263 3.62892 6.08076 3.15039 7.49998 3.15039C8.37306 3.15039 9.19806 3.35457 9.97498 3.76292C10.7519 4.17125 11.4269 4.75682 12 5.51964C12.5731 4.75682 13.2481 4.17125 14.025 3.76292C14.8019 3.35457 15.6269 3.15039 16.5 3.15039C17.9192 3.15039 19.1073 3.62892 20.0644 4.58597C21.0214 5.54302 21.5 6.73115 21.5 8.15037C21.5 8.86575 21.374 9.57087 21.1221 10.2657C20.8701 10.9606 20.4272 11.7295 19.7932 12.5725C19.1592 13.4154 18.3019 14.3773 17.2211 15.458C16.1403 16.5388 14.7679 17.8311 13.1038 19.335L12 20.3272Z" />
        </g>
      </svg>
    </div>
  );
};
