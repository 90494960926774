// @ts-nocheck
/* eslint-disable */

export function goToCoachingSession(
  link: string = 'https://meet.softfact.works/meetings/urs-merkel/key-accounting-support'
): void {
  window.open(link, '_blank');
}

export function goToMembershipCoachingSession(): void {
  const link = 'https://meet.softfact.works/meetings/urs-merkel/key-accounting-support';
  window.open(link, '_blank');
}
