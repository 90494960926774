// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {AvatarUser} from '@modules/Core/components/base/avatar/AvatarUser';
import {StatisticLabelBadge} from '@modules/Core/components/base/badge/StatisticLabelBadge';
import {Card} from '@modules/Core/components/base/cards/Card';
import {Typography} from '@modules/Core/components/base/Typography';
import {getAchievementImage} from '@modules/Core/util/imagesUtil';
import {getProfilePic} from '@modules/Profile/util/profileUtil';
import {topPerformersLangProps} from '@modules/Statistics/config/langProps';
import {_PerformerKey, _TeamPerformerProfile} from '@modules/Team/types/teamstatistics.model';
import {trans} from '@modules/Translations/util/i18n';

interface _Props {
  performer: _TeamPerformerProfile;
  performerKey: _PerformerKey;
}

const imagesMap = {
  userActivity: 'team_moneySaver',
  exercisesCompleted: 'exercises',
  softfactsCompleted: 'softfacts',
};
export const TopPerformersCard: React.FC<_Props> = ({performer, performerKey}) => {
  const trendType = performer?.metrics?.trend > 0 ? '+' : performer?.metrics?.trend < 0 ? '-' : 'N';
  return (
    <Card>
      <div className="flex flex-row justify-between w-full">
        <img className="h-3" src={getAchievementImage(imagesMap[performerKey] ?? '', 'png')} />
        <StatisticLabelBadge change={performer?.metrics?.trend} changeType={trendType} />
      </div>

      <Typography breakWords breakLines variant="body" bold>
        {trans(topPerformersLangProps[performerKey]?.title)}
      </Typography>

      <AvatarUser image={getProfilePic(performer)} label={performer.fullName} email={performer.position} />

      <Typography breakWords breakLines variant="body2">
        {trans(topPerformersLangProps[performerKey]?.description, {
          name: performer.firstName,
        })}
      </Typography>
    </Card>
  );
};
