// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {addCN} from '@/_core/util/cssHelper';
import {IconProps, getSize} from '../types';

export const IconLongText: React.FC<IconProps> = ({size = 'md', color = 'fill-grey700'}) => {
  const finSize = getSize(size);
  return (
    <div>
      <svg
        className={addCN(finSize, color, 'transition')}
        width="25"
        height="24"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
      >
        <mask id="mask0_7135_11436" maskUnits="userSpaceOnUse" x="0" y="0" width="32" height="32">
          <rect width="32" height="32" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_7135_11436)">
          <path d="M9.66797 22.3333H18.3346V20.3333H9.66797V22.3333ZM9.66797 17H22.3346V15H9.66797V17ZM9.66797 11.6666H22.3346V9.66663H9.66797V11.6666ZM7.0783 27.3333C6.40475 27.3333 5.83464 27.1 5.36797 26.6333C4.9013 26.1666 4.66797 25.5965 4.66797 24.923V7.07696C4.66797 6.4034 4.9013 5.83329 5.36797 5.36663C5.83464 4.89996 6.40475 4.66663 7.0783 4.66663H24.9243C25.5979 4.66663 26.168 4.89996 26.6346 5.36663C27.1013 5.83329 27.3346 6.4034 27.3346 7.07696V24.923C27.3346 25.5965 27.1013 26.1666 26.6346 26.6333C26.168 27.1 25.5979 27.3333 24.9243 27.3333H7.0783ZM7.0783 25.3333H24.9243C25.027 25.3333 25.121 25.2905 25.2063 25.205C25.2919 25.1196 25.3346 25.0256 25.3346 24.923V7.07696C25.3346 6.97429 25.2919 6.88029 25.2063 6.79496C25.121 6.7094 25.027 6.66663 24.9243 6.66663H7.0783C6.97564 6.66663 6.88164 6.7094 6.7963 6.79496C6.71075 6.88029 6.66797 6.97429 6.66797 7.07696V24.923C6.66797 25.0256 6.71075 25.1196 6.7963 25.205C6.88164 25.2905 6.97564 25.3333 7.0783 25.3333Z" />
        </g>
      </svg>
    </div>
  );
};
