// @ts-nocheck
/* eslint-disable */

import React, {useCallback, useEffect, useMemo, useState, useRef} from 'react';
import {Model} from 'survey-core';
import {Survey} from 'survey-react-ui';
import useSnackbar from '@modules/Core/hooks/ui/snackbar';
import {logger} from '@modules/Core/util/Logger';
import {_CustomSurvey} from '@modules/CustomSurveys/types/customSurvey.model';
import {mapBackendToSurveyCreator} from '@modules/CustomSurveys/util/customSurveyMapperUtil';
import {finalizeCustomSurveyAnswer} from '@modules/CustomSurveys/util/customSurveysFlowActions';
import {onTextMarkdown} from '@modules/CustomSurveys/util/surveyJSEventsHandlersUtil';
import {CustomSurveyThankYouPage} from './CustomSurveyThankYouPage';
import 'survey-core/survey.i18n';
import './CustomSurveyAnswer.css';

interface _Props {
  survey: _CustomSurvey;
  language?: string;
  profileId?: string;
  setAnswers: (answers: Record<string, any>) => void;
  answers: Record<string, any>;
}

export const CustomSurveyAnswer: React.FC<_Props> = ({profileId, language, survey, setAnswers, answers}) => {
  const [completed, setCompleted] = useState(false);
  const snackbar = useSnackbar();
  const debounceTimerRef = useRef<NodeJS.Timeout>();

  const surveyModel = useMemo(() => {
    const mappedSurvey = mapBackendToSurveyCreator(survey, language);
    const model = new Model(mappedSurvey);
    model.locale = language ?? 'de';
    // Override thankyou page
    model.showCompletedPage = false;
    model.showQuestionNumbers = false;
    model.onTextMarkdown.add(onTextMarkdown);

    if (setAnswers) {
      // Hide the default complete button
      model.showNavigationButtons = false;

      // Set initial data from answers prop
      if (answers && Object.keys(answers).length > 0) {
        model.data = answers;
      }

      // Add debounced value changed event handler
      model.onValueChanged.add((sender, options) => {
        const updatedData = sender.data;

        // Clear any existing timer
        if (debounceTimerRef.current) {
          clearTimeout(debounceTimerRef.current);
        }

        // Set a new timer to update the answers after a delay
        debounceTimerRef.current = setTimeout(() => {
          setAnswers(updatedData);
        }, 50); // 50ms debounce delay
      });
    }

    model.onValidatedErrorsOnCurrentPage.add(function (sender, options) {
      logger.debug('Validation:', options);
      if (options.errors?.length) {
        snackbar.danger(options.errors[0]?.getText());

        // remove the error message from the survey
        options.errors = [];
      }
    });

    return model;
  }, [survey, language]);

  const mapDataToSubmission = (surveyData: Record<string, any>, survey: _CustomSurvey) => {
    return Object.entries(surveyData).reduce<Array<{surveyId: string; questionId: string; answer: any}>>(
      (acc, [questionName, answer]) => {
        const question = survey.questions?.find(q => q.options?.name === questionName);
        if (question) {
          acc.push({
            surveyId: survey.id,
            questionId: question.id,
            answer,
          });
        }
        return acc;
      },
      []
    );
  };

  const handleComplete = useCallback(
    async (sender: any) => {
      logger.debug('Survey completed', sender.data);

      try {
        const surveyData = surveyModel.data;
        const success = await finalizeCustomSurveyAnswer(survey.id, mapDataToSubmission(surveyData, survey));

        if (success) {
          logger.debug('Survey finalized successfully');
          setCompleted(true);
        } else {
          logger.error('Failed to finalize survey');
        }
      } catch (error) {
        logger.error('Error finalizing survey:', error);
      }
    },
    [survey]
  );

  useEffect(() => {
    surveyModel.onComplete.add(handleComplete);

    return () => {
      surveyModel.onComplete.remove(handleComplete);
    };
  }, [surveyModel]);

  logger.debug('Survey model:', {
    surveyModel,
    survey,
  });

  if (completed) {
    return <CustomSurveyThankYouPage createdBy={survey.createdBy} profileId={profileId} />;
  }
  return (
    <div className="w-full h-full survey-answer-container">
      <Survey model={surveyModel} />
    </div>
  );
};
