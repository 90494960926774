// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {addCN} from '@/_core/util/cssHelper';
import {IconProps, getSize} from '../types';

export const IconLike: React.FC<IconProps> = ({size = 'md', color = 'fill-grey700'}) => {
  const finSize = getSize(size);
  return (
    <div>
      <svg
        className={addCN(finSize, color, 'transition')}
        width="32"
        height="32"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M23.6411 27.3333H9.61553V11.3334L18.4617 2.53857L19.5898 3.66674C19.7283 3.80521 19.8432 3.98854 19.9347 4.21674C20.0261 4.44494 20.0719 4.6599 20.0719 4.86161V5.20007L18.6565 11.3334H27.5898C28.224 11.3334 28.7843 11.5766 29.2706 12.0629C29.7569 12.5492 30.0001 13.1095 30.0001 13.7437V15.8975C30.0001 16.0359 29.9821 16.1855 29.9462 16.3462C29.9103 16.5069 29.8702 16.6564 29.8257 16.7949L26.0052 25.8102C25.8138 26.2376 25.4933 26.5983 25.0437 26.8923C24.5941 27.1863 24.1266 27.3333 23.6411 27.3333ZM11.6155 25.3334H23.6411C23.7352 25.3334 23.8313 25.3077 23.9296 25.2564C24.0279 25.2052 24.1027 25.1197 24.154 25L28.0001 16V13.7437C28.0001 13.624 27.9617 13.5257 27.8847 13.4488C27.8078 13.3718 27.7095 13.3334 27.5898 13.3334H16.1283L17.8001 6.02567L11.6155 12.1847V25.3334ZM9.61553 11.3334V13.3334H5.33346V25.3334H9.61553V27.3333H3.3335V11.3334H9.61553Z" />
      </svg>
    </div>
  );
};
