// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {SoftFactDropDownV2} from '@modules/Core/components/base/softfacts/SoftFactDropDownV2';
import {logger} from '@modules/Core/util/Logger';
import {_ProfileServiceExercises} from '@modules/Profile/types/exercises.model';
import {_ProfileModule} from '@modules/Profile/types/modules.model';

interface _Props {
  modules: _ProfileModule[];
  exercises: _ProfileServiceExercises;
  hasStartedJourney?: boolean;
  focusJourneySoftFacts?: string[];
  hideOtherModules?: boolean;
}

export const ProfileModulesItem: React.FC<_Props> = ({
  modules,
  hasStartedJourney,
  exercises,
  focusJourneySoftFacts,
  hideOtherModules,
}) => {
  if (!modules?.length) return null;

  logger.debug('[ProfileModulesItem]ProfileModulesItem', {modules, hasStartedJourney, exercises});
  return (
    <SoftFactDropDownV2
      modules={modules}
      hasStartedJourney={Boolean(hasStartedJourney)}
      exercises={exercises}
      focusJourneySoftFacts={focusJourneySoftFacts}
      hideOtherModules={hideOtherModules}
    />
  );
};
