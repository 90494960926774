// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {addCN} from '@/_core/util/cssHelper';
import {IconProps, getSize} from '../types';

export const IconEasy: React.FC<IconProps> = ({size = 'md', color = 'fill-grey700'}) => {
  const finSize = getSize(size);
  return (
    <div>
      <svg
        className={addCN(finSize, color, 'transition')}
        width="32"
        height="32"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M15.0002 6.94867V2.02563H17.0001V6.94867H15.0002ZM23.0976 10.3076L21.7258 8.93587L25.1642 5.3846L26.5822 6.82303L23.0976 10.3076ZM25.0514 16.9999V15H29.9745V16.9999H25.0514ZM15.0002 29.9742V25.064H17.0001V29.9742H15.0002ZM8.92841 10.2538L5.38485 6.83587L6.83611 5.43077L10.3207 8.90254L8.92841 10.2538ZM25.1437 26.6153L21.7258 23.064L23.0771 21.7256L26.5693 25.123L25.1437 26.6153ZM2.02588 16.9999V15H6.94891V16.9999H2.02588ZM6.82328 26.6153L5.43101 25.164L8.86945 21.7256L9.59251 22.4076L10.3284 23.1102L6.82328 26.6153ZM16.0032 23.3332C13.967 23.3332 12.2352 22.6206 10.8079 21.1952C9.38056 19.7699 8.66688 18.0391 8.66688 16.003C8.66688 13.9668 9.37955 12.235 10.8049 10.8077C12.2302 9.38031 13.961 8.66663 15.9971 8.66663C18.0333 8.66663 19.7651 9.3793 21.1924 10.8046C22.6198 12.23 23.3335 13.9607 23.3335 15.9969C23.3335 18.0331 22.6208 19.7649 21.1955 21.1922C19.7701 22.6196 18.0394 23.3332 16.0032 23.3332ZM16.0002 21.3333C17.4668 21.3333 18.7224 20.811 19.7668 19.7666C20.8113 18.7222 21.3335 17.4666 21.3335 15.9999C21.3335 14.5333 20.8113 13.2777 19.7668 12.2333C18.7224 11.1888 17.4668 10.6666 16.0002 10.6666C14.5335 10.6666 13.278 11.1888 12.2335 12.2333C11.1891 13.2777 10.6668 14.5333 10.6668 15.9999C10.6668 17.4666 11.1891 18.7222 12.2335 19.7666C13.278 20.811 14.5335 21.3333 16.0002 21.3333Z" />
      </svg>
    </div>
  );
};
