// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {addCN} from '@/_core/util/cssHelper';
import {IconProps, getSize} from '../types';

export const IconSave: React.FC<IconProps> = ({size = 'md', color = 'fill-grey700'}) => {
  const finSize = getSize(size);
  return (
    <div>
      <svg
        className={addCN(finSize, color, 'transition')}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <mask
          id="mask0_3181_59"
          style={{maskType: 'alpha'}}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="24"
          height="24"
        >
          <rect width="24" height="24" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_3181_59)">
          <path d="M20.5 7.2115V18.6923C20.5 19.1974 20.325 19.625 19.975 19.975C19.625 20.325 19.1974 20.5 18.6923 20.5H5.30775C4.80258 20.5 4.375 20.325 4.025 19.975C3.675 19.625 3.5 19.1974 3.5 18.6923V5.30775C3.5 4.80258 3.675 4.375 4.025 4.025C4.375 3.675 4.80258 3.5 5.30775 3.5H16.7885L20.5 7.2115ZM19 7.85L16.15 5H5.30775C5.21792 5 5.14417 5.02883 5.0865 5.0865C5.02883 5.14417 5 5.21792 5 5.30775V18.6923C5 18.7821 5.02883 18.8558 5.0865 18.9135C5.14417 18.9712 5.21792 19 5.30775 19H18.6923C18.7821 19 18.8558 18.9712 18.9135 18.9135C18.9712 18.8558 19 18.7821 19 18.6923V7.85ZM12 17.2693C12.6923 17.2693 13.2821 17.0257 13.7693 16.5385C14.2564 16.0513 14.5 15.4616 14.5 14.7692C14.5 14.0769 14.2564 13.4872 13.7693 13C13.2821 12.5128 12.6923 12.2692 12 12.2692C11.3077 12.2692 10.7179 12.5128 10.2308 13C9.74358 13.4872 9.5 14.0769 9.5 14.7692C9.5 15.4616 9.74358 16.0513 10.2308 16.5385C10.7179 17.0257 11.3077 17.2693 12 17.2693ZM6.38475 9.8845H14.596V6.38475H6.38475V9.8845Z" />
        </g>
      </svg>
    </div>
  );
};
