// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {addCN} from '@/_core/util/cssHelper';
import {IconProps, getSize} from '../types';

export const IconSmileyNeutral: React.FC<IconProps> = ({size = 'md', color = 'fill-grey700'}) => {
  const finSize = getSize(size);
  return (
    <div>
      <svg
        className={addCN(finSize, color, 'transition')}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_8263_9770)">
          <mask
            id="mask0_8263_9770"
            style={{maskType: 'luminance'}}
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="24"
            height="24"
          >
            <path d="M24 0H0V24H24V0Z" fill="white" />
          </mask>
          <g mask="url(#mask0_8263_9770)">
            <path
              d="M20.3803 11.9995C20.3803 16.6296 16.6275 20.3798 11.9999 20.3798C7.37243 20.3798 3.61963 16.6296 3.61963 11.9995C3.61963 7.37194 7.37243 3.61914 11.9999 3.61914C16.6275 3.61914 20.3803 7.37194 20.3803 11.9995Z"
              fill="#F0B856"
            />
            <path
              d="M20.3804 12.0001C20.3804 16.6303 16.6276 20.3804 12.0001 20.3804C9.36555 20.3804 7.01384 19.1653 5.47656 17.2614C6.91432 18.4242 8.74488 19.1182 10.7378 19.1182C15.3654 19.1182 19.1182 15.368 19.1182 10.7379C19.1182 8.74488 18.4242 6.91432 17.2614 5.47656C19.1627 7.01384 20.3804 9.36555 20.3804 12.0001Z"
              fill="#F4C673"
            />
            <path
              d="M15.7622 14.9024C15.7622 15.1552 15.558 15.3594 15.3052 15.3594H8.69491C8.44502 15.3594 8.23779 15.1552 8.23779 14.9024C8.23779 14.6494 8.44502 14.4453 8.69491 14.4453H15.3052C15.558 14.4453 15.7622 14.6494 15.7622 14.9024Z"
              fill="#263344"
            />
            <path
              d="M15.9287 10.3933C15.9287 11.0852 15.3679 11.6461 14.676 11.6461C13.9841 11.6461 13.4233 11.0852 13.4233 10.3933C13.4233 9.70146 13.9841 9.14062 14.676 9.14062C15.3679 9.14062 15.9287 9.70146 15.9287 10.3933Z"
              fill="#263344"
            />
            <path
              d="M15.3772 9.75371C15.4856 9.89941 15.4971 10.0744 15.4028 10.1446C15.3086 10.2148 15.1442 10.1535 15.0358 10.0078C14.9273 9.8621 14.9159 9.68715 15.0101 9.61695C15.1044 9.5468 15.2688 9.60802 15.3772 9.75371Z"
              fill="#0D212E"
            />
            <path
              d="M10.5767 10.3933C10.5767 11.0852 10.0158 11.6461 9.32396 11.6461C8.63209 11.6461 8.07129 11.0852 8.07129 10.3933C8.07129 9.70146 8.63209 9.14062 9.32396 9.14062C10.0158 9.14062 10.5767 9.70146 10.5767 10.3933Z"
              fill="#263344"
            />
            <path
              d="M10.0251 9.75371C10.1335 9.89941 10.145 10.0744 10.0507 10.1446C9.95647 10.2148 9.79215 10.1535 9.6837 10.0078C9.57526 9.8621 9.5638 9.68715 9.6581 9.61695C9.75238 9.5468 9.9167 9.60802 10.0251 9.75371Z"
              fill="#0D212E"
            />
          </g>
        </g>
        <defs>
          <clipPath id="clip0_8263_9770">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};
