// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {addCN} from '@/_core/util/cssHelper';
import {IconProps, getSize} from '../types';

export const IconThrust: React.FC<IconProps> = ({size = 'md', color = 'fill-grey700'}) => {
  const finSize = getSize(size);
  return (
    <div>
      <svg
        className={addCN(finSize, color, 'transition')}
        width="32"
        height="32"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M14.6 20.2719L21.6717 13.2001L20.2461 11.7745L14.6 17.4206L11.7743 14.595L10.3487 16.0206L14.6 20.2719ZM16 28.6411C13.1162 27.8548 10.7286 26.1573 8.83717 23.5488C6.94572 20.9402 6 18.024 6 14.8001V7.12833L16 3.38477L25.9999 7.12833V14.8001C25.9999 18.024 25.0542 20.9402 23.1628 23.5488C21.2713 26.1573 18.8837 27.8548 16 28.6411ZM16 26.5334C18.3111 25.8001 20.2222 24.3334 21.7333 22.1334C23.2444 19.9334 24 17.489 24 14.8001V8.5001L16 5.51293L7.99997 8.5001V14.8001C7.99997 17.489 8.75552 19.9334 10.2666 22.1334C11.7777 24.3334 13.6889 25.8001 16 26.5334Z" />
      </svg>
    </div>
  );
};
