// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {addCN} from '@/_core/util/cssHelper';
import {IconProps, getSize} from '../types';

export const IconDislike: React.FC<IconProps> = ({size = 'md', color = 'fill-grey700'}) => {
  const finSize = getSize(size);
  return (
    <div>
      <svg
        className={addCN(finSize, color, 'transition')}
        width="32"
        height="32"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M8.35893 5.20508H22.3845V21.205L13.5384 29.9998L12.4102 28.8717C12.2718 28.7332 12.1568 28.5499 12.0654 28.3217C11.9739 28.0935 11.9282 27.8785 11.9282 27.6768V27.3383L13.3436 21.205H4.41023C3.77603 21.205 3.21578 20.9618 2.72947 20.4755C2.24316 19.9892 2 19.4289 2 18.7947V16.6409C2 16.5025 2.01794 16.3529 2.05383 16.1922C2.08972 16.0316 2.12989 15.882 2.17433 15.7435L5.99483 6.72818C6.1863 6.30082 6.50681 5.94013 6.95637 5.64611C7.40594 5.35209 7.87347 5.20508 8.35893 5.20508ZM20.3846 7.20505H8.35893C8.26491 7.20505 8.16876 7.23069 8.07047 7.28198C7.97216 7.33327 7.89736 7.41874 7.84607 7.53841L3.99993 16.5384V18.7947C3.99993 18.9144 4.0384 19.0127 4.11533 19.0896C4.19227 19.1666 4.29057 19.205 4.41023 19.205H15.8717L14.1999 26.5127L20.3846 20.3537V7.20505ZM22.3845 21.205V19.205H26.6666V7.20505H22.3845V5.20508H28.6666V21.205H22.3845Z" />
      </svg>
    </div>
  );
};
