// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {addCN} from '@/_core/util/cssHelper';
import {IconProps, getSize} from '../types';

export const IconMenu: React.FC<IconProps> = ({size = 'md', color = 'fill-grey700'}) => {
  const finSize = getSize(size);
  return (
    <div>
      <svg
        className={addCN(finSize, color, 'transition')}
        width="32"
        height="32"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M4.6665 23.5129V21.5129H27.3331V23.5129H4.6665ZM4.6665 17.0001V15.0001H27.3331V17.0001H4.6665ZM4.6665 10.4873V8.4873H27.3331V10.4873H4.6665Z" />
      </svg>
    </div>
  );
};
