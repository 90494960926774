// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {addCN} from '@/_core/util/cssHelper';
import {IconProps, getSize} from '../types';

export const IconAttach: React.FC<IconProps> = ({size = 'md', color = 'fill-grey700'}) => {
  const finSize = getSize(size);
  return (
    <div>
      <svg
        className={addCN(finSize, color, 'transition')}
        width="32"
        height="32"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M23.5642 20.8589C23.5642 23.0242 22.8108 24.8668 21.304 26.3867C19.7972 27.9066 17.963 28.6665 15.8015 28.6665C13.6399 28.6665 11.8036 27.9066 10.2925 26.3867C8.78142 24.8668 8.02588 23.0242 8.02588 20.8589V8.87169C8.02588 7.33324 8.56006 6.02556 9.62841 4.94865C10.6968 3.87172 12.0002 3.33325 13.5386 3.33325C15.0771 3.33325 16.3805 3.87172 17.4489 4.94865C18.5173 6.02556 19.0514 7.33324 19.0514 8.87169V20.2178C19.0514 21.1204 18.7371 21.8909 18.1084 22.5294C17.4797 23.1678 16.7135 23.487 15.8097 23.487C14.9059 23.487 14.1348 23.17 13.4964 22.536C12.8579 21.902 12.5387 21.1292 12.5387 20.2178V8.51275H14.5386V20.2178C14.5386 20.5751 14.6587 20.876 14.8989 21.1204C15.1391 21.3649 15.4378 21.4871 15.795 21.4871C16.1523 21.4871 16.4511 21.3649 16.6912 21.1204C16.9314 20.876 17.0515 20.5751 17.0515 20.2178V8.85889C17.0378 7.87426 16.6959 7.0405 16.0256 6.35759C15.3554 5.67468 14.5264 5.33322 13.5386 5.33322C12.5551 5.33322 11.7237 5.67681 11.0445 6.36399C10.3654 7.05116 10.0258 7.88706 10.0258 8.87169V20.8589C10.0121 22.4708 10.5694 23.8418 11.6976 24.9717C12.8258 26.1016 14.1953 26.6666 15.8061 26.6666C17.3942 26.6666 18.7441 26.1016 19.8558 24.9717C20.9674 23.8418 21.537 22.4708 21.5643 20.8589V8.51275H23.5642V20.8589Z" />
      </svg>
    </div>
  );
};
