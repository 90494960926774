// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {addCN} from '@/_core/util/cssHelper';
import {IconProps, getSize} from '../types';

export const IconOrder: React.FC<IconProps> = ({size = 'md', color = 'fill-grey700'}) => {
  const finSize = getSize(size);
  return (
    <div>
      <svg
        className={addCN(finSize, color, 'transition')}
        width="32"
        height="32"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M9.5386 27.5385L3.3335 21.3334L9.5386 15.1283L10.9642 16.5334L7.1642 20.3335H27.3334V22.3334H7.1642L10.9642 26.1334L9.5386 27.5385ZM22.4617 16.8719L21.0361 15.4668L24.8361 11.6667H4.66683V9.6668H24.8361L21.0361 5.86677L22.4617 4.46167L28.6668 10.6668L22.4617 16.8719Z" />
      </svg>
    </div>
  );
};
