// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {addCN} from '@/_core/util/cssHelper';
import {IconProps, getSize} from '../types';

export const IconListener: React.FC<IconProps> = ({size = 'md', color = 'fill-grey700'}) => {
  const finSize = getSize(size);
  return (
    <div>
      <svg
        className={addCN(finSize, color, 'transition')}
        width="32"
        height="32"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M11.3844 27.3334H7.07677C6.40326 27.3334 5.83317 27.1 5.3665 26.6334C4.89984 26.1667 4.6665 25.5966 4.6665 24.9231V16C4.6665 14.4274 4.96395 12.9539 5.55884 11.5795C6.1537 10.2052 6.96224 9.0069 7.98444 7.98468C9.00666 6.96248 10.2049 6.15395 11.5793 5.55908C12.9537 4.96419 14.4272 4.66675 15.9998 4.66675C17.5724 4.66675 19.0459 4.96419 20.4203 5.55908C21.7947 6.15395 22.9929 6.96248 24.0152 7.98468C25.0374 9.0069 25.8459 10.2052 26.4408 11.5795C27.0357 12.9539 27.3331 14.4274 27.3331 16V24.9231C27.3331 25.5966 27.0998 26.1667 26.6331 26.6334C26.1664 27.1 25.5963 27.3334 24.9228 27.3334H20.6152V17.8975H25.3331V16C25.3331 13.4 24.4276 11.1945 22.6165 9.38338C20.8054 7.57227 18.5998 6.66672 15.9998 6.66672C13.3998 6.66672 11.1942 7.57227 9.38314 9.38338C7.57203 11.1945 6.66647 13.4 6.66647 16V17.8975H11.3844V27.3334ZM9.38444 19.8975H6.66647V24.9231C6.66647 25.0257 6.7092 25.1197 6.79467 25.2052C6.88016 25.2907 6.97419 25.3334 7.07677 25.3334H9.38444V19.8975ZM22.6152 19.8975V25.3334H24.9228C25.0254 25.3334 25.1194 25.2907 25.2049 25.2052C25.2904 25.1197 25.3331 25.0257 25.3331 24.9231V19.8975H22.6152Z" />
      </svg>
    </div>
  );
};
