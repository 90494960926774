// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {addCN} from '@/_core/util/cssHelper';
import {IconProps, getSize} from '../types';

export const IconSend: React.FC<IconProps> = ({size = 'md', color = 'fill-grey700'}) => {
  const finSize = getSize(size);
  return (
    <div>
      <svg
        className={addCN(finSize, color, 'transition')}
        width="32"
        height="32"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M4.6665 25.6667V6.3335L27.6151 16.0001L4.6665 25.6667ZM6.66647 22.6668L22.4665 16.0001L6.66647 9.33343V14.2565L13.8972 16.0001L6.66647 17.7437V22.6668Z" />
      </svg>
    </div>
  );
};
