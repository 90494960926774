// @ts-nocheck
/* eslint-disable */

import {useMemo} from 'react';
import {
  SERVICE_PROFILE_BASE_AND_ANALYSIS,
  SERVICE_PROFILE_ROLES,
  SERVICE_PROFILE_WAY_OF_WORKING,
  SERVICE_PROFILE_MOTIVE_STRUCTURE,
  SERVICE_PROFILE_COMMUNICATION,
  SERVICE_PROFILE_EMOTIONAL_INTELLIGENCE,
  SERVICE_PROFILE_LEARNING,
  SERVICE_PROFILE_MINDFULNESS,
  SERVICE_PROFILE_MOTIVATION,
  SERVICE_PROFILE_PROACTIVITY,
  SERVICE_PROFILE_RESILIENCE,
  SERVICE_PROFILE_RESPONSIBILITY,
  SERVICE_PROFILE_SELF_EFFICACY,
  SERVICE_PROFILE_TRUST,
} from '@modules/Core/config/services';
import {trans} from '@modules/Translations/util/i18n';

export interface SoftFactDimension {
  id: string;
  name: string;
  leftLabel?: string;
  rightLabel?: string;
}

export interface SoftFactGroup {
  id: string;
  name: string;
  dimensions: SoftFactDimension[];
}

export const useSoftFactGroups = (): SoftFactGroup[] => {
  return useMemo(
    () => [
      {
        id: SERVICE_PROFILE_BASE_AND_ANALYSIS,
        name: trans('service.profile_base_and_analysis'),
        dimensions: [
          {id: 'openMindedness', name: trans('profile_result.kauai.openMindedness.title')},
          {id: 'conscientiousness', name: trans('profile_result.kauai.conscientiousness.title')},
          {id: 'extraversion', name: trans('profile_result.kauai.extraversion.title')},
          {id: 'agreeableness', name: trans('profile_result.kauai.agreeableness.title')},
          {id: 'emotionalStability', name: trans('profile_result.kauai.emotionalStability.title')},
        ],
      },
      {
        id: SERVICE_PROFILE_ROLES,
        name: trans('service.profile_roles'),
        dimensions: [
          {id: 'critic', name: trans('profile_result.roles.critic.title')},
          {id: 'social', name: trans('profile_result.roles.social.title')},
          {id: 'listener', name: trans('profile_result.roles.listener.title')},
          {id: 'evaluator', name: trans('profile_result.roles.evaluator.title')},
          {id: 'coordinator', name: trans('profile_result.roles.coordinator.title')},
          {id: 'team_leader', name: trans('profile_result.roles.team_leader.title')},
          {id: 'problem_solver', name: trans('profile_result.roles.problem_solver.title')},
          {id: 'task_completer', name: trans('profile_result.roles.task_completer.title')},
          {id: 'task_motivator', name: trans('profile_result.roles.task_motivator.title')},
          {id: 'teamwork_supporter', name: trans('profile_result.roles.teamwork_support.title')},
        ],
      },
      {
        id: SERVICE_PROFILE_WAY_OF_WORKING,
        name: trans('service.profile_way_of_working'),
        dimensions: [
          {
            id: 'leading',
            name: trans('way_of_working.leading.title'),
            leftLabel: trans('way_of_working.leading.first_facet.label'),
            rightLabel: trans('way_of_working.leading.second_facet.label'),
          },
          {
            id: 'deciding',
            name: trans('way_of_working.deciding.title'),
            leftLabel: trans('way_of_working.deciding.first_facet.label'),
            rightLabel: trans('way_of_working.deciding.second_facet.label'),
          },
          {
            id: 'disagreeing',
            name: trans('way_of_working.disagreeing.title'),
            leftLabel: trans('way_of_working.disagreeing.first_facet.label'),
            rightLabel: trans('way_of_working.disagreeing.second_facet.label'),
          },
          {
            id: 'planning',
            name: trans('way_of_working.planning.title'),
            leftLabel: trans('way_of_working.planning.first_facet.label'),
            rightLabel: trans('way_of_working.planning.second_facet.label'),
          },
        ],
      },
      {
        id: SERVICE_PROFILE_MOTIVE_STRUCTURE,
        name: trans('service.motive_structure'),
        dimensions: [
          {
            id: 'future_career',
            name: `${trans('motive_structure.future_short')} / ${trans('motive_structure.career.title')}`,
          },
          {
            id: 'future_development',
            name: `${trans('motive_structure.future_short')} / ${trans('motive_structure.development.title')}`,
          },
          {
            id: 'future_selfoptimization',
            name: `${trans('motive_structure.future_short')} / ${trans('motive_structure.selfoptimization.title')}`,
          },
          {
            id: 'future_selfrealization',
            name: `${trans('motive_structure.future_short')} / ${trans('motive_structure.selfrealization.title')}`,
          },
          {
            id: 'morale_fairness',
            name: `${trans('motive_structure.morale_short')} / ${trans('motive_structure.fairness.title')}`,
          },
          {
            id: 'morale_goalinternalization',
            name: `${trans('motive_structure.morale_short')} / ${trans('motive_structure.goalinternalization.title')}`,
          },
          {
            id: 'morale_idealism',
            name: `${trans('motive_structure.morale_short')} / ${trans('motive_structure.idealism.title')}`,
          },
          {
            id: 'morale_responsibility',
            name: `${trans('motive_structure.morale_short')} / ${trans('motive_structure.responsibility.title')}`,
          },
          {
            id: 'self_achievement',
            name: `${trans('motive_structure.self_short')} / ${trans('motive_structure.achievement.title')}`,
          },
          {
            id: 'self_fun',
            name: `${trans('motive_structure.self_short')} / ${trans('motive_structure.fun.title')}`,
          },
          {
            id: 'self_independance',
            name: `${trans('motive_structure.self_short')} / ${trans('motive_structure.independance.title')}`,
          },
          {
            id: 'self_power',
            name: `${trans('motive_structure.self_short')} / ${trans('motive_structure.power.title')}`,
          },
          {
            id: 'social_acceptance',
            name: `${trans('motive_structure.social_short')} / ${trans('motive_structure.acceptance.title')}`,
          },
          {
            id: 'social_belonging',
            name: `${trans('motive_structure.social_short')} / ${trans('motive_structure.belonging.title')}`,
          },
          {
            id: 'social_security',
            name: `${trans('motive_structure.social_short')} / ${trans('motive_structure.security.title')}`,
          },
          {
            id: 'social_status',
            name: `${trans('motive_structure.social_short')} / ${trans('motive_structure.status.title')}`,
          },
        ],
      },
      {
        id: SERVICE_PROFILE_COMMUNICATION,
        name: trans('service.profile_communication'),
        dimensions: [
          {id: 'style', name: trans('profile_modules.facets.style.title')},
          {id: 'structure', name: trans('profile_modules.facets.structure.title')},
        ],
      },
      {
        id: SERVICE_PROFILE_EMOTIONAL_INTELLIGENCE,
        name: trans('service.profile_emotional_intelligence'),
        dimensions: [
          {id: 'understanding', name: trans('profile_modules.facets.understanding.title')},
          {id: 'integration', name: trans('profile_modules.facets.integration.title')},
        ],
      },
      {
        id: SERVICE_PROFILE_LEARNING,
        name: trans('service.profile_learning'),
        dimensions: [
          {id: 'ability', name: trans('profile_modules.facets.learning_ability.title')},
          {id: 'motivation', name: trans('profile_modules.facets.learning_motivation.title')},
        ],
      },
      {
        id: SERVICE_PROFILE_MINDFULNESS,
        name: trans('service.profile_mindfulness'),
        dimensions: [
          {id: 'pause', name: trans('profile_modules.facets.pause.title')},
          {id: 'awareness', name: trans('profile_modules.facets.awareness.title')},
        ],
      },
      {
        id: SERVICE_PROFILE_MOTIVATION,
        name: trans('service.profile_motivation'),
        dimensions: [
          {id: 'arousal', name: trans('profile_modules.facets.arousal.title')},
          {id: 'persistence', name: trans('profile_modules.facets.persistence.title')},
        ],
      },
      {
        id: SERVICE_PROFILE_PROACTIVITY,
        name: trans('service.profile_proactivity'),
        dimensions: [
          {id: 'change_intention', name: trans('profile_modules.facets.change_intention.title')},
          {id: 'strength', name: trans('profile_modules.facets.strength.title')},
        ],
      },
      {
        id: SERVICE_PROFILE_RESILIENCE,
        name: trans('service.profile_resilience'),
        dimensions: [
          {id: 'flexibility', name: trans('profile_modules.facets.flexibility.title')},
          {id: 'strength', name: trans('profile_modules.facets.strength.title')},
        ],
      },
      {
        id: SERVICE_PROFILE_RESPONSIBILITY,
        name: trans('service.profile_responsibility'),
        dimensions: [
          {id: 'ownership', name: trans('profile_modules.facets.ownership.title')},
          {id: 'leadership', name: trans('profile_modules.facets.leadership.title')},
        ],
      },
      {
        id: SERVICE_PROFILE_SELF_EFFICACY,
        name: trans('service.profile_self_efficacy'),
        dimensions: [
          {id: 'security_of_competence', name: trans('profile_modules.facets.security_of_competence.title')},
          {id: 'conviction_of_effect', name: trans('profile_modules.facets.conviction_of_effect.title')},
        ],
      },
      {
        id: SERVICE_PROFILE_TRUST,
        name: trans('service.profile_trust'),
        dimensions: [
          {id: 'openness', name: trans('profile_modules.facets.openness.title')},
          {id: 'honesty', name: trans('profile_modules.facets.honesty.title')},
        ],
      },
    ],
    []
  );
};
