// @ts-nocheck
/* eslint-disable */

import React, {useEffect, useState} from 'react';
import {Button} from '@modules/Core/components/base/buttons/Button';
import {IconInfo} from '@modules/Core/components/base/Icons/Icons';
import {Input} from '@modules/Core/components/base/inputs/Input';
import {ToolTip} from '@modules/Core/components/base/ToolTip';
import {Typography} from '@modules/Core/components/base/Typography';
import {Section} from '@modules/Core/components/layout/Section';
import {LoginEmailInput} from '@modules/Core/components/platform/login/LoginEmailInput';
import {LoginPasswordInput} from '@modules/Core/components/platform/login/LoginPasswordInput';
import TOSConsent from '@modules/Core/components/platform/login/TOSConsent';
import useSnackbar from '@modules/Core/hooks/ui/snackbar';
import {register} from '@modules/Core/services/api/register';
import {handleHttpError} from '@modules/Core/util/errors';
import {dispatchReactEvent} from '@modules/Core/util/eventsUtil';
import {logger} from '@modules/Core/util/Logger';
import {trans} from '@modules/Translations/util/i18n';

interface _RegisterProps {
  // Basic data
  firstName: string | null;
  setFirstName: (firstName: string | null) => void;
  lastName: string | null;
  setLastName: (lastName: string | null) => void;
  email: string | null;
  setEmail: (email: string | null) => void;
  password: string | null;
  setPassword: (password: string | null) => void;
  institutionName: string | null;
  setInstitutionName: (institutionName: string | null) => void;
  promotionCode: string | null;
  setPromotionCode: (promotionCode: string | null) => void;

  setActiveTab: (activeTab: 'login' | 'register') => void;
  registeredEmail: string | null;
  isInvite: boolean;
  showLoginTab: boolean;
  isTeamInvite: boolean;

  // Flags
  registered: boolean;
  setRegistered: (registered: boolean) => void;
  hideInstitutionName: boolean;
  hideNewsletterFlag: boolean;
  hideRequiredIndicator: boolean;
  buttonLabel?: string;
  consentText?: string;
  hidePasswordField?: boolean;

  // Actions
  onLogin: (login: boolean) => void;
  teamInviteCode?: string;
}

export const Register: React.FC<_RegisterProps> = ({
  firstName,
  lastName,
  email,
  password,
  setFirstName,
  setLastName,
  setEmail,
  setPassword,
  institutionName,
  setInstitutionName,
  hideInstitutionName,
  setActiveTab,
  onLogin,
  registeredEmail,
  registerOptions = {},
  isInvite,
  showLoginTab,
  isTeamInvite,
  registered,
  promotionCode,
  setPromotionCode,
  registrationDone,
  setRegistrationDone,
  teamInviteCode,
  hideNewsletterFlag,
  hideRequiredIndicator,
  hidePasswordField,
  buttonLabel,
  consentText,
}) => {
  const snackbar = useSnackbar();

  const [tosPrivacy, setTosPrivacy] = useState<boolean>(true);
  const [newsLetter, setNewsLetter] = useState<boolean>(false);
  const [consent, setConsent] = useState<boolean>(true);
  useEffect(() => {
    if (registeredEmail && !isInvite) {
      setInitialRegistrationDone();
    }
  }, [registeredEmail, isInvite]);

  function onSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    onRegister();
  }

  function setInitialRegistrationDone() {
    setRegistrationDone(true);
    setEmail(registeredEmail);
    setActiveTab(showLoginTab ? 1 : 0);
  }

  async function onRegister() {
    try {
      dispatchReactEvent('navigate.start', {step: 'register', direction: 'next'});
      // TODO Move most attributes (consent, newsletter...) to registerOptions?
      const registrationResponse = await register(
        email,
        password || (registerOptions.type === 'candidate_analysis' ? 'd61eb01a-df00-4ef8-a738-4421332edc89' : null),
        firstName,
        lastName,
        tosPrivacy,
        consent,
        newsLetter,
        registerOptions,
        institutionName,
        promotionCode,
        teamInviteCode
      );
      logger.debug('Registration response', registrationResponse);
      if (registrationResponse?.status === 200 && registrationResponse?.data?.status !== 'error') {
        if (registerOptions.type === 'candidate_analysis') {
          setEmail(registrationResponse?.data?.user?.email);
          setPassword(`candidate:${registrationResponse?.data?.user?.email.split('@')[0]}`);
        }
        setRegistrationDone(true);
      } else {
        handleHttpError({response: registrationResponse});
      }
    } catch (e) {
      // handled by AuthTokenInterceptor => errorMessage()
    }
    dispatchReactEvent('navigate.done');
  }

  const showInstitution = !((isTeamInvite && !institutionName) || hideInstitutionName);

  return (
    <form onSubmit={e => onSubmit(e)}>
      <Section gap={5}>
        <Section>
          <div className="flex flex-row gap-3 w-full">
            <Input
              name="firstName"
              type="text"
              value={firstName}
              required
              dusk="register-first-name"
              placeholder={trans('profile.first_name')}
              label={trans('profile.first_name')}
              onChange={e => setFirstName(e)}
              hideRequiredIndicator={hideRequiredIndicator}
            />

            <Input
              name="lastName"
              type="text"
              value={lastName}
              required
              dusk="register-last-name"
              placeholder={trans('profile.last_name')}
              label={trans('profile.last_name')}
              onChange={e => setLastName(e)}
              hideRequiredIndicator={hideRequiredIndicator}
            />
          </div>

          <div className="flex flex-row gap-3 w-full">
            {setEmail && (
              <LoginEmailInput email={email} setEmail={setEmail} hideRequiredIndicator={hideRequiredIndicator} />
            )}
            {!hidePasswordField && (
              <LoginPasswordInput
                password={password}
                setPassword={setPassword}
                hideRequiredIndicator={hideRequiredIndicator}
              />
            )}
          </div>

          <div className="flex flex-row gap-3 w-full">
            {showInstitution && setInstitutionName && (
              <Input
                disabled={isTeamInvite}
                dusk="register-institution-name"
                value={institutionName}
                label={trans('auth.institution_name_label')}
                placeholder={trans('auth.institution_name_placeholder')}
                required
                onChange={e => setInstitutionName(e)}
                hideRequiredIndicator={hideRequiredIndicator}
              />
            )}

            {setPromotionCode && (
              <Input
                dusk="register-promotion-code"
                value={promotionCode}
                name="promotion_code"
                label={trans('auth.promotion_code_label')}
                placeholder={trans('auth.promotion_code_placeholder')}
                required={false}
                onChange={e => setPromotionCode(e)}
                style={{flex: 'auto'}}
                iconPosition="right"
                hideRequiredIndicator={hideRequiredIndicator}
                iconElement={
                  <ToolTip plc="top-left" txt={trans('promotions.tooltip')}>
                    <IconInfo />
                  </ToolTip>
                }
                // InputProps={{
                //   endAdornment: (
                //     <InputAdornment position="end">
                //       <Tooltip title={trans('promotions.tooltip')} placement="top">
                //         <InfoIcon />
                //       </Tooltip>
                //     </InputAdornment>
                //   ),
                // }}
              />
            )}
          </div>
        </Section>

        <TOSConsent
          // consent={consent}
          // setConsent={setConsent}
          // tosPrivacy={tosPrivacy}
          // setTosPrivacy={setTosPrivacy}
          newsLetter={hideNewsletterFlag ? false : newsLetter}
          setNewsLetter={hideNewsletterFlag ? undefined : setNewsLetter}
          isTeamInvite={isTeamInvite}
          isInvite={isInvite}
          consentText={consentText}
        />

        <div className="flex flex-row justify-between items-center w-full">
          <Typography>{!hideRequiredIndicator && <>*{trans('base.required_fields')}</>}</Typography>
          <div className="relative-col-width-2">
            <Button dusk="register-btn" type="submit">
              {buttonLabel || trans('register.create_account')}
            </Button>
          </div>
        </div>
      </Section>
    </form>
  );
};
