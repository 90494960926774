// @ts-nocheck
/* eslint-disable */

import React, {useEffect, useState} from 'react';
import {Section} from '@modules/Core/components/layout/Section';
import {logger} from '@modules/Core/util/Logger';
import {_BaseStepComponentOptions} from '@modules/FlowEngine/types/step.model';
import {BaseStep} from '../Base/BaseStep';

export const ComponentStep: React.FC<_BaseStepComponentOptions> = ({options}) => {
  const [component, setComponent] = useState<JSX.Element | null>(null);
  const [values, setValues] = useState<Record<string, any>>({});
  const {
    headline,
    headlineVariant,
    description,
    descriptionVariant,
    image,
    type,
    params,
    values: inputValues,
    onChange,
  } = options;

  useEffect(() => {
    if (!type) {
      return;
    }
    setComponent(
      React.createElement(type, {
        ...params,
        values: inputValues,
        onChange: (values: Record<string, any>) => {
          setValues(values);
          onChange?.(values);
        },
      })
    );
  }, [options]);

  const getValuesCallback = (): Record<string, any> => {
    return values;
  };

  return (
    <BaseStep options={options} getValueCallbackFn={getValuesCallback} inputs={Object.values(values)}>
      <Section
        title={headline}
        titleVariant={headlineVariant || 'h5'}
        description={description}
        descriptionVariant={descriptionVariant}
        image={image}
        gap={8}
      >
        {component}
      </Section>
    </BaseStep>
  );
};
