// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {addCN} from '@/_core/util/cssHelper';
import {IconProps, getSize} from '../types';

export const IconMore: React.FC<IconProps> = ({size = 'md', color = 'fill-grey700'}) => {
  const finSize = getSize(size);
  return (
    <div>
      <svg
        className={addCN(finSize, color, 'transition')}
        width="32"
        height="32"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M8.30755 17.9999C7.75757 17.9999 7.28675 17.8041 6.89508 17.4124C6.50344 17.0208 6.30762 16.5499 6.30762 16C6.30762 15.45 6.50344 14.9792 6.89508 14.5875C7.28675 14.1958 7.75757 14 8.30755 14C8.85755 14 9.32837 14.1958 9.72002 14.5875C10.1117 14.9792 10.3075 15.45 10.3075 16C10.3075 16.5499 10.1117 17.0208 9.72002 17.4124C9.32837 17.8041 8.85755 17.9999 8.30755 17.9999ZM15.9999 17.9999C15.4499 17.9999 14.9791 17.8041 14.5874 17.4124C14.1957 17.0208 13.9999 16.5499 13.9999 16C13.9999 15.45 14.1957 14.9792 14.5874 14.5875C14.9791 14.1958 15.4499 14 15.9999 14C16.5498 14 17.0207 14.1958 17.4123 14.5875C17.804 14.9792 17.9998 15.45 17.9998 16C17.9998 16.5499 17.804 17.0208 17.4123 17.4124C17.0207 17.8041 16.5498 17.9999 15.9999 17.9999ZM23.6922 17.9999C23.1422 17.9999 22.6713 17.8041 22.2797 17.4124C21.888 17.0208 21.6922 16.5499 21.6922 16C21.6922 15.45 21.888 14.9792 22.2797 14.5875C22.6713 14.1958 23.1422 14 23.6922 14C24.2421 14 24.713 14.1958 25.1046 14.5875C25.4963 14.9792 25.6921 15.45 25.6921 16C25.6921 16.5499 25.4963 17.0208 25.1046 17.4124C24.713 17.8041 24.2421 17.9999 23.6922 17.9999Z" />
      </svg>
    </div>
  );
};
