// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {addCN} from '@/_core/util/cssHelper';
import {IconProps, getSize} from '../types';

export const IconJourneyAdd: React.FC<IconProps> = ({size = 'md', color = 'fill-grey700'}) => {
  const finSize = getSize(size);
  return (
    <div>
      <svg
        className={addCN(finSize, color, 'transition')}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.3069 6.52006C11.2725 6.40619 11.2823 6.28293 11.3342 6.17641C11.3861 6.06987 11.4761 5.98845 11.585 5.94937L13.9055 5.03459C13.982 5.00471 14.0643 4.99397 14.1457 5.00323C14.227 5.01249 14.3051 5.04148 14.3735 5.08787L17.8241 7.45044C17.9183 7.51372 17.9871 7.61011 18.0179 7.7218L18.8952 11.0011C16.4383 11.0516 14.4167 12.8743 14.057 15.2429L11.3069 6.52006ZM14.0028 15.8317L13.1661 13.6042L10.5919 6.93562C10.5789 6.90473 10.5575 6.87841 10.5303 6.85993C10.5032 6.84145 10.4713 6.83159 10.4388 6.83158C10.4063 6.83157 10.3744 6.84138 10.3472 6.85985C10.3282 6.87276 10.312 6.88949 10.2996 6.90894C10.2942 6.91731 10.2896 6.92619 10.2857 6.93548L9.15194 9.70094C9.13299 9.74375 9.12304 9.79024 9.12276 9.83731C9.12247 9.8844 9.13185 9.93102 9.15027 9.97407C9.17212 10.0229 9.19463 10.0693 9.20603 10.0885L12.6931 15.6514L8.82035 10.3123C8.79619 10.2836 8.77056 10.2563 8.74357 10.2304C8.67212 10.1634 8.57965 10.1255 8.48327 10.1236L5.38719 9.979C5.35522 9.97386 5.3225 9.97952 5.29387 9.99512C5.26524 10.0107 5.24223 10.0354 5.22822 10.0656C5.21422 10.0958 5.20999 10.1299 5.21614 10.1627C5.22229 10.1956 5.2385 10.2255 5.26237 10.2481L10.5478 15.2226L14.9286 19.2733C14.9791 19.3199 15.0385 19.3279 15.0893 19.3119C15.1335 19.3084 15.176 19.2878 15.2049 19.2555C14.4539 18.3808 14 17.2434 14 16C14 15.9437 14.0009 15.8876 14.0028 15.8317ZM4.28519 10.689C4.40477 10.6866 4.52139 10.7275 4.61493 10.8046L14.6809 19.1707C15.0262 19.4577 14.8223 20.0011 14.3698 20L6.90272 19.9826C6.84841 19.9825 6.79445 19.9737 6.74277 19.9564L6.68444 19.9368C6.63584 19.9203 6.58991 19.8964 6.54822 19.8658L6.33889 19.7127L3.0324 17.1934C2.94175 17.1255 2.87811 17.0258 2.85334 16.9129L2.00972 12.9198C1.99338 12.8412 1.9977 12.7596 2.02222 12.6834C2.04673 12.6072 2.09055 12.5392 2.1491 12.4865L3.95994 10.8175C4.05059 10.7368 4.16562 10.6913 4.28519 10.689Z"
        />
        <path d="M19.0263 19.5H19.9737V16.9737H22.5V16.0263H19.9737V13.5H19.0263V16.0263H16.5V16.9737H19.0263V19.5Z" />
      </svg>
    </div>
  );
};
