// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {addCN} from '@/_core/util/cssHelper';
import {IconProps, getSize} from '../types';

export const IconArrowForward: React.FC<IconProps> = ({size = 'md', color = 'fill-grey700'}) => {
  const finSize = getSize(size);
  return (
    <div>
      <svg
        className={addCN(finSize, color, 'transition')}
        width="32"
        height="32"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M22.1692 16.9999H6V15H22.1692L14.5744 7.4051L16 6L25.9999 16L16 25.9999L14.5744 24.5948L22.1692 16.9999Z" />
      </svg>
    </div>
  );
};
