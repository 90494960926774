// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {flowRoute} from '@/_core/util/util';
import {Typography} from '@modules/Core/components/base/Typography';
import {trans} from '@modules/Translations/util/i18n';

interface _Props {
  feedbackText?: string;
  linkText?: string;
}

export const ProfileSoftfactionaryItem: React.FC<_Props> = ({
  feedbackText: externalFeedbackText,
  linkText: externalLinkText,
}) => {
  const feedbackText = externalLinkText ?? trans('base.check_out_soft_factionary');

  return (
    <div className="flex flex-row w-full items-center gap-0">
      <Typography variant="body1" dangerouslySetInnerHTML={{__html: feedbackText}} />
    </div>
  );
};
