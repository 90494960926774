// @ts-nocheck
/* eslint-disable */

import React, {useEffect, useState} from 'react';
import {flowRoute, navigateToFlow} from '@app/_core/util/util';
import {Login} from '@modules/Core/components/platform/login/Login';
import {LoginResetPassword} from '@modules/Core/components/platform/login/LoginResetPassword';
import {Register} from '@modules/Core/components/platform/login/Register';
import {RegisterConfirmation} from '@modules/Core/components/platform/login/RegisterConfirmation';
import {RegisterLoginWrapper} from '@modules/Core/components/platform/login/RegisterLoginWrapper';
import {useEffectAsync} from '@modules/Core/hooks/effectAsync';
import useSnackbar from '@modules/Core/hooks/ui/snackbar';
import {dispatchReactEvent} from '@modules/Core/util/eventsUtil';
import {performFlowAction} from '@modules/FlowEngine/services/api';
import {_BaseStepComponentOptions} from '@modules/FlowEngine/types/step.model';
import {useProfile} from '@modules/Profile/hooks/profile';
import {trans} from '@modules/Translations/util/i18n';
import {BaseStep} from '../Base/BaseStep';

export const RegisterLoginStep: React.FC<_BaseStepComponentOptions> = ({options}) => {
  const snackbar = useSnackbar();
  const [, onLogin] = useProfile();

  // Login & Register shared state
  const [email, setEmail] = useState<string>(options?.email || '');
  const [password, setPassword] = useState<string>('');

  // Login state
  const [rememberMe, setRememberMe] = useState<boolean>(false);
  const [showLost, setLostPassword] = useState<boolean>(false);

  // Register state
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [institutionName, setInstitutionName] = useState<string>('');
  const [promotionCode, setPromotionCode] = useState<string>('');
  const [registrationDone, setRegistrationDone] = useState<boolean>(false);

  // Component state
  const [activeTab, setActiveTab] = useState<'login' | 'register' | 'candidate_analysis'>('login');

  // Logging & Registering states (Used to trigger useEffect)
  const [loggedIn, setLoggedIn] = useState<boolean>(false);
  const [registered, setRegistered] = useState<boolean>(false);

  useEffectAsync(async () => {
    if (options?.isLoggedIn) {
      if (options?.inviteCode || options?.teamInviteCode) {
        const response = await performFlowAction({
          method: 'invitation.accept',
          params: {
            inviteCode: options?.inviteCode,
            teamInviteCode: options?.teamInviteCode,
          },
        });
        if (response?.data?.status === 'OK' && response?.data?.message) {
          snackbar.success(response?.data?.message);
        }
        if (response?.data?.institution_id) {
          const redirect = response?.data?.team_id ? flowRoute('TeamDashboard', response?.data?.team_id) : null;
          navigateToFlow('SwitchOrganisation', {
            institutionId: response?.data?.institution_id,
            redirect,
          });
        }
      } else {
        navigateToFlow('ProfileDashboard');
      }
    }
  }, [options?.isLoggedIn]);

  useEffect(() => {
    if (options?.type) {
      setActiveTab(options?.type);
    } else if (!options?.hideLoginTab) {
      setActiveTab('login');
    } else if (!options?.hideRegisterTab) {
      setActiveTab('register');
    } else {
      // default
      setActiveTab('login');
    }
  }, [options?.hideLoginTab, options?.hideRegisterTab, options?.type]);

  useEffect(() => {
    if (options?.institutionId || (options?.institutionName && !institutionName)) {
      setInstitutionName(options?.institutionName as string);
    }

    if (options?.email) {
      setEmail(options?.email as string);
    }
    if (options?.inviteCode) {
      setPromotionCode(options?.inviteCode as string);
    }
  }, [options]);

  useEffect(() => {
    if (loggedIn) {
      options.onComplete();
    }
  }, [loggedIn]);

  useEffect(() => {
    if (registered) {
      setRegistrationDone(true);
    }
  }, [registered]);

  useEffect(() => {
    if (activeTab === 'candidate_analysis' && registrationDone) {
      doLogin(true);
    }
  }, [registrationDone]);

  function onTabChange(tab: 'login' | 'register'): void {
    setActiveTab(tab);
    setLostPassword(false);
  }

  async function doLogin(isAfterRegister: boolean = false): Promise<void> {
    // Result is handled in a useEffect
    if (!password || !email) {
      setActiveTab('login');
      return;
    }

    dispatchReactEvent('navigate.start', {step: 'login', direction: 'next'});
    try {
      const result = await onLogin(email, password, rememberMe);
      if (result && result.status === 'success') {
        setLoggedIn(true);
        return;
      }
    } catch (e) {
      // handled by AuthTokenInterceptor => errorMessage()
    }

    // This will only be fired if the login failed
    dispatchReactEvent('navigate.done', {step: 'login', direction: 'next'});

    if (isAfterRegister) {
      setRegistered(true);
    }
  }

  if (options?.isLoggedIn) {
    dispatchReactEvent('navigate.start', {step: 'login', direction: 'next'});
    return null;
  }

  if (activeTab === 'candidate_analysis') {
    return (
      <Register
        email={email}
        setEmail={setEmail}
        password={password}
        setPassword={setPassword}
        firstName={firstName}
        setFirstName={setFirstName}
        lastName={lastName}
        setLastName={setLastName}
        registerOptions={{
          type: 'candidate_analysis',
          candidateAnalysisId: options?.candidateAnalysisId,
        }}
        setRegistrationDone={setRegistrationDone}
        hideNewsletterFlag
        hideRequiredIndicator
        hidePasswordField
        buttonLabel={trans('base.next')}
        consentText={trans('candidate_analysis.tos_content')}
      />
    );
  }

  return (
    <BaseStep options={options}>
      <RegisterLoginWrapper
        onTabChange={onTabChange}
        showLoginTab={!options.hideLoginTab}
        showRegisterTab={!options.hideRegisterTab}
        showLost={showLost}
        activeTab={activeTab}
        registrationDone={registrationDone}
      >
        {activeTab === 'login' && !showLost && (
          <Login
            showRememberMe={options.showRememberMe}
            onLogin={doLogin}
            email={email}
            password={password}
            setEmail={setEmail}
            setPassword={setPassword}
            rememberMe={rememberMe}
            setRememberMe={setRememberMe}
            showLost={showLost}
            setLostPassword={setLostPassword}
          />
        )}
        {activeTab === 'login' && showLost && (
          <LoginResetPassword email={email} setEmail={setEmail} setLostPassword={setLostPassword} />
        )}
        {activeTab === 'register' && !registrationDone && (
          <Register
            teamInviteCode={options?.teamInviteCode}
            promotionCode={promotionCode}
            setPromotionCode={setPromotionCode}
            showLoginTab={!options.hideLoginTab}
            isInvite={options.isInvite || options.inviteId}
            isTeamInvite={options.isTeamInvite}
            institutionName={institutionName}
            setInstitutionName={setInstitutionName}
            hideInstitutionName={!!options.inviteCode}
            registeredEmail={options?.email}
            onLogin={doLogin}
            registered={registered}
            setRegistered={setRegistered}
            email={email}
            password={password}
            setEmail={setEmail}
            setPassword={setPassword}
            setActiveTab={setActiveTab}
            firstName={firstName}
            setFirstName={setFirstName}
            lastName={lastName}
            setLastName={setLastName}
            registerOptions={{
              inviteId: options?.inviteId,
            }}
            registrationDone={registrationDone}
            setRegistrationDone={setRegistrationDone}
          />
        )}
        {activeTab === 'register' && registrationDone && <RegisterConfirmation email={email} onLogin={doLogin} />}
      </RegisterLoginWrapper>
    </BaseStep>
  );
};
