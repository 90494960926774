// @ts-nocheck
/* eslint-disable */

import React, {ReactElement, ReactNode, useState} from 'react';
import LocSelect, {
  ActionMeta,
  components,
  ControlProps,
  DropdownIndicatorProps,
  GroupBase,
  OptionProps,
  SingleValue,
  StylesConfig,
  Props as SelectProps,
} from 'react-select';
import {Props} from 'react-select/base';
import {addCN} from '@app/_core/util/cssHelper';
import {ToolTip} from '@modules/Core/components/base/ToolTip';
import {trans} from '@modules/Translations/util/i18n';
import {generate} from '../helper';
import {IconExpandMore} from '../Icons/Icons';

// ! we need "import {Props} from 'react-select/base'" to declare the customProps. Prettier will remove it since Props is otherwise unused, so we need to add it manually
// eslint-disable-next-line
interface _Props extends Props<undefined, false, GroupBase<undefined>> {}

// declare custom props
declare module 'react-select/base' {
  export interface Props<
    Option,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    IsMulti extends boolean,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    Group extends GroupBase<Option>,
  > {
    customProps: {
      icon?: React.ElementType;
      disabled?: boolean;
      innerPadding?: string;
      minHeight?: number;
      gap?: number;
      hideBorder?: boolean;
      isActionMenu?: boolean;
    };
  }
}

export interface _SelectValue {
  value: string | number;
  label: string | ReactNode;
  icon?: React.ElementType;
  isDisabled?: boolean;
  isHidden?: boolean;
  tooltip?: string;
}

export interface _Select {
  options: _SelectValue[];
  icon?: React.FC;
  disabled?: boolean;
  hidden?: boolean;
  open?: boolean;
  size?: 'sm' | 'md';
  value?: _SelectValue;
  onChange: (newValue: _SelectValue, actionMeta?: ActionMeta<_SelectValue>) => void;
  searchable?: boolean;
  hideBorder?: boolean;
  placeholder?: string;
  label?: string;
  required?: boolean;
  onMenuOpen?: () => void;
  containerRef?: React.RefObject<HTMLDivElement>;
  isActionMenu?: boolean;
}

interface CustomSelectProps extends SelectProps<_SelectValue, false, GroupBase<_SelectValue>> {
  customProps: {
    icon?: React.ElementType;
    disabled?: boolean;
    innerPadding?: string;
    minHeight?: number;
    gap?: number;
    hideBorder?: boolean;
    isActionMenu?: boolean;
  };
}

export const Select: React.FC<_Select> = ({
  options,
  icon,
  disabled = false,
  hidden = false,
  open,
  size = 'md',
  value,
  onChange,
  searchable = false,
  hideBorder = false,
  required = false,
  placeholder,
  label,
  onMenuOpen,
  containerRef,
  isActionMenu = false,
}) => {
  // we cant set the height. -> Use padding instead
  let innerPadding = '8px 16px';
  let minHeight = 48;
  let gap = 0;
  if (size === 'sm') {
    innerPadding = '0px 8px';
    minHeight = 38;
    gap = 16;
  }

  const getValue = (): _SelectValue | undefined => {
    if (!value?.value?.toString()?.length) return undefined;
    return options.find(option => option.value === value?.value) ?? value;
  };

  const handleChange = (newValue: SingleValue<_SelectValue>): void => {
    onChange(newValue!);
  };

  const [id] = useState(generate());

  // Check if there are any non-hidden options
  const hasSelectableOptions = options.some(option => !option.isHidden);

  // If no selectable options, add a disabled "No items" option
  const displayOptions = hasSelectableOptions
    ? options
    : [{value: 'no-items', label: trans('base.no_items'), isDisabled: true}];

  return (
    <div className={addCN('flex flex-col gap-1 w-full', hidden && 'hidden')} ref={containerRef}>
      {label && (
        <label htmlFor={id} className="text-body2-bold">
          {label}
        </label>
      )}
      <LocSelect<_SelectValue, false, GroupBase<_SelectValue>>
        id={id}
        isSearchable={searchable}
        menuIsOpen={open}
        components={{DropdownIndicator, Control, Option}}
        placeholder={isActionMenu ? undefined : placeholder}
        className="react-select-container"
        classNamePrefix="react-select"
        options={displayOptions}
        value={isActionMenu ? {value: '', label: placeholder} : getValue()}
        onChange={handleChange}
        onMenuOpen={onMenuOpen}
        customProps={{
          icon,
          disabled,
          innerPadding,
          minHeight,
          gap,
          hideBorder,
          isActionMenu,
        }}
        isDisabled={disabled}
        styles={colourStyles}
        required={required}
      />
    </div>
  );
};

const DropdownIndicator = (props: DropdownIndicatorProps<_SelectValue, false>): ReactElement => (
  <components.DropdownIndicator {...props}>
    <IconExpandMore color={props?.selectProps?.customProps?.disabled ? 'fill-white500' : undefined} />
  </components.DropdownIndicator>
);

const Control = ({children, ...props}: ControlProps<_SelectValue, false>): ReactElement => {
  const cProps = props?.selectProps?.customProps;
  return (
    <components.Control {...props}>
      {cProps?.icon ? <cProps.icon color={cProps?.disabled ? 'fill-white500' : undefined} /> : null}
      {children}
    </components.Control>
  );
};

const Option = ({children, ...props}: OptionProps<_SelectValue, false>): ReactElement => {
  const data = props?.data;

  // Don't render hidden options
  if (data?.isHidden) {
    return <></>;
  }

  let color;
  if (props.isSelected) {
    color = 'fill-white500';
  }

  if (data?.isDisabled) {
    color = 'fill-grey500';
  }

  const content = (
    <components.Option {...props}>
      <div className="flex flex-row gap-1 items-center">
        {data?.icon ? <data.icon color={color} /> : null}
        {children}
      </div>
    </components.Option>
  );

  if (data?.tooltip) {
    return <ToolTip txt={data.tooltip}>{content}</ToolTip>;
  }
  return content;
};

const colourStyles: StylesConfig<_SelectValue, false, GroupBase<_SelectValue>> = {
  container: styles => ({...styles, width: '100%'}),
  control: (styles, {isDisabled, isFocused, selectProps}) => {
    const innerPadding = selectProps?.customProps?.innerPadding;
    const minHeight = selectProps?.customProps?.minHeight;
    const gap = selectProps?.customProps?.gap ?? 0;
    const hideBorder = selectProps?.customProps?.hideBorder;
    let borderColor = 'var(--color-grey300)';
    if (isFocused) borderColor = 'var(--color-blue700)';
    if (isDisabled) borderColor = 'var(--color-grey500)';
    if (hideBorder) borderColor = 'transparent';

    return {
      ...styles,
      fontSize: '1rem',
      borderRadius: 8,
      padding: innerPadding,
      minHeight,
      gap,
      borderColor,
      // boxShadow: '0 0 0 1px var(--color-blue700)', // todo @jonny all borders should actually be boxShadow. Looks nicer
      boxShadow: 'none',
      backgroundColor: isDisabled ? 'var(--color-grey50)' : 'var(--color-white500)',
      '&:hover': {
        backgroundColor: 'var(--color-grey100)',
      },
    };
  },
  singleValue: (styles, {isDisabled}) => ({
    ...styles,
    color: isDisabled ? 'var(--color-grey500)' : undefined,
  }),
  indicatorSeparator: styles => ({
    ...styles,
    display: 'none',
  }),
  dropdownIndicator: styles => ({
    ...styles,
    padding: 0,
  }),
  valueContainer: (styles, props) => ({
    ...styles,
    padding: props?.selectProps?.customProps?.icon ? '2px 8px' : '0',
  }),
  menu: styles => ({
    ...styles,
    marginTop: 4,
    boxShadow: 'none',
    backgroundColor: 'var(--color-white500)',
    border: '1px solid var(--color-grey300)',
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
  }),
  menuList: styles => ({
    ...styles,
    padding: 8,
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
  }),
  option: (styles, props) => ({
    ...styles,
    padding: '6px 8px 6px 12px',
    backgroundColor:
      props.isSelected && !(props.selectProps as CustomSelectProps).customProps.isActionMenu
        ? 'var(--color-blue700)'
        : 'var(--color-white500)',
    fontSize: '1rem',
    fontWeight: 400,
    color:
      props.isSelected && !(props.selectProps as CustomSelectProps).customProps.isActionMenu
        ? 'var(--color-white500)'
        : props.isDisabled
          ? 'var(--color-grey500)'
          : 'var(--color-prussian300)',
    borderRadius: 4,
    cursor: props.isDisabled ? 'not-allowed' : 'pointer',
    '&:hover': {
      backgroundColor: props.isDisabled
        ? undefined
        : props.isSelected && !(props.selectProps as CustomSelectProps).customProps.isActionMenu
          ? 'var(--color-blue700)'
          : 'var(--color-grey100)',
    },
  }),
  placeholder: (styles, {isDisabled}) => ({
    ...styles,
    color: isDisabled ? 'var(--color-white500)' : 'var(--color-prussian300)',
    fontSize: '1rem',
    fontWeight: 400,
  }),
};
