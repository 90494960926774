// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {addCN} from '@/_core/util/cssHelper';
import {IconProps, getSize} from '../types';

export const IconProactivity: React.FC<IconProps> = ({size = 'md', color = 'fill-grey700'}) => {
  const finSize = getSize(size);
  return (
    <div>
      <svg
        className={addCN(finSize, color, 'transition')}
        width="32"
        height="32"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M16.0002 28.8717L10.7951 23.6666L12.2207 22.241L15.0002 25.0205V16.9999L7.00012 16.9794L9.77965 19.7589L8.33352 21.205L3.12842 15.9999L8.31302 10.8154L9.73862 12.241L6.97962 15H15.0002V6.97937L12.2207 9.75888L10.7951 8.33327L16.0002 3.12817L21.2053 8.33327L19.7797 9.75888L17.0002 6.97937V15L25.0003 15.0205L22.2207 12.241L23.6669 10.7948L28.872 15.9999L23.6669 21.205L22.2413 19.7794L25.0208 16.9999H17.0002L16.9797 25L19.7592 22.2205L21.2053 23.6666L16.0002 28.8717Z" />
      </svg>
    </div>
  );
};
