// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {addCN} from '@/_core/util/cssHelper';
import {IconProps, getSize} from '../types';

export const IconBriefcase: React.FC<IconProps> = ({size = 'md', color = 'fill-grey700'}) => {
  const finSize = getSize(size);
  return (
    <div>
      <svg
        className={addCN(finSize, color, 'transition')}
        width="32"
        height="32"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M5.74376 27.3332C5.07025 27.3332 4.50016 27.0999 4.0335 26.6332C3.56683 26.1665 3.3335 25.5964 3.3335 24.9229V11.0769C3.3335 10.4034 3.56683 9.83327 4.0335 9.3666C4.50016 8.89993 5.07025 8.6666 5.74376 8.6666H11.3335V6.41023C11.3335 5.73674 11.5668 5.16667 12.0335 4.7C12.5002 4.23333 13.0703 4 13.7438 4H18.2565C18.93 4 19.5001 4.23333 19.9668 4.7C20.4334 5.16667 20.6668 5.73674 20.6668 6.41023V8.6666H26.2565C26.93 8.6666 27.5001 8.89993 27.9668 9.3666C28.4334 9.83327 28.6668 10.4034 28.6668 11.0769V24.9229C28.6668 25.5964 28.4334 26.1665 27.9668 26.6332C27.5001 27.0999 26.93 27.3332 26.2565 27.3332H5.74376ZM13.3335 8.6666H18.6668V6.41023C18.6668 6.30766 18.6241 6.21363 18.5386 6.12817C18.4531 6.04268 18.3591 5.99993 18.2565 5.99993H13.7438C13.6412 5.99993 13.5472 6.04268 13.4617 6.12817C13.3762 6.21363 13.3335 6.30766 13.3335 6.41023V8.6666ZM26.6668 19.6665H19.3334V21.9999H12.6668V19.6665H5.33346V24.9229C5.33346 25.0255 5.3762 25.1195 5.46166 25.205C5.54715 25.2905 5.64119 25.3332 5.74376 25.3332H26.2565C26.3591 25.3332 26.4531 25.2905 26.5386 25.205C26.6241 25.1195 26.6668 25.0255 26.6668 24.9229V19.6665ZM14.6668 19.9999H17.3335V17.3332H14.6668V19.9999ZM5.33346 17.6666H12.6668V15.3333H19.3334V17.6666H26.6668V11.0769C26.6668 10.9743 26.6241 10.8803 26.5386 10.7948C26.4531 10.7093 26.3591 10.6666 26.2565 10.6666H5.74376C5.64119 10.6666 5.54715 10.7093 5.46166 10.7948C5.3762 10.8803 5.33346 10.9743 5.33346 11.0769V17.6666Z" />
      </svg>
    </div>
  );
};
