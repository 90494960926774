// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {addCN} from '@/_core/util/cssHelper';
import {getSize, IconProps} from '../types';

export const IconCandidateAdd: React.FC<IconProps> = ({size = 'md', color = 'fill-grey700'}) => {
  const finSize = getSize(size);
  return (
    <div>
      <svg
        className={addCN(finSize, color, 'transition')}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <mask
          id="mask0_3244_87"
          style={{maskType: 'alpha'}}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="24"
          height="24"
        >
          <rect width="24" height="24" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_3244_87)">
          <path d="M2.7093 20.5283V22H15.6427C15.2115 21.5823 14.8585 21.0836 14.6081 20.5283H2.7093Z" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M19.5581 13.8126V6.41512C19.5581 5.91948 19.3872 5.49996 19.0453 5.15657C18.7035 4.81317 18.2859 4.64147 17.7924 4.64147H2.76571C2.27229 4.64147 1.85465 4.81317 1.51279 5.15657C1.17093 5.49996 1 5.91948 1 6.41512V17.5849C1 18.0805 1.17093 18.5 1.51279 18.8434C1.85465 19.1868 2.27229 19.3585 2.76571 19.3585H14.2527C14.2089 19.0978 14.186 18.8299 14.186 18.5567C14.186 17.9359 14.3038 17.3428 14.518 16.7985C14.121 16.5132 13.7022 16.2697 13.2618 16.068C12.3389 15.6453 11.3447 15.434 10.2791 15.434C9.21344 15.434 8.2192 15.6453 7.29634 16.068C6.37348 16.4906 5.54577 17.0968 4.81321 17.8868H2.76571C2.6905 17.8868 2.62164 17.8553 2.55913 17.7924C2.49645 17.7296 2.46512 17.6604 2.46512 17.5849V6.41512C2.46512 6.33957 2.49645 6.2704 2.55913 6.20761C2.62164 6.14465 2.6905 6.11317 2.76571 6.11317H17.7924C17.8676 6.11317 17.9365 6.14465 17.999 6.20761C18.0617 6.2704 18.093 6.33957 18.093 6.41512V13.8505C18.3703 13.8 18.6559 13.7737 18.9477 13.7737C19.1545 13.7737 19.3583 13.7869 19.5581 13.8126ZM13.5023 17.8868H7.05581C7.52791 17.5598 8.03663 17.3145 8.58198 17.151C9.12733 16.9875 9.69302 16.9057 10.2791 16.9057C10.8651 16.9057 11.4308 16.9875 11.9762 17.151C12.5215 17.3145 13.0302 17.5598 13.5023 17.8868Z"
          />
          <path d="M2.7093 2V3.47171H17.8488V2H2.7093Z" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.1808 12.6841C11.6581 13.2092 11.0242 13.4717 10.2791 13.4717C9.53398 13.4717 8.90007 13.2092 8.37735 12.6841C7.85447 12.1589 7.59302 11.522 7.59302 10.7736C7.59302 10.0251 7.85447 9.38838 8.37735 8.8633C8.90007 8.33807 9.53398 8.07545 10.2791 8.07545C11.0242 8.07545 11.6581 8.33807 12.1808 8.8633C12.7037 9.38838 12.9651 10.0251 12.9651 10.7736C12.9651 11.522 12.7037 12.1589 12.1808 12.6841ZM9.41416 11.6426C9.65135 11.8809 9.93965 12 10.2791 12C10.6185 12 10.9068 11.8809 11.144 11.6426C11.3813 11.4042 11.5 11.1145 11.5 10.7736C11.5 10.4328 11.3813 10.1432 11.144 9.90478C10.9068 9.66636 10.6185 9.54716 10.2791 9.54716C9.93965 9.54716 9.65135 9.66636 9.41416 9.90478C9.17681 10.1432 9.05814 10.4328 9.05814 10.7736C9.05814 11.1145 9.17681 11.4042 9.41416 11.6426Z"
          />
          <path d="M15.6512 19.1699V17.6982H22V19.1699H15.6512Z" />
          <path d="M18.093 15.2454H19.5581V21.6228H18.093V15.2454Z" />
        </g>
      </svg>
    </div>
  );
};
