// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {addCN} from '@/_core/util/cssHelper';
import {IconProps, getSize} from '../types';

export const IconTrash: React.FC<IconProps> = ({size = 'md', color = 'fill-grey700'}) => {
  const finSize = getSize(size);
  return (
    <div>
      <svg
        className={addCN(finSize, color, 'transition')}
        width="32"
        height="32"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M9.7436 27.3333C9.07864 27.3333 8.5107 27.0978 8.03977 26.6269C7.56881 26.1559 7.33333 25.588 7.33333 24.923V7.99999H6V6.00002H12V4.82056H20V6.00002H25.9999V7.99999H24.6666V24.923C24.6666 25.5965 24.4333 26.1666 23.9666 26.6333C23.4999 27.1 22.9298 27.3333 22.2563 27.3333H9.7436ZM22.6666 7.99999H9.3333V24.923C9.3333 25.0427 9.37177 25.141 9.4487 25.2179C9.52563 25.2949 9.62393 25.3333 9.7436 25.3333H22.2563C22.3589 25.3333 22.4529 25.2906 22.5384 25.2051C22.6239 25.1196 22.6666 25.0256 22.6666 24.923V7.99999ZM12.5385 22.6667H14.5384V10.6667H12.5385V22.6667ZM17.4615 22.6667H19.4615V10.6667H17.4615V22.6667Z" />
      </svg>
    </div>
  );
};
