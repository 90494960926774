// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {addCN} from '@/_core/util/cssHelper';
import {IconProps, getSize} from '../types';

export const IconCycle: React.FC<IconProps> = ({size = 'md', color = 'fill-grey700'}) => {
  const finSize = getSize(size);
  return (
    <div>
      <svg
        className={addCN(finSize, color, 'transition')}
        width="32"
        height="32"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M11.377 25.1025C9.02318 24.1812 7.09284 22.6662 5.58602 20.5577C4.07917 18.4492 3.32575 16.0479 3.32575 13.3539C3.32575 12.7163 3.3719 12.0876 3.46422 11.468C3.55653 10.8483 3.69926 10.2419 3.89242 9.64872L1.67955 10.9333L0.692383 9.21032L6.46928 5.87702L9.80258 11.6334L8.05905 12.6462L6.15648 9.34615C5.88639 9.97179 5.68596 10.6218 5.55518 11.2961C5.42441 11.9705 5.35902 12.6607 5.35902 13.3667C5.35902 15.6419 6.00988 17.6864 7.31162 19.5001C8.61333 21.3137 10.3061 22.5958 12.3898 23.3462L11.377 25.1025ZM20.4744 8.66665V6.66668H24.2744C23.2607 5.40002 22.0317 4.42096 20.5872 3.72952C19.1428 3.03805 17.6137 2.69232 16 2.69232C14.6838 2.69232 13.447 2.91326 12.2898 3.35515C11.1325 3.79704 10.0898 4.41372 9.16155 5.20518L8.14875 3.43598C9.23422 2.58127 10.4355 1.91033 11.7526 1.42315C13.0697 0.935971 14.4744 0.692383 15.9667 0.692383C17.7137 0.692383 19.3778 1.03084 20.959 1.70775C22.5402 2.38468 23.9342 3.36247 25.141 4.64112V2.00002H27.141V8.66665H20.4744ZM20.1334 28.6025L14.3565 25.2564L17.6898 19.5129L19.4129 20.5128L17.4231 23.9205C20.0539 23.5599 22.2479 22.3829 24.0052 20.3898C25.7624 18.3966 26.6411 16.0556 26.6411 13.3667C26.6411 13.0111 26.6227 12.6645 26.5859 12.3269C26.5492 11.9893 26.4872 11.6581 26.4 11.3333H28.4461C28.5111 11.6581 28.5598 11.9837 28.5923 12.3103C28.6248 12.6368 28.641 12.9778 28.641 13.3334C28.641 16.2821 27.738 18.897 25.932 21.1782C24.1261 23.4594 21.8026 24.9384 18.9615 25.6154L21.1334 26.8795L20.1334 28.6025Z" />
      </svg>
    </div>
  );
};
