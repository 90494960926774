// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {addCN} from '@/_core/util/cssHelper';
import {IconProps, getSize} from '../types';

export const IconZoomOut: React.FC<IconProps> = ({size = 'md', color = 'fill-grey700'}) => {
  const finSize = getSize(size);
  return (
    <div>
      <svg
        className={addCN(finSize, color, 'transition')}
        width="32"
        height="32"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M26.0565 27.4359L17.6821 19.0616C17.0154 19.612 16.2488 20.0428 15.3821 20.3539C14.5154 20.665 13.6189 20.8205 12.6924 20.8205C10.4135 20.8205 8.4848 20.0315 6.90631 18.4534C5.32782 16.8753 4.53857 14.9471 4.53857 12.6688C4.53857 10.3905 5.32762 8.46163 6.90571 6.88214C8.4838 5.30267 10.412 4.51294 12.6903 4.51294C14.9686 4.51294 16.8975 5.30219 18.477 6.88067C20.0565 8.45916 20.8462 10.3879 20.8462 12.6667C20.8462 13.6189 20.6864 14.5283 20.3667 15.3949C20.047 16.2616 19.6205 17.0155 19.0872 17.6565L27.4616 26.0308L26.0565 27.4359ZM12.6924 18.8206C14.4103 18.8206 15.8655 18.2245 17.0578 17.0321C18.2501 15.8398 18.8462 14.3847 18.8462 12.6667C18.8462 10.9488 18.2501 9.49365 17.0578 8.30134C15.8655 7.10903 14.4103 6.51287 12.6924 6.51287C10.9744 6.51287 9.51929 7.10903 8.32697 8.30134C7.13469 9.49365 6.53854 10.9488 6.53854 12.6667C6.53854 14.3847 7.13469 15.8398 8.32697 17.0321C9.51929 18.2245 10.9744 18.8206 12.6924 18.8206ZM9.51291 13.6667V11.6668H15.8719V13.6667H9.51291Z" />
      </svg>
    </div>
  );
};
