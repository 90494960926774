// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {addCN} from '@/_core/util/cssHelper';
import {IconProps, getSize} from '../types';

export const IconSchedule: React.FC<IconProps> = ({size = 'md', color = 'fill-grey700'}) => {
  const finSize = getSize(size);
  return (
    <div>
      <svg
        className={addCN(finSize, color, 'transition')}
        width="32"
        height="32"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M6.66669 11.7437H25.3334V8.41035C25.3334 8.30769 25.2906 8.21369 25.205 8.12835C25.1197 8.0428 25.0257 8.00002 24.923 8.00002H7.07702C6.97435 8.00002 6.88035 8.0428 6.79502 8.12835C6.70947 8.21369 6.66669 8.30769 6.66669 8.41035V11.7437ZM7.07702 28.6667C6.40346 28.6667 5.83335 28.4334 5.36669 27.9667C4.90002 27.5 4.66669 26.9299 4.66669 26.2564V8.41035C4.66669 7.7368 4.90002 7.16669 5.36669 6.70002C5.83335 6.23335 6.40346 6.00002 7.07702 6.00002H8.92302V3.17969H10.9744V6.00002H21.077V3.17969H23.077V6.00002H24.923C25.5966 6.00002 26.1667 6.23335 26.6334 6.70002C27.1 7.16669 27.3334 7.7368 27.3334 8.41035V15.695C27.0136 15.5548 26.687 15.4415 26.3537 15.355C26.0204 15.2688 25.6802 15.2009 25.3334 15.1514V13.7437H6.66669V26.2564C6.66669 26.359 6.70947 26.453 6.79502 26.5384C6.88035 26.6239 6.97435 26.6667 7.07702 26.6667H15.7464C15.859 27.0358 15.9954 27.3849 16.1554 27.714C16.3151 28.0431 16.4967 28.3607 16.7 28.6667H7.07702ZM24.2564 30C22.5915 30 21.1752 29.4162 20.0077 28.2487C18.8401 27.0811 18.2564 25.6649 18.2564 24C18.2564 22.3351 18.8401 20.9189 20.0077 19.7514C21.1752 18.5838 22.5915 18 24.2564 18C25.9215 18 27.3377 18.5838 28.505 19.7514C29.6726 20.9189 30.2564 22.3351 30.2564 24C30.2564 25.6649 29.6726 27.0811 28.505 28.2487C27.3377 29.4162 25.9215 30 24.2564 30ZM26.477 27.0514L27.3077 26.2207L24.8464 23.759V20.077H23.6667V24.241L26.477 27.0514Z" />
      </svg>
    </div>
  );
};
