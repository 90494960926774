// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {addCN} from '@/_core/util/cssHelper';
import {IconProps, getSize} from '../types';

export const IconEmail: React.FC<IconProps> = ({size = 'md', color = 'fill-grey700'}) => {
  const finSize = getSize(size);
  return (
    <div>
      <svg
        className={addCN(finSize, color, 'transition')}
        width="32"
        height="32"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M5.74376 25.9999C5.07025 25.9999 4.50016 25.7666 4.0335 25.2999C3.56683 24.8333 3.3335 24.2632 3.3335 23.5897V8.41027C3.3335 7.73676 3.56683 7.16667 4.0335 6.7C4.50016 6.23333 5.07025 6 5.74376 6H26.2565C26.93 6 27.5001 6.23333 27.9668 6.7C28.4334 7.16667 28.6668 7.73676 28.6668 8.41027V23.5897C28.6668 24.2632 28.4334 24.8333 27.9668 25.2999C27.5001 25.7666 26.93 25.9999 26.2565 25.9999H5.74376ZM16.0001 16.7435L5.33346 9.923V23.5897C5.33346 23.7093 5.37193 23.8076 5.44886 23.8846C5.5258 23.9615 5.6241 24 5.74376 24H26.2565C26.3762 24 26.4745 23.9615 26.5514 23.8846C26.6283 23.8076 26.6668 23.7093 26.6668 23.5897V9.923L16.0001 16.7435ZM16.0001 14.6666L26.4617 7.99997H5.5386L16.0001 14.6666ZM5.33346 9.923V7.99997V23.5897C5.33346 23.7093 5.37193 23.8076 5.44886 23.8846C5.5258 23.9615 5.6241 24 5.74376 24H5.33346V9.923Z" />
      </svg>
    </div>
  );
};
