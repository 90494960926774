// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {addCN} from '@/_core/util/cssHelper';
import {IconProps, getSize} from '../types';

export const IconAttentionSeeker: React.FC<IconProps> = ({size = 'md', color = 'fill-grey700'}) => {
  const finSize = getSize(size);
  return (
    <div>
      <svg
        className={addCN(finSize, color, 'transition')}
        width="32"
        height="32"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M7.07677 27.3334C6.40326 27.3334 5.83317 27.1 5.3665 26.6334C4.89984 26.1667 4.6665 25.5966 4.6665 24.9231V20.0001H6.66647V24.9231C6.66647 25.0257 6.7092 25.1197 6.79467 25.2052C6.88016 25.2907 6.97419 25.3334 7.07677 25.3334H11.9998V27.3334H7.07677ZM19.9998 27.3334V25.3334H24.9228C25.0254 25.3334 25.1194 25.2907 25.2049 25.2052C25.2904 25.1197 25.3331 25.0257 25.3331 24.9231V20.0001H27.3331V24.9231C27.3331 25.5966 27.0998 26.1667 26.6331 26.6334C26.1664 27.1 25.5963 27.3334 24.9228 27.3334H19.9998ZM4.6665 12V7.07702C4.6665 6.40351 4.89984 5.83341 5.3665 5.36675C5.83317 4.90008 6.40326 4.66675 7.07677 4.66675H11.9998V6.66672H7.07677C6.97419 6.66672 6.88016 6.70945 6.79467 6.79492C6.7092 6.8804 6.66647 6.97444 6.66647 7.07702V12H4.6665ZM25.3331 12V7.07702C25.3331 6.97444 25.2904 6.8804 25.2049 6.79492C25.1194 6.70945 25.0254 6.66672 24.9228 6.66672H19.9998V4.66675H24.9228C25.5963 4.66675 26.1664 4.90008 26.6331 5.36675C27.0998 5.83341 27.3331 6.40351 27.3331 7.07702V12H25.3331ZM15.9998 22C14.3349 22 12.9186 21.4163 11.7511 20.2488C10.5836 19.0812 9.99984 17.665 9.99984 16C9.99984 14.3351 10.5836 12.9189 11.7511 11.7513C12.9186 10.5838 14.3349 10.0001 15.9998 10.0001C17.6647 10.0001 19.081 10.5838 20.2485 11.7513C21.416 12.9189 21.9998 14.3351 21.9998 16C21.9998 17.665 21.416 19.0812 20.2485 20.2488C19.081 21.4163 17.6647 22 15.9998 22ZM15.9998 20.0001C17.1109 20.0001 18.0554 19.6112 18.8331 18.8334C19.6109 18.0556 19.9998 17.1112 19.9998 16C19.9998 14.8889 19.6109 13.9445 18.8331 13.1667C18.0554 12.3889 17.1109 12 15.9998 12C14.8887 12 13.9442 12.3889 13.1665 13.1667C12.3887 13.9445 11.9998 14.8889 11.9998 16C11.9998 17.1112 12.3887 18.0556 13.1665 18.8334C13.9442 19.6112 14.8887 20.0001 15.9998 20.0001Z" />
      </svg>
    </div>
  );
};
