// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {addCN} from '@/_core/util/cssHelper';
import {IconProps, getSize} from '../types';

export const IconSmileySad: React.FC<IconProps> = ({size = 'md', color = 'fill-grey700'}) => {
  const finSize = getSize(size);
  return (
    <div>
      <svg
        className={addCN(finSize, color, 'transition')}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_2996_3057)">
          <mask
            id="mask0_2996_3057"
            style={{maskType: 'luminance'}}
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="24"
            height="24"
          >
            <path d="M24 0H0V24H24V0Z" fill="white" />
          </mask>
          <g mask="url(#mask0_2996_3057)">
            <path
              d="M20.3803 12C20.3803 16.6302 16.6275 20.3803 12 20.3803C7.37248 20.3803 3.61968 16.6302 3.61968 12C3.61968 7.37248 7.37248 3.61968 12 3.61968C16.6275 3.61968 20.3803 7.37248 20.3803 12Z"
              fill="#F0B856"
            />
            <path
              d="M20.3803 12C20.3803 16.6302 16.6275 20.3803 12 20.3803C9.36544 20.3803 7.01373 19.1652 5.47645 17.2613C6.91421 18.4241 8.74477 19.1181 10.7377 19.1181C15.3652 19.1181 19.118 15.3678 19.118 10.7378C19.118 8.74477 18.4241 6.91421 17.2613 5.47645C19.1626 7.01373 20.3803 9.36544 20.3803 12Z"
              fill="#F4C673"
            />
            <path
              d="M19.7152 13.6707C19.7481 14.0124 19.4976 14.3162 19.1558 14.3489C18.814 14.3818 18.5103 14.1313 18.4775 13.7895C18.4446 13.4477 18.9752 12.4686 18.9752 12.4686C18.9752 12.4686 19.6824 13.3289 19.7152 13.6707Z"
              fill="#F4C673"
            />
            <path
              d="M19.4585 13.9633C19.4075 14.0565 19.3182 14.1057 19.2577 14.0738C19.1995 14.0419 19.1932 13.9395 19.2441 13.844C19.2952 13.7508 19.3844 13.7017 19.445 13.7335C19.5032 13.7654 19.5117 13.8677 19.4585 13.9633Z"
              fill="#F9F9F9"
            />
            <path
              d="M4.1571 13.6281C4.161 13.9715 4.44241 14.2467 4.78576 14.2428C5.12903 14.2389 5.40425 13.9574 5.40036 13.614C5.39646 13.2706 4.76446 12.3538 4.76446 12.3538C4.76446 12.3538 4.15325 13.2847 4.1571 13.6281Z"
              fill="#F4C673"
            />
            <path
              d="M4.44359 13.8917C4.5043 13.9789 4.59822 14.0182 4.65502 13.9801C4.70948 13.9421 4.70485 13.8397 4.64396 13.7501C4.58329 13.6629 4.48932 13.6236 4.43257 13.6617C4.37805 13.6997 4.38045 13.8024 4.44359 13.8917Z"
              fill="#F9F9F9"
            />
            <path
              d="M8.27963 16.1517C9.15484 14.637 10.5409 14.6207 12.0661 14.3027C13.5976 13.9814 14.9837 15.1877 15.8589 15.8101C16.13 15.9571 15.8733 16.3557 15.3713 16.5384C13.17 17.1958 10.9686 15.9645 8.76723 16.7905C8.26523 17.0181 8.00855 16.6728 8.27963 16.1517Z"
              fill="#0D212E"
            />
            <path
              d="M14.2112 16.7292C14.1645 16.422 13.9704 15.973 13.7592 15.7666C13.4935 15.5084 12.9967 15.3841 12.5806 15.5399C12.4216 15.6016 12.249 15.6453 12.0658 15.6664C11.8785 15.6866 11.6947 15.6804 11.5182 15.6479C10.8608 15.5174 10.2111 15.7515 10.064 16.3176C10.0466 16.382 10.0434 16.4491 10.0352 16.5152C11.4271 16.4005 12.8192 16.8022 14.2112 16.7292Z"
              fill="#F4C673"
            />
          </g>
        </g>
        <defs>
          <clipPath id="clip0_2996_3057">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};
