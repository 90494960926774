// @ts-nocheck
/* eslint-disable */

import React, {useEffect} from 'react';
import {logger} from '@modules/Core/util/Logger';
import {CustomSurveyAnswer} from '@modules/CustomSurveys/components/answer/CustomSurveyAnswer';
import {CustomSurveyNotAvailablePage} from '@modules/CustomSurveys/components/answer/CustomSurveyNotAvailablePage';
import {_CustomSurvey} from '@modules/CustomSurveys/types/customSurvey.model';
import {useLanguage} from '@modules/Translations/hooks/language';

interface _Props {
  survey: _CustomSurvey;
  profileId?: string;
  setAnswers: (answers: Record<string, any>) => void;
  answers: Record<string, any>;
  skipSurvey: () => void;
}

export const AnswerCustomSurveyItem: React.FC<_Props> = ({profileId, survey, setAnswers, answers, skipSurvey}) => {
  logger.debug('AnswerCustomSurvey', {survey, profileId});
  const {currentLanguage} = useLanguage();

  useEffect(() => {
    if (!survey) {
      skipSurvey?.();
    }
  }, [skipSurvey, survey]);

  if (!survey) {
    return <CustomSurveyNotAvailablePage profileId={profileId} />;
  }

  return (
    <div className="flex flex-col h-full">
      <CustomSurveyAnswer
        profileId={profileId}
        survey={survey}
        language={currentLanguage}
        setAnswers={setAnswers}
        answers={answers}
      />
    </div>
  );
};
