// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {addCN} from '@/_core/util/cssHelper';
import {IconProps, getSize} from '../types';

export const IconSmileyUpset: React.FC<IconProps> = ({size = 'md', color = 'fill-grey700'}) => {
  const finSize = getSize(size);
  return (
    <div>
      <svg
        className={addCN(finSize, color, 'transition')}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_2996_3251)">
          <g mask="url(#mask0_2996_3251)">
            <path
              d="M20.3803 12C20.3803 16.6302 16.6275 20.3803 12 20.3803C7.37248 20.3803 3.61968 16.6302 3.61968 12C3.61968 7.37248 7.37248 3.61967 12 3.61967C16.6275 3.61967 20.3803 7.37248 20.3803 12Z"
              fill="#F0B856"
            />
            <path
              d="M20.3803 12C20.3803 16.6302 16.6275 20.3803 12 20.3803C9.36544 20.3803 7.01373 19.1652 5.47645 17.2613C6.91421 18.4241 8.74477 19.1181 10.7377 19.1181C15.3652 19.1181 19.118 15.3678 19.118 10.7378C19.118 8.74476 18.4241 6.9142 17.2613 5.47644C19.1626 7.01372 20.3803 9.36543 20.3803 12Z"
              fill="#F4C673"
            />
            <path
              d="M16.4116 10.8676C16.4116 11.6081 15.806 12.2137 15.0623 12.2137C14.3186 12.2137 13.713 11.6081 13.713 10.8676C13.713 10.1207 14.3186 9.51826 15.0623 9.51826C15.806 9.51826 16.4116 10.1207 16.4116 10.8676Z"
              fill="#0D212E"
            />
            <path
              d="M15.8137 10.5581C15.7252 10.6622 15.5141 10.6305 15.3482 10.4883C15.1828 10.3429 15.1212 10.1401 15.2129 10.0364C15.3051 9.92973 15.513 9.96089 15.6815 10.1068C15.8474 10.249 15.9059 10.4513 15.8137 10.5581Z"
              fill="#263344"
            />
            <path
              d="M10.287 10.8676C10.287 11.6081 9.68138 12.2137 8.9377 12.2137C8.19399 12.2137 7.58836 11.6081 7.58836 10.8676C7.58836 10.1207 8.19399 9.51826 8.9377 9.51826C9.68138 9.51826 10.287 10.1207 10.287 10.8676Z"
              fill="#0D212E"
            />
            <path
              d="M9.68916 10.5581C9.60052 10.6622 9.38951 10.6305 9.22362 10.4883C9.05821 10.3429 8.99661 10.1401 9.08829 10.0364C9.18045 9.92973 9.38836 9.96089 9.5569 10.1068C9.72279 10.249 9.78132 10.4513 9.68916 10.5581Z"
              fill="#263344"
            />
            <path
              d="M15.2375 16.9648C14.8195 17.2172 13.6939 16.0948 12.1429 16.081C10.6146 16.0674 9.4663 17.2172 9.04829 16.9648C8.54752 16.761 9.404 14.3179 12.1429 14.3029C14.8818 14.3178 15.7382 16.7612 15.2375 16.9648Z"
              fill="#0D212E"
            />
          </g>
        </g>
        <defs>
          <clipPath id="clip0_2996_3251">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};
