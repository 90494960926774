// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {addCN} from '@/_core/util/cssHelper';
import {IconProps, getSize} from '../types';

export const IconSmileyExcited: React.FC<IconProps> = ({size = 'md', color = 'fill-grey700'}) => {
  const finSize = getSize(size);
  return (
    <div>
      <svg
        className={addCN(finSize, color, 'transition')}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_8263_3332)">
          <g mask="url(#mask0_8263_3332)">
            <path
              d="M20.3803 11.9995C20.3803 16.6296 16.6275 20.3798 11.9999 20.3798C7.37243 20.3798 3.61963 16.6296 3.61963 11.9995C3.61963 7.37194 7.37243 3.61914 11.9999 3.61914C16.6275 3.61914 20.3803 7.37194 20.3803 11.9995Z"
              fill="#F0B856"
            />
            <path
              d="M20.3804 12.0001C20.3804 16.6303 16.6276 20.3804 12.0001 20.3804C9.36555 20.3804 7.01384 19.1653 5.47656 17.2614C6.91432 18.4242 8.74488 19.1182 10.7378 19.1182C15.3654 19.1182 19.1182 15.368 19.1182 10.7379C19.1182 8.74488 18.4242 6.91432 17.2614 5.47656C19.1627 7.01384 20.3804 9.36555 20.3804 12.0001Z"
              fill="#F4C673"
            />
            <path
              d="M15.7666 14.2891C16.1143 14.5277 15.1586 17.1486 11.9999 17.1354C8.84111 17.1487 7.88543 14.5278 8.23308 14.2891C8.47986 14.2891 9.80786 14.2899 11.9999 14.2899C14.2719 14.2899 15.2479 14.2891 15.7666 14.2891Z"
              fill="#0D212E"
            />
            <path
              d="M7.00271 9.84624C7.00514 9.84352 7.01403 9.7968 7.06399 9.67312C7.46619 8.70793 8.52312 8.22595 9.52571 8.49645C10.0787 8.64451 10.4551 8.9551 10.7885 9.39197C10.9777 9.6399 10.93 9.99433 10.682 10.1835C10.4341 10.3728 10.0797 10.3251 9.89051 10.0771C9.73599 10.0771 9.80671 10.077 9.59746 10.077C8.75071 10.077 8.05199 10.0209 7.42639 10.0209C7.37816 10.1379 7.24418 10.1936 7.12722 10.1454C7.01019 10.0972 6.95448 9.9632 7.00271 9.84624Z"
              fill="#0D212E"
            />
            <path
              d="M16.5737 10.0208C16.0441 10.0208 15.8756 10.0127 15.287 10.0249C14.3317 10.042 14.2955 10.042 14.1657 10.042C14.0268 10.3212 13.6883 10.379 13.4091 10.2401C13.1298 10.1012 13.016 9.76219 13.1549 9.48293C13.4162 8.95752 14.3369 8.28062 15.3518 8.44504C16.2445 8.59432 16.8363 9.2331 16.9974 9.84616C17.0456 9.96312 16.9899 10.0971 16.8729 10.1453C16.7559 10.1935 16.6219 10.1378 16.5737 10.0208Z"
              fill="#0D212E"
            />
          </g>
        </g>
        <defs>
          <clipPath id="clip0_8263_3332">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};
