// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {addCN} from '@/_core/util/cssHelper';
import {IconProps, getSize} from '../types';

export const IconTrust: React.FC<IconProps> = ({size = 'md', color = 'fill-grey700'}) => {
  const finSize = getSize(size);
  return (
    <div>
      <svg
        className={addCN(finSize, color, 'transition')}
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path d="M10.95 15.2034L16.2538 9.89959L15.1846 8.83039L10.95 13.065L8.83075 10.9458L7.76155 12.015L10.95 15.2034ZM12 21.4803C9.83716 20.8906 8.04646 19.6175 6.62787 17.6611C5.20929 15.7047 4.5 13.5175 4.5 11.0996V5.34576L12 2.53809L19.5 5.34576V11.0996C19.5 13.5175 18.7907 15.7047 17.3721 17.6611C15.9535 19.6175 14.1628 20.8906 12 21.4803ZM12 19.8996C13.7333 19.3496 15.1666 18.2496 16.3 16.5996C17.4333 14.9496 18 13.1163 18 11.0996V6.37459L12 4.13421L5.99997 6.37459V11.0996C5.99997 13.1163 6.56664 14.9496 7.69997 16.5996C8.83331 18.2496 10.2666 19.3496 12 19.8996Z" />
      </svg>
    </div>
  );
};
