// @ts-nocheck
/* eslint-disable */

import {sessionConfig} from '@app/_core/util/util';
import {dispatchReactEvent} from '@modules/Core/util/eventsUtil';
import {_NavigateParams} from '@modules/FlowEngine/hooks/navigateToFlow';

export function isLoggedIn(): boolean {
  return !!sessionConfig('authToken') && sessionConfig('userType') === 'user';
}

export async function onLogout(params?: _NavigateParams): Promise<void> {
  dispatchReactEvent('logout', params);
}
