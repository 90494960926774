// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {addCN} from '@/_core/util/cssHelper';
import {IconProps, getSize} from '../types';

export const IconOrganisation: React.FC<IconProps> = ({size = 'md', color = 'fill-grey700'}) => {
  const finSize = getSize(size);
  return (
    <div>
      <svg
        className={addCN(finSize, color, 'transition')}
        width="14"
        height="12"
        viewBox="0 0 14 12"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
      >
        <path d="M0.578125 11.7174V0.0507812H6.91143V2.71745H13.4242V11.7174H0.578125ZM1.57809 10.7174H5.91144V9.05076H1.57809V10.7174ZM1.57809 8.05078H5.91144V6.3841H1.57809V8.05078ZM1.57809 5.38411H5.91144V3.71743H1.57809V5.38411ZM1.57809 2.71745H5.91144V1.05076H1.57809V2.71745ZM6.91143 10.7174H12.4243V3.71743H6.91143V10.7174ZM8.37297 6.3841V5.38411H10.8345V6.3841H8.37297ZM8.37297 9.05076V8.05078H10.8345V9.05076H8.37297Z" />
      </svg>
    </div>
  );
};
