// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {addCN} from '@/_core/util/cssHelper';
import {IconProps, getSize} from '../types';

export const IconDiamond: React.FC<IconProps> = ({size = 'md', color = 'fill-grey700'}) => {
  const finSize = getSize(size);
  return (
    <div>
      <svg
        className={addCN(finSize, color, 'transition')}
        width="32"
        height="32"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M15.9998 27.4742L3.06396 11.9486L6.87163 4.33325H25.128L28.9357 11.9486L15.9998 27.4742ZM12.2947 10.9999H19.705L17.3716 6.33319H14.628L12.2947 10.9999ZM14.9999 23.1564V12.9999H6.56133L14.9999 23.1564ZM16.9998 23.1564L25.4383 12.9999H16.9998V23.1564ZM21.928 10.9999H26.205L23.8716 6.33319H19.5947L21.928 10.9999ZM5.79466 10.9999H10.0716L12.405 6.33319H8.12803L5.79466 10.9999Z" />
      </svg>
    </div>
  );
};
