// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {addCN} from '@/_core/util/cssHelper';
import {IconProps, getSize} from '../types';

export const IconTag: React.FC<IconProps> = ({size = 'md', color = 'fill-grey700'}) => {
  const finSize = getSize(size);

  return (
    <div>
      <svg
        className={addCN(finSize, color, 'transition')}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="ic/md/tag">
          <mask id="mask0_5055_14802" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
            <rect id="Bounding box" width="24" height="24" fill="#D9D9D9" />
          </mask>
          <g mask="url(#mask0_5055_14802)">
            <path
              id="price"
              d="M20.9576 14.2019L14.2019 20.9576C14.0202 21.1384 13.8158 21.274 13.5887 21.3644C13.3616 21.4548 13.1351 21.5 12.9091 21.5C12.6832 21.5 12.4572 21.4548 12.2312 21.3644C12.0053 21.274 11.8019 21.1384 11.6212 20.9576L3.02695 12.373C2.85645 12.2089 2.726 12.0164 2.6356 11.7954C2.54521 11.5744 2.50002 11.3419 2.50002 11.0981V4.3173C2.50002 3.81755 2.67663 3.38973 3.02985 3.03385C3.38305 2.67795 3.81221 2.5 4.31732 2.5H11.0981C11.3395 2.5 11.5735 2.54896 11.7998 2.64688C12.0262 2.74479 12.2224 2.87661 12.3884 3.04232L20.9576 11.6269C21.1409 11.8086 21.2746 12.013 21.3586 12.2401C21.4426 12.4672 21.4846 12.6949 21.4846 12.9233C21.4846 13.1516 21.4426 13.3761 21.3586 13.5968C21.2746 13.8175 21.1409 14.0192 20.9576 14.2019ZM13.1327 19.9038L19.8884 13.1481C19.9526 13.084 19.9846 13.0087 19.9846 12.9221C19.9846 12.8356 19.9526 12.7602 19.8884 12.6961L11.2077 4.0096H4.31732C4.22757 4.0096 4.15225 4.03844 4.09135 4.09613C4.03045 4.15383 4 4.22755 4 4.3173V11.0827C4 11.1211 4.00641 11.1596 4.01922 11.1981C4.03206 11.2365 4.0545 11.2718 4.08655 11.3038L12.6807 19.9038C12.7449 19.9679 12.8202 20 12.9067 20C12.9933 20 13.0686 19.9679 13.1327 19.9038ZM6.5249 7.7788C6.87368 7.7788 7.16986 7.65728 7.41345 7.41423C7.65703 7.17118 7.77882 6.87604 7.77882 6.52883C7.77882 6.17894 7.6573 5.88153 7.41425 5.6366C7.1712 5.39168 6.87606 5.26923 6.52885 5.26923C6.17896 5.26923 5.88156 5.3913 5.63662 5.63545C5.39171 5.8796 5.26925 6.17608 5.26925 6.52488C5.26925 6.87366 5.39132 7.16984 5.63547 7.41343C5.87962 7.65701 6.1761 7.7788 6.5249 7.7788Z"
            />
          </g>
        </g>
      </svg>
    </div>
  );
};
