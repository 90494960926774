// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {addCN} from '@/_core/util/cssHelper';
import {IconProps, getSize} from '../types';

export const IconMotives: React.FC<IconProps> = ({size = 'md', color = 'fill-grey700'}) => {
  const finSize = getSize(size);
  return (
    <div>
      <svg
        className={addCN(finSize, color, 'transition')}
        width="32"
        height="32"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M17.2897 3.40015C20.3112 3.69457 22.8836 4.89851 25.0071 7.01198C27.1305 9.12545 28.3393 11.6941 28.6333 14.7181H19.5872C19.3872 14.1745 19.0902 13.701 18.6961 13.2975C18.3021 12.8941 17.8333 12.5973 17.2897 12.407V3.40015ZM19.2897 5.90265V11.2053C19.5854 11.4138 19.8615 11.6442 20.1179 11.8963C20.3743 12.1484 20.6025 12.4224 20.8025 12.7181H26.1308C25.6316 11.0856 24.7748 9.6621 23.5603 8.44755C22.3457 7.23301 20.9222 6.38471 19.2897 5.90265ZM14.7769 3.40015V12.4009C14.0111 12.6756 13.3966 13.1445 12.9333 13.8077C12.4701 14.4709 12.2385 15.2026 12.2385 16.0026C12.2385 16.8027 12.4722 17.5223 12.9398 18.1616C13.4073 18.801 14.0197 19.258 14.7769 19.5327V28.6001C11.5257 28.2753 8.81114 26.9125 6.63337 24.5116C4.45559 22.1108 3.3667 19.2731 3.3667 15.9986C3.3667 12.724 4.45559 9.8826 6.63337 7.47425C8.81114 5.06589 11.5257 3.70786 14.7769 3.40015ZM12.777 5.90265C10.5667 6.56076 8.77909 7.82401 7.41413 9.69241C6.04915 11.5608 5.36667 13.6634 5.36667 16.0001C5.36667 18.3369 6.04915 20.4394 7.41413 22.3078C8.77909 24.1762 10.5667 25.4531 12.777 26.1386V20.7436C12.0009 20.1761 11.3838 19.4826 10.9257 18.6629C10.4676 17.8433 10.2385 16.9535 10.2385 15.9937C10.2385 15.0339 10.4655 14.142 10.9193 13.3181C11.3731 12.4942 11.9924 11.7984 12.777 11.2309V5.90265ZM19.5872 17.2565H28.6333C28.3375 20.2772 27.1282 22.8491 25.0053 24.972C22.8823 27.095 20.3105 28.3043 17.2897 28.6001V19.5306C17.8393 19.3403 18.3096 19.0497 18.7006 18.6585C19.0917 18.2675 19.3872 17.8001 19.5872 17.2565ZM20.8025 19.2565C20.5991 19.5437 20.37 19.8133 20.1153 20.0654C19.8606 20.3176 19.5854 20.5436 19.2897 20.7436V26.0976C20.9307 25.607 22.3564 24.7544 23.5667 23.5398C24.7769 22.3253 25.6316 20.8975 26.1308 19.2565H20.8025Z" />
      </svg>
    </div>
  );
};
