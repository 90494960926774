// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {addCN} from '@/_core/util/cssHelper';
import {IconProps, getSize} from '../types';

export const IconPhoto: React.FC<IconProps> = ({size = 'md', color = 'fill-grey700'}) => {
  const finSize = getSize(size);
  return (
    <div>
      <svg
        className={addCN(finSize, color, 'transition')}
        width="32"
        height="32"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M7.07677 27.3334C6.40326 27.3334 5.83317 27.1 5.3665 26.6334C4.89984 26.1667 4.6665 25.5966 4.6665 24.9231V7.07702C4.6665 6.40351 4.89984 5.83341 5.3665 5.36675C5.83317 4.90008 6.40326 4.66675 7.07677 4.66675H24.9228C25.5963 4.66675 26.1664 4.90008 26.6331 5.36675C27.0998 5.83341 27.3331 6.40351 27.3331 7.07702V24.9231C27.3331 25.5966 27.0998 26.1667 26.6331 26.6334C26.1664 27.1 25.5963 27.3334 24.9228 27.3334H7.07677ZM7.07677 25.3334H24.9228C25.0254 25.3334 25.1194 25.2907 25.2049 25.2052C25.2904 25.1197 25.3331 25.0257 25.3331 24.9231V7.07702C25.3331 6.97444 25.2904 6.8804 25.2049 6.79492C25.1194 6.70945 25.0254 6.66672 24.9228 6.66672H7.07677C6.97419 6.66672 6.88016 6.70945 6.79467 6.79492C6.7092 6.8804 6.66647 6.97444 6.66647 7.07702V24.9231C6.66647 25.0257 6.7092 25.1197 6.79467 25.2052C6.88016 25.2907 6.97419 25.3334 7.07677 25.3334ZM8.99987 22.3334H23.1023L18.7177 16.4873L14.9742 21.359L12.3075 17.9488L8.99987 22.3334Z" />
      </svg>
    </div>
  );
};
