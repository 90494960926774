// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {addCN} from '@/_core/util/cssHelper';
import {IconProps, getSize} from '../types';

export const IconCommunication: React.FC<IconProps> = ({size = 'md', color = 'fill-grey700'}) => {
  const finSize = getSize(size);
  return (
    <div>
      <svg
        className={addCN(finSize, color, 'transition')}
        width="32"
        height="32"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M8.3335 18.3332H18.3334V16.3333H8.3335V18.3332ZM8.3335 14.3332H23.6668V12.3333H8.3335V14.3332ZM8.3335 10.3332H23.6668V8.33325H8.3335V10.3332ZM3.3335 28.0511V5.74352C3.3335 5.07001 3.56683 4.49992 4.0335 4.03325C4.50016 3.56659 5.07025 3.33325 5.74376 3.33325H26.2565C26.93 3.33325 27.5001 3.56659 27.9668 4.03325C28.4334 4.49992 28.6668 5.07001 28.6668 5.74352V20.9229C28.6668 21.5964 28.4334 22.1665 27.9668 22.6332C27.5001 23.0999 26.93 23.3332 26.2565 23.3332H8.05143L3.3335 28.0511ZM7.20016 21.3332H26.2565C26.3591 21.3332 26.4531 21.2905 26.5386 21.205C26.6241 21.1195 26.6668 21.0255 26.6668 20.9229V5.74352C26.6668 5.64094 26.6241 5.54691 26.5386 5.46142C26.4531 5.37595 26.3591 5.33322 26.2565 5.33322H5.74376C5.64119 5.33322 5.54715 5.37595 5.46166 5.46142C5.3762 5.54691 5.33346 5.64094 5.33346 5.74352V23.1794L7.20016 21.3332Z" />
      </svg>
    </div>
  );
};
