// @ts-nocheck
/* eslint-disable */

// Ignoring ts file
// why? cause eslint is fuckin stupid.
// keep getting state[flowName] could be null, even after adding checks before
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {logger} from '@modules/Core/util/Logger';
import {_AppState} from '@modules/State/types/appState.model';
import {_Flow} from '../types/flow.model';
import {getStepByIndex} from '../util/helpers/util';

export type _FlowState = Record<string, _Flow | undefined>;

const flowsSlice = createSlice({
  name: 'flow',
  initialState: {} satisfies _FlowState,
  reducers: {
    startFlow(state: _FlowState, action) {
      const flowName = action?.payload?.name;

      if (!flowName) {
        logger.error('[FlowSlice] Flow name is missing');
        return;
      }

      const fromLen = state[flowName]?.steps?.create?.flowData?.steps?.selectSoftFacts?.selectedSoftFacts?.length > 0;
      const toLen =
        action.payload?.flow?.steps?.create?.flowData?.steps?.selectSoftFacts?.selectedSoftFacts?.length > 0;

      logger.info(`[FlowSlice] State change: Initializing flow ${flowName}`);

      try {
        if (fromLen && !toLen) {
          logger.info(`[FlowSlice] State change: Soft facts are NOT empty, not updating flow ${flowName}`);
        }
      } catch (error) {
        logger.error('[FlowSlice] Error checking soft facts', {
          error,
          flowName,
          state: state[flowName],
        });
      }

      state[flowName] = action.payload.flow;

      state[flowName].name = flowName;

      state[flowName].finished = false;

      state[flowName].data = state[flowName].data || {
        actions: {},
        input: {},
        steps: {},
      };

      if (action.payload.stepName && action.payload.stepName !== 'start') {
        state[flowName].currentStep = state[flowName]?.steps?.[action.payload.stepName];
      } else {
        state[flowName].currentStep = getStepByIndex(state[flowName].steps, 0);
      }
    },
    updateCurrentStepData(state: _FlowState, action) {
      const flowName = action?.payload?.name;

      if (!flowName) {
        logger.error('[FlowSlice] Flow name is missing');
        return;
      }

      logger.info(`[FlowSlice] State change: Updating step data for (1) flow ${flowName}`);

      if (!state[flowName]) {
        throw new Error('[FlowSlice] Flow not found in the redux state!');
      }

      if (state[flowName].finished) {
        logger.info('[FlowSlice] Flow already finished, not updating input.');
        return;
      }

      if (!state[flowName].currentStep) {
        logger.error('[FlowSlice] Current step is missing');
        return;
      }

      state[flowName].currentStep.data = action.payload?.data;

      state[flowName].steps[state[flowName].currentStep.stepName] = state[flowName].currentStep;
    },
    updateStepIndex(state: _FlowState, action) {
      const flowName = action?.payload?.name;

      if (!flowName) {
        logger.error('[FlowSlice] Flow name is missing');
        return;
      }

      if (!state[flowName]) {
        throw new Error('[FlowSlice] Flow not found in the redux state!');
      }

      const newStep = getStepByIndex(state[flowName].steps, action.payload.index);

      if (!newStep) {
        logger.error(`[FlowSlice] Step not found for index ${action.payload.index}`);
        return;
      }

      logger.info(
        `[FlowSlice] State change: Updating step index for flow ${flowName}, new step: ${newStep.stepName}, old step: ${state[flowName].currentStep.stepName}`
      );

      state[flowName].currentStep = newStep;
    },
    // updateFlowInput(state: _FlowState, action) {
    //   const flowName = action?.payload?.name;

    //   if (!flowName) {
    //     logger.error('[FlowSlice] Flow name is missing');
    //     return;
    //   }

    //   logger.info(`[FlowSlice] Updating flow input for flow ${flowName}.l`);

    //   if (!state[flowName]) {
    //     throw new Error('[FlowSlice] Flow not found in the redux state!');
    //   }

    //   if (state[flowName].finished) {
    //     logger.info('[FlowSlice] Flow already finished, not updating input.');
    //     return;
    //   }

    //   state[flowName].input = action.payload;
    // },
    finishFlow(state: _FlowState, action) {
      const flowName = action?.payload?.name;

      if (!flowName) {
        logger.error('[FlowSlice] Flow name is missing');
        return;
      }

      logger.info(`[FlowSlice] State change: Finishing flow ${flowName}`);

      if (!state[flowName]) {
        throw new Error('[FlowSlice] Flow not found in the redux state!');
      }

      // state[flowName] = {
      // };

      state[flowName].finished = true;

      logger.info(`[FlowSlice] Finished flow ${flowName}.`);

      // void refreshReduxState();
    },
    saveFlow(state, action) {
      logger.info("[FlowSlice] This action is currently not implemented, it's meant for future development.");
    },
    resetFlow(state: _FlowState, action) {
      const flowName = action?.payload?.name;

      if (!flowName) {
        logger.error('[FlowSlice] Flow name is missing');
        return;
      }

      logger.info(`[FlowSlice] State change: Resetting flow ${flowName}`);

      state[flowName] = {};

      logger.info(`[FlowSlice] Reset flow ${flowName}.`, {
        flowName,
        flow: state[flowName],
      });
    },
    updateStepFlowData(state: _FlowState, action) {
      const flowName = action?.payload?.name;

      if (!flowName) {
        logger.error('[FlowSlice] Flow name is missing');
        return;
      }

      if (!state[flowName]) {
        throw new Error('[FlowSlice] Flow not found in the redux state!');
      }

      if (state[flowName].finished) {
        logger.info('[FlowSlice] Flow already finished, not updating Step Flow Data.');
        return;
      }

      // TODO @Stefan sometimes currentStep is undefined still, had to add this check for now
      if (!state[flowName].currentStep) {
        logger.info('[FlowSlice] No step found, not updating Step Flow Data.');
        return;
      }

      logger.info(`[FlowSlice] State change: Updating step flow data for (2) ${flowName}`);

      const payloadAlreadySet =
        state[flowName].currentStep?.flowData &&
        Object.keys((action?.payload?.data as Record<any, any>) || {}).every(
          key => state[flowName].currentStep.flowData[key] === action.payload?.data[key]
        );

      if (!payloadAlreadySet) {
        state[flowName].currentStep.flowData = {
          ...state[flowName].currentStep?.flowData,
          ...action.payload?.data,
        };
        state[flowName].steps[state[flowName].currentStep.stepName] = state[flowName].currentStep;
      }

      // Update step data from payload
      if (action?.payload?.data?.steps) {
        logger.info(`[FlowSlice] Updating step data for flow (3) ${flowName}`);
        for (const [stepName, stepData] of Object.entries(action?.payload?.data?.steps)) {
          state[flowName].steps[stepName] = {
            ...state[flowName].steps[stepName],
            ...stepData,
          };
        }
        state[flowName].input = {
          ...state[flowName].input,
          loadStateDone: true,
        };
      }
    },
  },
  extraReducers: builder => {
    builder.addCase(updateFlowAction.fulfilled, (state: _FlowState, action) => {
      const {name: flowName, actionName, data, status} = action?.meta?.arg as unknown as Record<string, any>;

      if (!state[flowName]) {
        logger.error(`[FlowSlice] Flow not found: ${flowName}`);
        return;
      }

      if (!state[flowName].actions[actionName]) {
        logger.error(`[FlowSlice] Action not found: ${actionName}`);
        return;
      }

      state[flowName].data.actions[actionName] = data;

      state[flowName].actions[actionName].data = data;
      state[flowName].actions[actionName].status = status;
    });
  },
});

export const updateFlowAction = createAsyncThunk(
  'flow/updateFlowAction',
  async (action: Record<string, any>, {dispatch, getState}) => {
    const state = getState() as _AppState;
    const flowName = (action as any)?.name;

    if (!flowName) {
      logger.error('[FlowSlice] Flow name is missing');
      return;
    }

    if (!state.flow[flowName]) {
      throw new Error('[FlowSlice] Flow not found in the redux state!');
    }

    if (state.flow[flowName].finished) {
      logger.info('[FlowSlice] Flow already finished, not updating actions.');
    }
  }
);
export const {startFlow, updateStepIndex, updateCurrentStepData, saveFlow, resetFlow, finishFlow, updateStepFlowData} =
  flowsSlice.actions;
export default flowsSlice;
