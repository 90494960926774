// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {addCN} from '@/_core/util/cssHelper';
import {getSize, IconProps} from '../types';

export const IconHide: React.FC<IconProps> = ({size = 'md', color = 'fill-grey700'}) => {
  const finSize = getSize(size);
  return (
    <div>
      <svg
        className={addCN(finSize, color, 'transition')}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <mask
          id="mask0_16_1293"
          style={{maskType: 'alpha'}}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="24"
          height="24"
        >
          <rect width="24" height="24" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_16_1293)">
          <path d="M15.773 12.9731L14.65 11.85C14.8 11.0218 14.5638 10.2773 13.9413 9.61639C13.3189 8.95549 12.5551 8.70004 11.65 8.85004L10.5269 7.72699C10.7526 7.62571 10.984 7.54975 11.2212 7.49912C11.4583 7.44847 11.7179 7.42314 12 7.42314C13.1346 7.42314 14.0977 7.81898 14.8894 8.61064C15.6811 9.40231 16.0769 10.3654 16.0769 11.5C16.0769 11.7821 16.0516 12.0449 16.0009 12.2885C15.9503 12.5321 15.8743 12.7603 15.773 12.9731ZM18.9538 16.0846L17.85 15.05C18.4833 14.5667 19.0458 14.0375 19.5375 13.4625C20.0292 12.8875 20.45 12.2334 20.8 11.5C19.9667 9.81671 18.7708 8.47921 17.2125 7.48754C15.6542 6.49588 13.9167 6.00004 12 6.00004C11.5167 6.00004 11.0417 6.03338 10.575 6.10004C10.1083 6.16671 9.65 6.26671 9.2 6.40004L8.03465 5.23469C8.6667 4.98341 9.31221 4.79815 9.97117 4.67892C10.6301 4.55969 11.3064 4.50007 12 4.50007C14.3436 4.50007 16.457 5.14622 18.3404 6.43852C20.2237 7.73083 21.5974 9.41801 22.4615 11.5C22.091 12.3936 21.6125 13.2276 21.0259 14.0019C20.4394 14.7763 19.7487 15.4705 18.9538 16.0846ZM19.7615 21.8692L15.7154 17.8538C15.2026 18.0436 14.6343 18.1987 14.0106 18.3192C13.3869 18.4398 12.7167 18.5 12 18.5C9.65001 18.5 7.53657 17.8539 5.65965 16.5616C3.78273 15.2693 2.40901 13.5821 1.5385 11.5C1.90773 10.6167 2.38466 9.79267 2.96927 9.02792C3.55389 8.26318 4.19748 7.60005 4.90005 7.03852L2.1308 4.23852L3.18465 3.18469L20.8154 20.8154L19.7615 21.8692ZM5.95387 8.09232C5.42567 8.51283 4.91189 9.01829 4.41252 9.60869C3.91316 10.1991 3.50898 10.8295 3.2 11.5C4.03333 13.1834 5.22916 14.5209 6.7875 15.5125C8.34583 16.5042 10.0833 17 12 17C12.4551 17 12.9083 16.9616 13.3596 16.8846C13.8109 16.8077 14.1936 16.7282 14.5077 16.6462L13.2423 15.35C13.0718 15.4192 12.8744 15.4744 12.65 15.5154C12.4256 15.5564 12.209 15.5769 12 15.5769C10.8654 15.5769 9.90227 15.1811 9.1106 14.3894C8.31893 13.5978 7.9231 12.6346 7.9231 11.5C7.9231 11.2975 7.94361 11.0856 7.98465 10.8645C8.02568 10.6433 8.08081 10.4411 8.15002 10.2577L5.95387 8.09232Z" />
        </g>
      </svg>
    </div>
  );
};
