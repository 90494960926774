// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {Section} from '@modules/Core/components/layout/Section';

interface Answer {
  title: {
    de: string;
  };
  type: string;
  value: string | number | null;
}

interface CustomSurvey {
  title: {
    de: string;
  };
  description: {
    de: string;
  };
  answers: Answer[];
}

interface Candidate {
  fullName: string;
  email: string;
  link: string;
  isFavorite: boolean;
  isTop: boolean;
  dimensions: any[];
  customSurvey: CustomSurvey;
}

interface Props {
  candidates: Candidate[];
  emptyStateComponent?: React.ReactNode;
  title?: string;
  description?: string;
}

export const CandidateAnswersTableItem: React.FC<Props> = ({candidates, emptyStateComponent, title, description}) => {
  if (!candidates?.length) {
    return emptyStateComponent || <div>No candidates to display</div>;
  }

  // Check if any candidate has a custom survey
  const hasCustomSurvey = candidates.some(candidate => candidate.customSurvey);
  if (!hasCustomSurvey) {
    return emptyStateComponent;
  }

  // Get questions from the first candidate with a survey
  const firstCandidateWithSurvey = candidates.find(candidate => candidate.customSurvey);
  const questions = firstCandidateWithSurvey?.customSurvey.answers || [];

  return (
    <Section title={title} description={description} gap={5}>
      <div className="overflow-x-auto">
        <table className="w-full border-collapse">
          <thead>
            <tr className="bg-grey100">
              <th className="p-3 text-left border-b border-grey200">Question</th>
              {candidates.map(candidate => (
                <th key={candidate.email} className="p-3 text-left border-b border-grey200">
                  {candidate.fullName}
                  {candidate.isFavorite && <span className="ml-2 text-success">★</span>}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {questions.map((question, index) => (
              <tr key={index} className="border-b border-grey200 hover:bg-grey50">
                <td className="p-3">{question.title.de}</td>
                {candidates.map(candidate => {
                  const answer = candidate.customSurvey?.answers[index];
                  return (
                    <td key={candidate.email} className="p-3">
                      {answer?.type === 'rating' ? (
                        <div className="flex items-center">
                          {answer.value !== null ? (
                            <span className="px-2 py-1 bg-grey100 rounded">{answer.value}/5</span>
                          ) : (
                            <span className="text-grey400">Not answered</span>
                          )}
                        </div>
                      ) : (
                        <span>{answer?.value || <span className="text-grey400">Not answered</span>}</span>
                      )}
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Section>
  );
};

export default CandidateAnswersTableItem;
