// @ts-nocheck
/* eslint-disable */

import React, {ElementType, useEffect, useState} from 'react';
import {Divider} from '@modules/Core/components/base/Divider';
import {IconEasy, IconEnergySource, IconHide, IconShow} from '@modules/Core/components/base/Icons/Icons';
import {Typography} from '@modules/Core/components/base/Typography';
import {Section} from '@modules/Core/components/layout/Section';
import {ProfilePersonalityResultDevMode} from '@modules/Dev/components/ProfilePersonalityResultDevMode';
import {trans} from '@modules/Translations/util/i18n';

interface _ProfileServiceGraphItemProps {
  /**
   * Looks like mmhll or smth like that
   * m - medium, h - high, l - low
   * Ordered O->C->E->A->N
   */
  data: string;
}

export const ProfilePersonalityTraitsDetailsItem: React.FC<_ProfileServiceGraphItemProps> = ({data}) => {
  // Graph data
  const [score, setScore] = useState<string | null>(null);
  const [isDevMode, setIsDevMode] = useState<boolean>(false);

  useEffect(() => {
    setScore(data);
  }, [data]);

  // Split score string into individual segments
  const [openness, conscientiousness, extraversion, agreeableness, emotionalStability] = score?.split('') ?? [];

  // Map segments to their full values
  const getLevel = (segment: string) => {
    switch (segment) {
      case 'l':
        return 'low';
      case 'm':
        return 'medium';
      case 'h':
        return 'high';
      default:
        return '';
    }
  };

  const personalityLevels = {
    openness: getLevel(openness),
    conscientiousness: getLevel(conscientiousness),
    extraversion: getLevel(extraversion),
    agreeableness: getLevel(agreeableness),
    emotionalStability: getLevel(emotionalStability),
  };

  const text = [
    trans(`profile_result.kauai.openMindedness.result.${personalityLevels.openness}.text`),
    trans(`profile_result.kauai.conscientiousness.result.${personalityLevels.conscientiousness}.text`),
    trans(`profile_result.kauai.extraversion.result.${personalityLevels.extraversion}.text`),
    trans(`profile_result.kauai.agreeableness.result.${personalityLevels.agreeableness}.text`),
    trans(`profile_result.kauai.emotionalStability.result.${personalityLevels.emotionalStability}.text`),
  ];

  const interpretation = [
    trans(`profile_result.kauai.openMindedness.result.${personalityLevels.openness}.interpretation`),
    trans(`profile_result.kauai.conscientiousness.result.${personalityLevels.conscientiousness}.interpretation`),
    trans(`profile_result.kauai.extraversion.result.${personalityLevels.extraversion}.interpretation`),
    trans(`profile_result.kauai.agreeableness.result.${personalityLevels.agreeableness}.interpretation`),
    trans(`profile_result.kauai.emotionalStability.result.${personalityLevels.emotionalStability}.interpretation`),
  ];

  const recommendations = [
    trans(`profile_result.kauai.openMindedness.result.${personalityLevels.openness}.recommendation`),
    trans(`profile_result.kauai.conscientiousness.result.${personalityLevels.conscientiousness}.recommendation`),
    trans(`profile_result.kauai.extraversion.result.${personalityLevels.extraversion}.recommendation`),
    trans(`profile_result.kauai.agreeableness.result.${personalityLevels.agreeableness}.recommendation`),
    trans(`profile_result.kauai.emotionalStability.result.${personalityLevels.emotionalStability}.recommendation`),
  ];

  const effectDescriptions = [
    trans(`profile_result.kauai.openMindedness.result.${personalityLevels.openness}.effect.description`),
    trans(`profile_result.kauai.conscientiousness.result.${personalityLevels.conscientiousness}.effect.description`),
    trans(`profile_result.kauai.extraversion.result.${personalityLevels.extraversion}.effect.description`),
    trans(`profile_result.kauai.agreeableness.result.${personalityLevels.agreeableness}.effect.description`),
    trans(`profile_result.kauai.emotionalStability.result.${personalityLevels.emotionalStability}.effect.description`),
  ];

  const effectRecommendations = [
    trans(`profile_result.kauai.openMindedness.result.${personalityLevels.openness}.effect.recommendation`),
    trans(`profile_result.kauai.conscientiousness.result.${personalityLevels.conscientiousness}.effect.recommendation`),
    trans(`profile_result.kauai.extraversion.result.${personalityLevels.extraversion}.effect.recommendation`),
    trans(`profile_result.kauai.agreeableness.result.${personalityLevels.agreeableness}.effect.recommendation`),
    trans(
      `profile_result.kauai.emotionalStability.result.${personalityLevels.emotionalStability}.effect.recommendation`
    ),
  ];

  const energySources = [
    trans(`profile_result.kauai.openMindedness.result.${personalityLevels.openness}.energy.sources`),
    trans(`profile_result.kauai.conscientiousness.result.${personalityLevels.conscientiousness}.energy.sources`),
    trans(`profile_result.kauai.extraversion.result.${personalityLevels.extraversion}.energy.sources`),
    trans(`profile_result.kauai.agreeableness.result.${personalityLevels.agreeableness}.energy.sources`),
    trans(`profile_result.kauai.emotionalStability.result.${personalityLevels.emotionalStability}.energy.sources`),
  ];

  const energyThieves = [
    trans(`profile_result.kauai.openMindedness.result.${personalityLevels.openness}.energy.thieves`),
    trans(`profile_result.kauai.conscientiousness.result.${personalityLevels.conscientiousness}.energy.thieves`),
    trans(`profile_result.kauai.extraversion.result.${personalityLevels.extraversion}.energy.thieves`),
    trans(`profile_result.kauai.agreeableness.result.${personalityLevels.agreeableness}.energy.thieves`),
    trans(`profile_result.kauai.emotionalStability.result.${personalityLevels.emotionalStability}.energy.thieves`),
  ];

  const potentialEasy = [
    trans(`profile_result.kauai.openMindedness.result.${personalityLevels.openness}.potential.easy`),
    trans(`profile_result.kauai.conscientiousness.result.${personalityLevels.conscientiousness}.potential.easy`),
    trans(`profile_result.kauai.extraversion.result.${personalityLevels.extraversion}.potential.easy`),
    trans(`profile_result.kauai.agreeableness.result.${personalityLevels.agreeableness}.potential.easy`),
    trans(`profile_result.kauai.emotionalStability.result.${personalityLevels.emotionalStability}.potential.easy`),
  ];

  const potentialHard = [
    trans(`profile_result.kauai.openMindedness.result.${personalityLevels.openness}.potential.hard`),
    trans(`profile_result.kauai.conscientiousness.result.${personalityLevels.conscientiousness}.potential.hard`),
    trans(`profile_result.kauai.extraversion.result.${personalityLevels.extraversion}.potential.hard`),
    trans(`profile_result.kauai.agreeableness.result.${personalityLevels.agreeableness}.potential.hard`),
    trans(`profile_result.kauai.emotionalStability.result.${personalityLevels.emotionalStability}.potential.hard`),
  ];

  return (
    <Section>
      <ProfilePersonalityResultDevMode
        personalityResult={score}
        setPersonalityResult={value => {
          setScore(value);
        }}
        isDevMode={isDevMode}
        setIsDevMode={setIsDevMode}
      />
      <div className="flex flex-row items-center gap-3">
        <img
          src="/assets/img/undraw/personality-analysis-traits.svg"
          alt="personality-analysis-traits"
          className="relative-col-width-4"
        />

        <Section titleVariant="h6" title={trans('profile_result.kauai.summary.title')}>
          <Typography dangerouslySetInnerHTML={{__html: recommendations.join('<br><br>')}} />
        </Section>
      </div>

      <div className="flex flex-row gap-3">
        <InternalSection
          title={trans('profile_result.kauai.headlines.energy_sources')}
          Icon={IconEnergySource}
          description={energySources.join('<br><br>')}
        />

        <InternalSection
          title={trans('profile_result.kauai.headlines.easy')}
          Icon={IconEasy}
          description={potentialEasy.join('<br><br>')}
        />
      </div>

      {/*
      <div className="flex flex-row gap-3">
        <InternalSection
          title="text"
          Icon={IconEnergySource}
          description={text.join('<br><br>')}
        />

        <InternalSection
          title="interpretation"
          Icon={IconEnergySource}
          description={interpretation.join('<br><br>')}
        />
      </div>

      <div className="flex flex-row gap-3">
        <InternalSection
          title="recommendations"
          Icon={IconEnergySource}
          description={recommendations.join('<br><br>')}
        />

        <InternalSection
          title="effectDescriptions"
          Icon={IconEnergySource}
          description={effectDescriptions.join('<br><br>')}
        />
      </div>

      <div className="flex flex-row gap-3">
        <InternalSection
          title="effectRecommendations"
          Icon={IconEnergySource}
          description={effectRecommendations.join('<br><br>')}
        />

        <InternalSection
          title="energySources"
          Icon={IconEnergySource}
          description={energySources.join('<br><br>')}
        />
      </div>

      <div className="flex flex-row gap-3">
        <InternalSection
          title="energyThieves"
          Icon={IconEnergySource}
          description={energyThieves.join('<br><br>')}
        />

        <InternalSection
          title="potentialEasy"
          Icon={IconEasy}
          description={potentialEasy.join('<br><br>')}
        />
      </div>

      <div className="flex flex-row gap-3">
        <InternalSection
          title="potentialHard"
          Icon={IconEasy}
          description={potentialHard.join('<br><br>')}
        />
      </div>
      */}
    </Section>
  );
};

interface _InternalSectionProps {
  title: string;
  description: string;
  Icon: ElementType;
}

export const InternalSection: React.FC<_InternalSectionProps> = ({title, description, Icon}) => (
  <div className="w-1/2">
    <Section
      titleVariant="h6"
      title={
        <div className="flex flex-col gap-2">
          <Icon color="fill-brand500" size="lg" />
          {title}
        </div>
      }
    >
      <Typography dangerouslySetInnerHTML={{__html: description}} />
    </Section>
  </div>
);
