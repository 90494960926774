// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {twMerge} from 'tailwind-merge';
import {Typography} from '@modules/Core/components/base/Typography';

interface _Label {
  value: string;
  size?: 'md';
  color?: 'success' | 'danger';
  icon?: React.ElementType;
  style?: React.CSSProperties;
}

export const LabelBadge: React.FC<_Label> = ({style, value, size = 'md', color = 'brand', icon}) => {
  const finSize = 'h-fit w-fit';

  // success
  let bgColor = 'bg-green300';
  let textColor = 'text-green700';
  let iconFill = 'fill-green700';
  if (color === 'danger') {
    bgColor = 'bg-[#FAD5DE]';
    textColor = 'text-red700';
    iconFill = 'fill-red700';
  }

  if (color === 'grey') {
    bgColor = 'bg-grey500';
    textColor = 'text-grey700';
    iconFill = 'fill-grey700';
  }

  if (color === 'warning') {
    bgColor = 'bg-orange500';
    textColor = 'text-white700';
    iconFill = 'fill-white700';
  }

  const Icon = icon ?? null;
  return (
    <div
      className={twMerge('flex flex-row justify-center items-center', 'py-0 px-1', 'gap-0', bgColor, finSize)}
      style={style}
    >
      <Typography variant="caption" className={twMerge(textColor, 'align-middle')}>
        {value}
      </Typography>
      {Icon && <Icon size="sm" className={iconFill} color={iconFill} />}
    </div>
  );
};
