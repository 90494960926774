// @ts-nocheck
/* eslint-disable */

export type IconSize = 'sm' | 'md' | 'lg';

export interface IconProps {
  size?: IconSize;
  color?: string;
  onClick?: () => void;
}

export const getSize = (size: IconSize | string): string => {
  if (!['sm', 'md', 'lg', 'xl', 'xxl'].includes(size) && !!size) {
    // return custom size
    return size;
  }
  if (size === 'sm') return 'h-2 w-2';
  if (size === 'md') return 'h-3 w-3';
  if (size === 'lg') return 'h-4 w-4';
  if (size === 'xl') return 'h-5 w-5';
  if (size === 'xxl') return 'h-6 w-6';
  return 'h-3 w-3';
};
