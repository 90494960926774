// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {addCN} from '@/_core/util/cssHelper';
import {IconProps, getSize} from '../types';

export const IconLinkedin: React.FC<IconProps> = ({size = 'md', color = 'fill-grey700'}) => {
  const finSize = getSize(size);
  return (
    <div>
      <svg
        className={addCN(finSize, color, 'transition')}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M19.2 3.19995H4.80001C3.91601 3.19995 3.20001 3.91595 3.20001 4.79995V19.2C3.20001 20.084 3.91601 20.7999 4.80001 20.7999H19.2C20.084 20.7999 20.8 20.084 20.8 19.2V4.79995C20.8 3.91595 20.084 3.19995 19.2 3.19995ZM8.76321 17.6H6.40321V10.0064H8.76321V17.6ZM7.55921 8.92075C6.79841 8.92075 6.18321 8.30395 6.18321 7.54475C6.18321 6.78555 6.79921 6.16955 7.55921 6.16955C8.31761 6.16955 8.93441 6.78635 8.93441 7.54475C8.93441 8.30395 8.31761 8.92075 7.55921 8.92075ZM17.6032 17.6H15.2448V13.9072C15.2448 13.0264 15.2288 11.8935 14.0184 11.8935C12.7904 11.8935 12.6016 12.8528 12.6016 13.8432V17.6H10.2432V10.0064H12.5072V11.044H12.5392C12.8544 10.4472 13.624 9.81755 14.772 9.81755C17.1616 9.81755 17.6032 11.3904 17.6032 13.4352V17.6Z" />
      </svg>
    </div>
  );
};
