// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {addCN} from '@/_core/util/cssHelper';
import {IconProps, getSize} from '../types';

export const IconNewChat: React.FC<IconProps> = ({size = 'md', color = 'fill-grey700'}) => {
  const finSize = getSize(size);
  return (
    <div>
      <svg
        className={addCN(finSize, color, 'transition')}
        width="32"
        height="32"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M4.3335 25.8588V6.74349C4.3335 6.08066 4.5695 5.51325 5.0415 5.04125C5.5135 4.56925 6.08091 4.33325 6.74373 4.33325H22.5899C23.2527 4.33325 23.8201 4.56925 24.2921 5.04125C24.7641 5.51325 25.0001 6.08066 25.0001 6.74349V13.3948C24.8334 13.3674 24.6668 13.3503 24.5001 13.3435C24.3335 13.3366 24.1668 13.3332 24.0001 13.3332C23.8335 13.3332 23.6668 13.3366 23.5001 13.3435C23.3335 13.3503 23.1668 13.3674 23.0002 13.3948V6.74349C23.0002 6.62382 22.9617 6.52552 22.8848 6.44859C22.8078 6.37165 22.7095 6.33319 22.5899 6.33319H6.74373C6.62406 6.33319 6.52576 6.37165 6.44883 6.44859C6.3719 6.52552 6.33343 6.62382 6.33343 6.74349V20.3333H16.0617C16.0343 20.4999 16.0172 20.6666 16.0104 20.8332C16.0036 20.9999 16.0001 21.1666 16.0001 21.3332C16.0001 21.4999 16.0036 21.6666 16.0104 21.8332C16.0172 21.9999 16.0343 22.1665 16.0617 22.3332H7.8591L4.3335 25.8588ZM9.4104 11.6665H19.9232V9.66659H9.4104V11.6665ZM9.4104 16.9999H15.9232V14.9999H9.4104V16.9999ZM23.0002 26.3332V22.3332H19.0002V20.3333H23.0002V16.3333H25.0001V20.3333H29.0001V22.3332H25.0001V26.3332H23.0002Z" />
      </svg>
    </div>
  );
};
