// @ts-nocheck
/* eslint-disable */

import {ReactNode, useCallback} from 'react';
// import {useSnackbar} from '../Platform/Snackbar';
// import {useProfile} from '../Redux/Profile';
// import {useTeams} from '../Redux/Teams';
import {logger} from '@modules/Core/util/Logger';
// import {useTeam} from '../Redux/Team';
import {useFlowData} from '@modules/FlowEngine/hooks/flowData';
import {useFlowPageLayout} from '@modules/FlowEngine/hooks/flowPageLayout';
import {useInitCurrentStep} from '@modules/FlowEngine/hooks/initCurrentStep';
import {useInitFlowManager} from '@modules/FlowEngine/hooks/initFlowManager';
import {useInitStepComponent} from '@modules/FlowEngine/hooks/initStepComponent';
import {_FlowInput, _FlowSchema} from '../types/core.model';
import {_FlowStep} from '../types/step.model';

export function useFlow(
  flowName: string,
  flowSchema: _FlowSchema,
  flowInput: _FlowInput | undefined,
  stepName: string | undefined,
  onCurrentStepChanged: ((newStep: _FlowStep | undefined) => void) | null,
  onFinish: (() => void) | null,
  instanceKey: string
): {
  options: any;
  currentStep: _FlowStep | undefined;
  onNextClicked: () => void;
  onSaveClicked: () => void;
  onFinishClicked: () => void;
  onPreviousClicked: () => void;
  stepComponent: ReactNode;
} {
  const flowManager = useInitFlowManager(flowName, flowSchema, flowInput, stepName, onFinish, instanceKey);

  const onFinishClicked = useCallback(() => {
    flowManager?.onFinishClicked();
    onFinish?.();
  }, [flowManager]);

  const [currentStep, options] = useFlowData(flowName, instanceKey, onFinishClicked);

  const {ready} = useInitCurrentStep(flowManager, currentStep);

  const stepComponent = useInitStepComponent(flowInput, flowManager, currentStep, ready, onCurrentStepChanged);

  useFlowPageLayout(flowName, currentStep, stepComponent, instanceKey);

  const onNextClicked = useCallback(() => {
    if (!currentStep) {
      logger.error('[useFlow] currentStep is missing');
      return;
    }
    void flowManager?.onNextClicked(currentStep);
  }, [flowManager, currentStep]);

  const onPreviousClicked = useCallback(() => {
    if (!currentStep) {
      logger.error('[useFlow] currentStep is missing');
      return;
    }
    flowManager?.onPreviousClicked(currentStep);
  }, [flowManager, currentStep]);

  const onSaveClicked = useCallback(() => {
    flowManager?.onSaveClicked();
  }, [flowManager]);

  return {
    options,
    currentStep,
    onNextClicked,
    onSaveClicked,
    onFinishClicked,
    onPreviousClicked,
    stepComponent,
  };
}
