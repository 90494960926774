// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {addCN} from '@/_core/util/cssHelper';
import {IconProps, getSize} from '../types';

export const IconTune: React.FC<IconProps> = ({size = 'md', color = 'fill-grey700'}) => {
  const finSize = getSize(size);
  return (
    <div>
      <svg
        className={addCN(finSize, color, 'transition')}
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.49996 13.8333V10.1666H8.49996V11.5H13.8333V12.5H8.49996V13.8333H7.49996ZM2.16663 12.5V11.5H5.83329V12.5H2.16663ZM4.83329 9.83329V8.49996H2.16663V7.49996H4.83329V6.16663H5.83329V9.83329H4.83329ZM7.49996 8.49996V7.49996H13.8333V8.49996H7.49996ZM10.1666 5.83329V2.16663H11.1666V3.49996H13.8333V4.49996H11.1666V5.83329H10.1666ZM2.16663 4.49996V3.49996H8.49996V4.49996H2.16663Z"
          fill="#6D7681"
        />
      </svg>
    </div>
  );
};
