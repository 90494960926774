// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {addCN} from '@/_core/util/cssHelper';
import {IconProps, getSize} from '../types';

export const IconLogo: React.FC<IconProps> = ({size = 'md', color = 'fill-grey700'}) => {
  const finSize = getSize(size);
  return (
    <div>
      <svg
        className={addCN(finSize, color, 'transition')}
        width="32"
        height="32"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M17.1154 7.22943C17.0633 7.06241 17.0781 6.88163 17.1568 6.7254C17.2356 6.56915 17.372 6.44973 17.5373 6.3924L21.0566 5.05073C21.1727 5.00691 21.2976 4.99115 21.4209 5.00473C21.5443 5.01831 21.6627 5.06084 21.7665 5.12888L27 8.59397C27.1427 8.68679 27.2471 8.82816 27.2939 8.99197L28.8883 14.7558L28.9799 15.117C28.998 15.1881 29.0039 15.2617 28.9975 15.3347L28.9899 15.4216C28.983 15.4986 28.9625 15.5737 28.9294 15.6435L24.3576 25.2838C24.2889 25.4138 24.1841 25.5211 24.0558 25.5927C23.9275 25.6644 23.7812 25.6973 23.6345 25.6875C23.4879 25.6777 23.3473 25.6257 23.2296 25.5376C23.1119 25.4496 23.0222 25.3293 22.9714 25.1913L17.1154 7.22943Z" />
        <path d="M22.8521 25.9907C22.9981 25.9797 23.132 25.8496 23.067 25.6823L19.9353 17.6195L16.031 7.83891C16.0114 7.7936 15.9789 7.75501 15.9377 7.7279C15.8965 7.7008 15.8482 7.68634 15.7989 7.68632C15.7495 7.6863 15.7012 7.7007 15.66 7.72778C15.6311 7.74672 15.6066 7.77125 15.5877 7.79977C15.5796 7.81206 15.5725 7.82508 15.5666 7.8387L13.8471 11.8947C13.8184 11.9575 13.8033 12.0257 13.8029 12.0947C13.8024 12.1638 13.8166 12.2322 13.8446 12.2953C13.8777 12.3669 13.9118 12.4349 13.9291 12.4631L19.2179 20.622L13.3442 12.7914C13.3076 12.7493 13.2687 12.7092 13.2278 12.6712C13.1194 12.573 12.9791 12.5174 12.833 12.5146L8.13724 12.3025C8.08876 12.295 8.03912 12.3033 7.9957 12.3262C7.95228 12.3491 7.91739 12.3853 7.89614 12.4296C7.87491 12.4738 7.86849 12.5238 7.87781 12.572C7.88714 12.6202 7.91172 12.6641 7.94793 12.6973L15.9641 19.9931L22.6084 25.9342C22.6849 26.0026 22.7751 26.0143 22.8521 25.9907Z" />
        <path d="M6.96598 13.5135C6.8241 13.4004 6.64723 13.3404 6.46587 13.3439C6.28452 13.3473 6.11006 13.414 5.97257 13.5324L3.22613 15.9802C3.13734 16.0575 3.07087 16.1573 3.0337 16.269C2.99651 16.3808 2.98996 16.5005 3.01475 16.6157L4.29423 22.4723C4.3318 22.6379 4.42833 22.7841 4.56581 22.8836L9.58065 26.5786L9.89814 26.8031C9.96137 26.848 10.031 26.8832 10.1047 26.9073L10.1932 26.936C10.2716 26.9614 10.3534 26.9743 10.4358 26.9745L21.7608 27C22.4471 27.0016 22.7564 26.2046 22.2326 25.7838L6.96598 13.5135Z" />
      </svg>
    </div>
  );
};
