// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {addCN} from '@/_core/util/cssHelper';
import {IconProps, getSize} from '../types';

export const IconCheckbox: React.FC<IconProps> = ({size = 'md', color = 'fill-grey700'}) => {
  const finSize = getSize(size);
  return (
    <div>
      <svg
        className={addCN(finSize, color, 'transition')}
        width="25"
        height="24"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
      >
        <mask id="mask0_7135_4158" maskUnits="userSpaceOnUse" x="0" y="0" width="32" height="32">
          <rect width="32" height="32" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_7135_4158)">
          <path d="M7.0783 27.3333C6.41341 27.3333 5.84541 27.0978 5.3743 26.627C4.90341 26.1558 4.66797 25.5878 4.66797 24.923V7.07696C4.66797 6.41207 4.90341 5.84407 5.3743 5.37296C5.84541 4.90207 6.41341 4.66663 7.0783 4.66663H24.9243C25.1279 4.66663 25.3202 4.69185 25.5013 4.74229C25.6824 4.79274 25.8551 4.8684 26.0193 4.96929L24.232 6.75629C24.2064 6.73074 24.1744 6.7094 24.136 6.69229C24.0975 6.67518 24.0526 6.66663 24.0013 6.66663H7.0783C6.95852 6.66663 6.86019 6.70507 6.7833 6.78196C6.70641 6.85885 6.66797 6.95718 6.66797 7.07696V24.923C6.66797 25.0427 6.70641 25.1411 6.7833 25.218C6.86019 25.2948 6.95852 25.3333 7.0783 25.3333H24.9243C25.0441 25.3333 25.1424 25.2948 25.2193 25.218C25.2962 25.1411 25.3346 25.0427 25.3346 24.923V15.9923L27.3346 13.9923V24.923C27.3346 25.5878 27.0992 26.1558 26.6283 26.627C26.1572 27.0978 25.5892 27.3333 24.9243 27.3333H7.0783ZM15.368 22.1923L8.5653 15.3896L9.97064 13.9846L15.3806 19.395L27.3576 7.41796L28.7706 8.78963L15.368 22.1923Z" />
        </g>
      </svg>
    </div>
  );
};
