// @ts-nocheck
/* eslint-disable */

import {useEffect, useState} from 'react';
import {navigateToFlowUsingWindow, removeSessionConfig, sessionConfig, setSessionConfig} from '@app/_core/util/util';
import {login, logout} from '@modules/Core/services/api/login';
import {submitError} from '@modules/Core/state/errorSlice';
import {addReactEventListener, dispatchReactEvent, removeReactEventListener} from '@modules/Core/util/eventsUtil';
import {logger} from '@modules/Core/util/Logger';
import {FLOWS} from '@modules/FlowEngine/config/flowNames';
import {_NavigateParams} from '@modules/FlowEngine/hooks/navigateToFlow';
import {fetchProfile} from '@modules/Profile/state/profileSlice';
import {dispatchAction} from '@modules/State/util/util';

let logoutInProgress = false;

export function useAuthToken(): {
  authToken: string | null;
  rememberMeState: boolean | null;
  fetchToken: (email: string, password: string, rememberMe?: boolean) => Promise<any>;
  resetToken: () => Promise<any>;
} {
  const [authToken, setAuthToken] = useState<string | null>(sessionConfig('authToken'));
  const [rememberMeState, setRememberMeState] = useState<boolean | null>(sessionConfig('rememberMe') === 'true');

  // add react event handler for logout
  useEffect(() => {
    addReactEventListener('logout', resetToken);
    return () => {
      removeReactEventListener('logout', resetToken);
    };
  }, []);

  const fetchToken = async (email: string, password: string, rememberMe: boolean = false): Promise<any> => {
    if (!email || !password) {
      dispatchAction(
        submitError({
          name: 'login',
          error: 'Email or password is empty',
        })
      );
    }
    logger.info('[AuthTokenSlice] Fetching token.');
    try {
      const response = await login(email, password, rememberMe);
      if (response?.status === 200 && response?.data?.token) {
        logger.info('[AuthTokenSlice] Token fetched.');

        setAuthToken(response?.data.token as string);
        setRememberMeState(rememberMe);

        setSessionConfig('authToken', response?.data.token);
        setSessionConfig('rememberMe', rememberMe.toString());
        setSessionConfig('userType', response?.data.user_type);
      } else {
        await resetToken();
      }
      return response?.data;
    } catch (error) {
      logger.error('[AuthTokenSlice] Error fetching token.', error);

      dispatchAction(submitError({name: 'login', error}));
    }
    return null;
  };

  const resetToken = async (params: _NavigateParams = {}): Promise<any> => {
    if (logoutInProgress) {
      return null;
    }
    logoutInProgress = true;
    logger.info('[AuthTokenSlice] Resetting token.');
    try {
      const response = await logout();
      if (response?.status === 200 && response?.data) {
        logger.info('[AuthTokenSlice] Token Reset.');
        setAuthToken(null);
        setRememberMeState(null);

        removeSessionConfig('authToken');
        removeSessionConfig('rememberMe');

        navigateToFlowUsingWindow(FLOWS.Login, params);
      }
      return response?.data;
    } catch (error) {
      logger.error('[AuthTokenSlice] Error Resetting token.', error);

      dispatchAction(submitError({name: 'logout', error}));
    }
    return null;
  };

  return {authToken, rememberMeState, fetchToken, resetToken};
}
