// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {AvatarUser} from '@modules/Core/components/base/avatar/AvatarUser';
import {Button} from '@modules/Core/components/base/buttons/Button';
import {Card} from '@modules/Core/components/base/cards/Card';
import {IconAdd, IconMore, IconNote, IconTrash} from '@modules/Core/components/base/Icons/Icons';
import {ContextMenu} from '@modules/Core/components/base/inputs/ContextMenu';
import {Typography} from '@modules/Core/components/base/Typography';
import {Section} from '@modules/Core/components/layout/Section';
import {_CustomSurvey} from '@modules/CustomSurveys/types/customSurvey.model';
import {getProfilePic} from '@modules/Profile/util/profileUtil';
import {trans} from '@modules/Translations/util/i18n';

interface SurveyCard {
  id: string;
  title: string;
  description: string;
  questionsCount: number;
  createdAt: string;
  author?: string;
}

const defaultSurveys: SurveyCard[] = [
  {
    id: 'remote-worker-profile',
    title: 'Remote Worker Profile',
    description: 'Learn about the ideal remote candidate for your organization and how they will fit into your team.',
    questionsCount: 24,
    createdAt: '2024-04-01',
    author: 'Urs Merkel',
  },
  {
    id: 'senior-software-developer',
    title: 'Senior Software Developer',
    description: 'Questions for Senior Software Developers and how they handle pressure & get tech stack.',
    questionsCount: 32,
    createdAt: '2024-02-12',
    author: 'Urs Merkel',
  },
  {
    id: 'junior-marketing-intern',
    title: 'Junior Marketing Intern',
    description: 'Questions for Junior Marketing Interns and how they handle questions regarding marketing results.',
    questionsCount: 15,
    createdAt: '2024-02-04',
    author: 'Urs Merkel',
  },
];

interface SelectCustomSurveysProps {
  surveys?: SurveyCard[];
  onSelectSurvey: (surveyId: string) => void;
  onCreateNew: () => void;
}

export const SelectCustomSurveys: React.FC<SelectCustomSurveysProps> = ({
  surveys = [],
  onSelectSurvey,
  onCreateNew,
}) => {
  const allSurveys = [...defaultSurveys, ...surveys];

  return (
    <Section
      title="Get To Know Your Candidates"
      description="Ask them custom questions which are role specific to get better analysis at the end."
      gap={6}
    >
      <Section title="Start With Existing Survey" className="flex-row">
        {allSurveys.map(survey => (
          <Card
            key={survey.id}
            width="relative-col-width-3"
            padding={2}
            onClick={() => onSelectSurvey?.(survey.id)}
            gap={2}
          >
            <div className="flex w-full justify-end">
              <ContextMenu
                triggerIcon={IconMore}
                menuItems={[
                  {
                    label: trans('profile.journal.edit_note'),
                    onChange: () => null,
                    icon: IconNote,
                  },
                  {
                    label: trans('profile.journal.delete_note'),
                    onChange: () => null,
                    icon: IconTrash,
                  },
                ]}
              />
            </div>

            <Typography variant="body" bold>
              {survey.title}
            </Typography>

            <Typography variant="body2" className="h-7">
              {survey.description}
            </Typography>

            {survey.author && <AvatarUser label={survey.author} image={getProfilePic(null)} />}

            <div className="flex justify-between gap-4">
              <Typography variant="body2">{survey.questionsCount} questions</Typography>
              <Typography variant="body2">Created on {new Date(survey.createdAt).toLocaleDateString()}</Typography>
            </div>
          </Card>
        ))}
      </Section>

      <Section title="Start From Scratch" className="flex-row">
        <Card padding={4} gap={3} width="relative-col-width-3" className="items-center" onClick={() => onCreateNew?.()}>
          <IconAdd size="lg" />
          <Typography variant="body">Create New Survey</Typography>
        </Card>
      </Section>
    </Section>
  );
};
