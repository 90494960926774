// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {addCN} from '@/_core/util/cssHelper';
import {IconProps, getSize} from '../types';

export const IconCritic: React.FC<IconProps> = ({size = 'md', color = 'fill-grey700'}) => {
  const finSize = getSize(size);
  return (
    <div>
      <svg
        className={addCN(finSize, color, 'transition')}
        width="32"
        height="32"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M9.53087 20.0256C10.2283 20.0256 10.8578 19.8213 11.4193 19.4128C11.9809 19.0042 12.377 18.4692 12.6078 17.8077L13.0693 16.3641C13.4334 15.2718 13.3381 14.2671 12.7834 13.35C12.2287 12.4329 11.4385 11.9743 10.4129 11.9743H5.68727L6.3206 17.1948C6.43171 17.9983 6.79026 18.6709 7.39624 19.2128C8.00224 19.7547 8.71378 20.0256 9.53087 20.0256ZM22.4693 20.0256C23.2864 20.0256 23.9979 19.7547 24.6039 19.2128C25.2099 18.6709 25.5684 17.9983 25.6795 17.1948L26.3129 11.9743H21.6206C20.5949 11.9743 19.8026 12.4363 19.2436 13.3602C18.6847 14.2842 18.5872 15.2923 18.9513 16.3846L19.3923 17.8077C19.6231 18.4692 20.0193 19.0042 20.5808 19.4128C21.1423 19.8213 21.7718 20.0256 22.4693 20.0256ZM9.53087 22.0256C8.21807 22.0256 7.06722 21.5914 6.07834 20.723C5.08945 19.8546 4.51467 18.7598 4.354 17.4384L3.6668 11.9743H2.43604V9.97437H10.4129C11.2881 9.97437 12.095 10.1983 12.8334 10.6462C13.5719 11.094 14.1616 11.7077 14.6026 12.4872H17.4308C17.8719 11.7077 18.4616 11.094 19.2001 10.6462C19.9385 10.1983 20.7453 9.97437 21.6206 9.97437H29.5641V11.9743H28.3333L27.6461 17.4384C27.4855 18.7598 26.9107 19.8546 25.9218 20.723C24.9329 21.5914 23.7821 22.0256 22.4693 22.0256C21.3308 22.0256 20.3095 21.7008 19.4052 21.0512C18.5009 20.4016 17.8659 19.541 17.5001 18.4692L17.0385 17.0461C16.9856 16.8649 16.9368 16.676 16.8924 16.4794C16.848 16.2829 16.8035 15.9948 16.7591 15.6153H15.2411C15.1966 15.9504 15.1522 16.2183 15.1077 16.4192C15.0633 16.62 15.0146 16.8111 14.9616 16.9923L14.5001 18.4358C14.1428 19.5076 13.5099 20.3739 12.6014 21.0345C11.6928 21.6952 10.6693 22.0256 9.53087 22.0256Z" />
      </svg>
    </div>
  );
};
