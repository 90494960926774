// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {addCN} from '@/_core/util/cssHelper';
import {getSize, IconProps} from '../types';

export const IconCandidateEdit: React.FC<IconProps> = ({size = 'md', color = 'fill-grey700'}) => {
  const finSize = getSize(size);
  return (
    <div>
      <svg
        className={addCN(finSize, color, 'transition')}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <mask
          id="mask0_3244_47772"
          style={{maskType: 'alpha'}}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="24"
          height="24"
        >
          <rect width="24" height="24" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_3244_47772)">
          <path d="M2.71699 20.5283V22H15.7086C15.2754 21.5823 14.9209 21.0836 14.6693 20.5283H2.71699Z" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M19.6416 13.8126V6.41512C19.6416 5.91948 19.4699 5.49996 19.1265 5.15657C18.7831 4.81317 18.3636 4.64147 17.868 4.64147H2.77365C2.27801 4.64147 1.8585 4.81317 1.5151 5.15657C1.1717 5.49996 1 5.91948 1 6.41512V17.5849C1 18.0805 1.1717 18.5 1.5151 18.8434C1.8585 19.1868 2.27801 19.3585 2.77365 19.3585H14.3123C14.2683 19.0978 14.2454 18.8299 14.2454 18.5567C14.2454 17.9359 14.3636 17.3428 14.5788 16.7985C14.18 16.5132 13.7594 16.2697 13.317 16.068C12.3899 15.6453 11.3912 15.434 10.3208 15.434C9.25039 15.434 8.25167 15.6453 7.32466 16.068C6.39765 16.4906 5.56621 17.0968 4.83036 17.8868H2.77365C2.6981 17.8868 2.62893 17.8553 2.56614 17.7924C2.50318 17.7296 2.47171 17.6604 2.47171 17.5849V6.41512C2.47171 6.33957 2.50318 6.2704 2.56614 6.20761C2.62893 6.14465 2.6981 6.11317 2.77365 6.11317H17.868C17.9435 6.11317 18.0127 6.14465 18.0755 6.20761C18.1384 6.2704 18.1699 6.33957 18.1699 6.41512V13.8505C18.4484 13.8 18.7353 13.7737 19.0284 13.7737C19.2362 13.7737 19.4408 13.7869 19.6416 13.8126ZM13.5586 17.8868H7.08305C7.55727 17.5598 8.06828 17.3145 8.61608 17.151C9.16388 16.9875 9.73212 16.9057 10.3208 16.9057C10.9095 16.9057 11.4777 16.9875 12.0255 17.151C12.5733 17.3145 13.0843 17.5598 13.5586 17.8868Z"
          />
          <path d="M2.71699 2V3.47171H17.9246V2H2.71699Z" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.2311 12.6841C11.706 13.2092 11.0693 13.4717 10.3208 13.4717C9.57236 13.4717 8.9356 13.2092 8.41053 12.6841C7.8853 12.1589 7.62268 11.522 7.62268 10.7736C7.62268 10.0251 7.8853 9.38838 8.41053 8.8633C8.9356 8.33807 9.57236 8.07545 10.3208 8.07545C11.0693 8.07545 11.706 8.33807 12.2311 8.8633C12.7563 9.38838 13.0189 10.0251 13.0189 10.7736C13.0189 11.522 12.7563 12.1589 12.2311 12.6841ZM9.45201 11.6426C9.69026 11.8809 9.97986 12 10.3208 12C10.6618 12 10.9514 11.8809 11.1896 11.6426C11.428 11.4042 11.5472 11.1145 11.5472 10.7736C11.5472 10.4328 11.428 10.1432 11.1896 9.90478C10.9514 9.66636 10.6618 9.54716 10.3208 9.54716C9.97986 9.54716 9.69026 9.66636 9.45201 9.90478C9.21359 10.1432 9.09439 10.4328 9.09439 10.7736C9.09439 11.1145 9.21359 11.4042 9.45201 11.6426Z"
          />
          <path d="M16.4529 21.1322V19.277L20.2645 15.483C20.3518 15.3957 20.4461 15.3342 20.5473 15.2987C20.6485 15.2631 20.7497 15.2454 20.8509 15.2454C20.9614 15.2454 21.0681 15.2661 21.1713 15.3075C21.2745 15.3489 21.3683 15.411 21.4527 15.4938L22.1021 16.15C22.1777 16.2373 22.2362 16.3318 22.2776 16.4335C22.319 16.5352 22.3397 16.6369 22.3397 16.7387C22.3397 16.8404 22.3208 16.9439 22.283 17.0492C22.2452 17.1545 22.1849 17.2508 22.1021 17.3381L18.3081 21.1322H16.4529ZM17.29 20.2951H17.957L20.2362 18.0051L19.9135 17.6716L19.5867 17.3422L17.29 19.6281V20.2951ZM19.9135 17.6716L19.5867 17.3422L20.2362 18.0051L19.9135 17.6716Z" />
        </g>
      </svg>
    </div>
  );
};
