// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {addCN} from '@/_core/util/cssHelper';
import {IconProps, getSize} from '../types';

export const IconNote: React.FC<IconProps> = ({size = 'md', color = 'fill-grey700'}) => {
  const finSize = getSize(size);
  return (
    <div>
      <svg
        className={addCN(finSize, color, 'transition')}
        width="32"
        height="32"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M12.2562 11.5896V9.58965H23.7434V11.5896H12.2562ZM12.2562 15.4358V13.4358H23.7434V15.4358H12.2562ZM15.8972 28.6665H7.9998C7.0682 28.6665 6.27975 28.3439 5.63444 27.6986C4.98915 27.0533 4.6665 26.2648 4.6665 25.3332V21.4871H8.6665V3.33325H27.3331V14.6101C26.9912 14.5913 26.6523 14.6123 26.3164 14.673C25.9806 14.7336 25.6528 14.8341 25.3331 14.9742V5.33322H10.6665V21.4871H18.2305L16.2306 23.487H6.66647V25.3332C6.66647 25.711 6.79425 26.0277 7.0498 26.2832C7.30536 26.5388 7.62203 26.6666 7.9998 26.6666H15.8972V28.6665ZM18.8203 28.6665V25.1435L26.0588 17.9384C26.2246 17.7726 26.4041 17.6559 26.5972 17.5884C26.7904 17.5208 26.9836 17.4871 27.1767 17.4871C27.3836 17.4871 27.5857 17.5264 27.7831 17.605C27.9805 17.6837 28.1579 17.8016 28.3151 17.9589L29.5485 19.205C29.6921 19.3708 29.8032 19.5503 29.8818 19.7435C29.9604 19.9366 29.9998 20.1298 29.9998 20.323C29.9998 20.5161 29.9639 20.7127 29.8921 20.9127C29.8203 21.1127 29.7057 21.2956 29.5485 21.4614L22.3434 28.6665H18.8203ZM20.41 27.0768H21.6767L26.0049 22.7281L25.3921 22.0947L24.7716 21.4691L20.41 25.8101V27.0768ZM25.3921 22.0947L24.7716 21.4691L26.0049 22.7281L25.3921 22.0947Z" />
      </svg>
    </div>
  );
};
