// @ts-nocheck
/* eslint-disable */

import React, {useState} from 'react';
import {useSoftFactGroups} from '@modules/ContentEngine/hooks/useSoftFactGroups';
import {Button} from '@modules/Core/components/base/buttons/Button';
import {IconCheckbox} from '@modules/Core/components/base/Icons/Icons';
import {RadioButton} from '@modules/Core/components/base/inputs/RadioButton';
import {Section} from '@modules/Core/components/layout/Section';
import {PROFILE_SERVICES_DESCRIPTIONS} from '@modules/Profile/config/profileServicesLangProps';
import {getServiceIcon} from '@modules/Profile/util/profileUtil';
import {trans} from '@modules/Translations/util/i18n';

interface SelectSoftFactsProps {
  values?: Record<string, any>;
  onChange?: (values: Record<string, any>) => void;
}

export const SelectSoftFacts: React.FC<SelectSoftFactsProps> = ({values, onChange}) => {
  const [selectedSoftFacts, setSelectedSoftFacts] = useState<string[]>(values?.selectedSoftFacts || []);
  const softFactGroups = useSoftFactGroups();

  const toggleFact = (factId: string) => {
    const newValues = selectedSoftFacts.includes(factId)
      ? selectedSoftFacts.filter(id => id !== factId)
      : [...selectedSoftFacts, factId];

    setSelectedSoftFacts(newValues);
    onChange?.({selectedSoftFacts: newValues});
  };

  const toggleAllInGroup = (groupId: string, dimensions: Array<{id: string}>) => {
    const groupFactIds = dimensions.map(dim => `${groupId}:${dim.id}`);
    const areAllSelected = groupFactIds.every(id => selectedSoftFacts.includes(id));

    const newValues = areAllSelected
      ? selectedSoftFacts.filter(id => !groupFactIds.includes(id))
      : Array.from(new Set([...selectedSoftFacts, ...groupFactIds]));

    setSelectedSoftFacts(newValues);
    onChange?.({selectedSoftFacts: newValues});
  };

  return (
    <Section gap={6}>
      {softFactGroups.map(group => (
        <Section
          key={group.id}
          title={group.name}
          description={trans(PROFILE_SERVICES_DESCRIPTIONS[group.id])}
          titleIcon={getServiceIcon(group.id)}
          actions={[
            {
              uuid: `toggle-${group.id}`,
              type: 'secondary_button',
              position: 'end',
              label: group.dimensions.every(dim => selectedSoftFacts.includes(`${group.id}:${dim.id}`))
                ? trans('subscription.reset_selection')
                : trans('base.select_all'),
              onClick: () => toggleAllInGroup(group.id, group.dimensions),
              icon: group.dimensions.every(dim => selectedSoftFacts.includes(`${group.id}:${dim.id}`))
                ? 'checkbox_unselected'
                : 'checkbox',
              iconPosition: 'right',
            },
          ]}
        >
          <div className="flex flex-row flex-wrap gap-2">
            {group.dimensions.map(dimension => (
              <div>
                <RadioButton
                  key={dimension.id}
                  onChange={() => toggleFact(`${group.id}:${dimension.id}`)}
                  label={dimension.name}
                  active={selectedSoftFacts.includes(`${group.id}:${dimension.id}`)}
                  forSurvey
                />
              </div>
            ))}
          </div>
        </Section>
      ))}
    </Section>
  );
};
