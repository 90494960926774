// @ts-nocheck
/* eslint-disable */

import React, {useEffect} from 'react';
import {twMerge} from 'tailwind-merge';
import {navigateTo} from '@app/_core/util/util';
import {setCandidateAnalysisFavoriteAction} from '@modules/CandidateAnalysis/util/candidateAnalysisFlowActions';
import {Avatar} from '@modules/Core/components/base/avatar/Avatar';
import {IconButton} from '@modules/Core/components/base/buttons/IconButton';
import {Card} from '@modules/Core/components/base/cards/Card';
import {IconFavorite} from '@modules/Core/components/base/Icons/Icons';
import {ToolTip} from '@modules/Core/components/base/ToolTip';
import {Typography} from '@modules/Core/components/base/Typography';
import {Section} from '@modules/Core/components/layout/Section';
import {PROFILE_SERVICES_DESCRIPTIONS, PROFILE_SERVICES_TITLES} from '@modules/Profile/config/profileServicesLangProps';
import {getServiceIcon} from '@modules/Profile/util/profileUtil';
import {trans} from '@modules/Translations/util/i18n';

interface Dimension {
  id: string;
  title: string;
  value: number;
  reflection: string;
  range: {
    min: number;
    max: number;
  };
  initials: string;
  name: string;
}

export interface CardItem {
  id: string | number;
  fullName: string;
  email: string;
  content?: React.ReactNode;
  onClick?: () => void;
  link?: string;
  isFavorite?: boolean;
  isTop?: boolean;
  dimensions: Dimension[];
  scores: Record<string, number>;
  candidateAnalysisId: string;
  profileId: string;
}

interface CardListItemProps {
  items: CardItem[];
  emptyStateComponent?: React.ReactNode;
  title?: string;
  description?: string;
  mode?: 'rows' | 'columns';
  wide?: boolean;
}

const DimensionIndicator: React.FC<{
  prefix: string;
  value: number;
}> = ({prefix, value}) => {
  const Icon = getServiceIcon(prefix);
  return (
    <div className="flex flex-col items-center gap-1">
      <div className="w-4 h-4 flex items-center justify-center">
        <ToolTip txt={trans(PROFILE_SERVICES_TITLES[prefix])}>
          <Icon />
        </ToolTip>
      </div>
      <Typography className="text-typography-paragraph text-xs">{value}%</Typography>
    </div>
  );
};

const CardHeader: React.FC<{item: CardItem}> = ({item}) => {
  return (
    <div className="w-full flex justify-between items-center">
      <IconButton
        icon={IconFavorite}
        color="dark"
        active={item.isFavorite}
        onClick={e => {
          e.stopPropagation();
          setCandidateAnalysisFavoriteAction(item.candidateAnalysisId, item.profileId, !item.isFavorite);
        }}
      />
      <Typography className="text-green700 font-bold">{item.scores.overall}%</Typography>
    </div>
  );
};

const CardContent: React.FC<{item: CardItem; wide?: boolean}> = ({item, wide}) => {
  const getInitials = (name: string): string =>
    name
      .split(' ')
      .map(word => word[0])
      .join('')
      .slice(0, 2)
      .toUpperCase();

  const dimensionsToShow = wide
    ? Object.entries(item.scores).filter(([key]) => key !== 'overall')
    : Object.entries(item.scores)
        .filter(([key]) => key !== 'overall')
        .sort(([, a], [, b]) => b - a)
        .slice(0, 5);

  return (
    <>
      <div className={twMerge('flex items-center gap-1', wide && 'mt-auto')}>
        <Avatar size="lg" initials={getInitials(item.fullName)} />
        <div className="flex flex-col">
          <Typography className="text-typography-headings font-bold">{item.fullName}</Typography>
        </div>
      </div>
      <div
        className={twMerge(
          'flex justify-between gap-1',
          wide && 'absolute bottom-4 right-4',
          !wide && dimensionsToShow.length > 2 && 'w-full'
        )}
      >
        {dimensionsToShow.map(([prefix, value]) => (
          <DimensionIndicator key={prefix} prefix={prefix} value={value} />
        ))}
      </div>
    </>
  );
};

export const CardListItem: React.FC<CardListItemProps> = ({
  items = [],
  emptyStateComponent,
  title,
  description,
  mode = 'rows',
  wide = false,
}) => {
  if (!items.length) {
    return emptyStateComponent ?? <div>No items to display</div>;
  }

  return (
    <Section title={title} description={description} mode={mode}>
      {items.map(item => (
        <Card
          key={item.id}
          onClick={() => item.onClick ?? (item.link && navigateTo(item.link))}
          className={twMerge(
            'bg-grey50 rounded-lg border border-grey300',
            'flex flex-col',
            (item.onClick || item.link) && 'cursor-pointer',
            wide ? 'relative p-2' : 'p-[10px] gap-[10px]'
          )}
          width={mode === 'columns' ? (wide ? 'relative-col-width-3' : 'relative-col-width-2') : ''}
        >
          <CardHeader item={item} />
          <CardContent item={item} wide={wide} />
        </Card>
      ))}
    </Section>
  );
};

export default CardListItem;
