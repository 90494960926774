// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {addCN} from '@/_core/util/cssHelper';
import {IconProps, getSize} from '../types';

export const IconProfilePrivacy: React.FC<IconProps> = ({size = 'md', color = 'fill-grey700'}) => {
  const finSize = getSize(size);
  return (
    <div>
      <svg
        className={addCN(finSize, color, 'transition')}
        width="32"
        height="32"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M22.6666 22.6668C23.2222 22.6668 23.6944 22.4723 24.0833 22.0834C24.4722 21.6945 24.6666 21.2223 24.6666 20.6668C24.6666 20.1112 24.4722 19.639 24.0833 19.2501C23.6944 18.8612 23.2222 18.6668 22.6666 18.6668C22.1111 18.6668 21.6389 18.8612 21.25 19.2501C20.8611 19.639 20.6666 20.1112 20.6666 20.6668C20.6666 21.2223 20.8611 21.6945 21.25 22.0834C21.6389 22.4723 22.1111 22.6668 22.6666 22.6668ZM22.6666 26.6668C23.3641 26.6668 23.9995 26.5078 24.573 26.1898C25.1465 25.8719 25.6154 25.4463 25.9795 24.9129C25.482 24.6155 24.9555 24.389 24.4 24.2334C23.8444 24.0779 23.2666 24.0001 22.6666 24.0001C22.0666 24.0001 21.4889 24.0779 20.9333 24.2334C20.3777 24.389 19.8512 24.6155 19.3538 24.9129C19.7179 25.4463 20.1867 25.8719 20.7602 26.1898C21.3337 26.5078 21.9692 26.6668 22.6666 26.6668ZM16 28.6411C13.1162 27.8548 10.7286 26.1573 8.83717 23.5488C6.94572 20.9402 6 18.024 6 14.8001V7.12833L16 3.38477L25.9999 7.12833V14.6822C25.6888 14.5557 25.3623 14.4416 25.0205 14.3399C24.6786 14.2382 24.3384 14.1583 24 14.1001V8.5206L16 5.53343L7.99997 8.5206V14.8001C7.99997 15.9813 8.16663 17.1347 8.49997 18.2604C8.8333 19.386 9.29271 20.4382 9.8782 21.4168C10.4637 22.3954 11.1632 23.2741 11.9769 24.0527C12.7906 24.8313 13.6735 25.4531 14.6257 25.9181L14.6641 25.9053C14.8402 26.3941 15.0684 26.8625 15.3487 27.3103C15.6291 27.7582 15.9479 28.165 16.3051 28.5308C16.2487 28.5479 16.1978 28.5663 16.1525 28.5859C16.1072 28.6056 16.0564 28.624 16 28.6411ZM22.6666 28.6667C21.0017 28.6667 19.5855 28.083 18.4179 26.9155C17.2504 25.7479 16.6667 24.3317 16.6667 22.6668C16.6667 21.0018 17.2504 19.5856 18.4179 18.4181C19.5855 17.2506 21.0017 16.6668 22.6666 16.6668C24.3316 16.6668 25.7478 17.2506 26.9153 18.4181C28.0828 19.5856 28.6666 21.0018 28.6666 22.6668C28.6666 24.3317 28.0828 25.7479 26.9153 26.9155C25.7478 28.083 24.3316 28.6667 22.6666 28.6667Z" />
      </svg>
    </div>
  );
};
