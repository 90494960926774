// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {addCN} from '@/_core/util/cssHelper';
import {IconProps, getSize} from '../types';

export const IconTeamDevelopment: React.FC<IconProps> = ({size = 'md', color = 'fill-grey700'}) => {
  const finSize = getSize(size);
  return (
    <div>
      <svg
        className={addCN(finSize, color, 'transition')}
        width="32"
        height="32"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M4.3335 27.6667V25.4745L6.33343 23.4745V27.6667H4.3335ZM9.66683 27.6667V20.1411L11.6668 18.1412V27.6667H9.66683ZM15.0002 27.6667V18.1412L17.0001 20.1745V27.6667H15.0002ZM20.3335 27.6667V20.1745L22.3334 18.1745V27.6667H20.3335ZM25.6668 27.6667V14.8078L27.6668 12.8079V27.6667H25.6668ZM4.3335 20.2923V17.4745L13.3335 8.4745L18.6668 13.8078L27.6668 4.80786V7.62573L18.6668 16.6257L13.3335 11.2924L4.3335 20.2923Z" />
      </svg>
    </div>
  );
};
