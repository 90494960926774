// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {addCN} from '@/_core/util/cssHelper';
import {IconProps, getSize} from '../types';

export const IconLink: React.FC<IconProps> = ({size = 'md', color = 'fill-grey700'}) => {
  const finSize = getSize(size);
  return (
    <div>
      <svg
        className={addCN(finSize, color, 'transition')}
        width="25"
        height="24"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
      >
        <mask id="mask0_2732_34817" maskUnits="userSpaceOnUse" x="0" y="0" width="32" height="32">
          <rect width="32" height="32" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_2732_34817)">
          <path d="M14.4104 22.0513H9.38471C7.71049 22.0513 6.28337 21.4613 5.10337 20.2813C3.92337 19.1015 3.33337 17.6746 3.33337 16.0006C3.33337 14.3266 3.92337 12.8995 5.10337 11.7193C6.28337 10.5388 7.71049 9.94861 9.38471 9.94861H14.4104V11.9486H9.38471C8.26493 11.9486 7.30982 12.3439 6.51937 13.1346C5.72871 13.9253 5.33337 14.8804 5.33337 15.9999C5.33337 17.1195 5.72871 18.0746 6.51937 18.8653C7.30982 19.6559 8.26493 20.0513 9.38471 20.0513H14.4104V22.0513ZM11 16.9999V14.9999H21V16.9999H11ZM17.5897 22.0513V20.0513H22.6154C23.7352 20.0513 24.6903 19.6559 25.4807 18.8653C26.2714 18.0746 26.6667 17.1195 26.6667 15.9999C26.6667 14.8804 26.2714 13.9253 25.4807 13.1346C24.6903 12.3439 23.7352 11.9486 22.6154 11.9486H17.5897V9.94861H22.6154C24.2896 9.94861 25.7167 10.5386 26.8967 11.7186C28.0767 12.8984 28.6667 14.3253 28.6667 15.9993C28.6667 17.6733 28.0767 19.1004 26.8967 20.2806C25.7167 21.4611 24.2896 22.0513 22.6154 22.0513H17.5897Z" />
        </g>
      </svg>
    </div>
  );
};
