// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {addCN} from '@/_core/util/cssHelper';
import {IconProps, getSize} from '../types';

export const IconTopCandidates: React.FC<IconProps> = ({size = 'md', color = 'fill-grey700'}) => {
  const finSize = getSize(size);
  return (
    <div>
      <svg
        className={addCN(finSize, color, 'transition')}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <mask
          id="mask0_3213_89"
          style={{maskType: 'alpha'}}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="24"
          height="24"
        >
          <rect width="24" height="24" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_3213_89)">
          <path d="M15.935 13.8077C15.2092 13.8077 14.5914 13.5537 14.0818 13.0455C13.5721 12.5373 13.3173 11.9203 13.3173 11.1945C13.3173 10.4687 13.5713 9.851 14.0795 9.3415C14.5877 8.83183 15.2047 8.577 15.9305 8.577C16.6563 8.577 17.2741 8.831 17.7838 9.339C18.2933 9.84717 18.548 10.4642 18.548 11.19C18.548 11.9158 18.294 12.5336 17.786 13.0433C17.2778 13.5529 16.6608 13.8077 15.935 13.8077ZM10.3173 19.423V17.9078C10.3173 17.6239 10.3862 17.3536 10.524 17.0968C10.6618 16.8399 10.8519 16.6359 11.0943 16.4848C11.8186 16.0603 12.583 15.7388 13.3875 15.5203C14.1922 15.3016 15.0406 15.1923 15.9328 15.1923C16.8249 15.1923 17.6733 15.3016 18.4778 15.5203C19.2823 15.7388 20.0468 16.0603 20.7713 16.4848C21.0134 16.6359 21.2034 16.8399 21.3413 17.0968C21.4791 17.3536 21.548 17.6239 21.548 17.9078V19.423H10.3173ZM11.9095 17.7693V17.923H19.9558V17.7693C19.3339 17.4166 18.6836 17.1489 18.0048 16.9663C17.3259 16.7836 16.6353 16.6923 15.9328 16.6923C15.2301 16.6923 14.5393 16.7836 13.8605 16.9663C13.1817 17.1489 12.5313 17.4166 11.9095 17.7693ZM15.9328 12.3077C16.2418 12.3077 16.5048 12.1991 16.722 11.9818C16.9393 11.7644 17.048 11.5013 17.048 11.1923C17.048 10.8833 16.9393 10.6202 16.722 10.403C16.5048 10.1857 16.2418 10.077 15.9328 10.077C15.6238 10.077 15.3606 10.1857 15.1433 10.403C14.9259 10.6202 14.8173 10.8833 14.8173 11.1923C14.8173 11.5013 14.9259 11.7644 15.1433 11.9818C15.3606 12.1991 15.6238 12.3077 15.9328 12.3077ZM3.44226 13.75V12.25H10.9423V13.75H3.44226ZM3.44226 5.75V4.25H14.9423V5.75H3.44226ZM11.398 9.75H3.44226V8.25H12.1348C11.9591 8.46917 11.8116 8.70217 11.6923 8.949C11.5731 9.19583 11.475 9.46283 11.398 9.75Z" />
        </g>
      </svg>
    </div>
  );
};
