// @ts-nocheck
/* eslint-disable */

/**
 * Helper fns for getting data from the 'flow' redux store
 * These fns are used to fetch and prepare date to be used in flowEvaluator
 * @returns {*}
 */
import {logger} from '@modules/Core/util/Logger';
import {appState} from '@modules/State/util/util';
import {_FlowAction} from '../../types/action.model';
import {_FlowEvaluatorData, _FlowInput} from '../../types/core.model';
import {_Flow, _FlowOptions} from '../../types/flow.model';
import {_FlowStep} from '../../types/step.model';

export function getFlowStateByName(flowName: string, instanceKey: string): _Flow | undefined {
  return appState().flow[`${flowName}_${instanceKey}`];
}

export function getFlowSteps(flowName: string, instanceKey: string): Record<string, _FlowStep> {
  const flow = getFlowStateByName(flowName, instanceKey);
  if (!flow) {
    logger.error(`[FlowDataGetters] Flow ${flowName} not found`);
    return {};
  }
  const result: Record<string, any> = {};
  const {steps} = flow;

  for (const stepName of Object.keys(steps || {})) {
    const step = steps[stepName];
    result[stepName] = {...step, ...step.data?.[stepName]};
    delete result[stepName].data;
  }

  return mapSteps(steps);
}

export function getFlowCurrentStepIndex(flowName: string, instanceKey: string): number | undefined {
  const flow = getFlowStateByName(flowName, instanceKey);
  if (!flow) {
    logger.error(`[FlowDataGetters] Flow ${flowName} not found`);
    return undefined;
  }
  return flow.currentStep?.index;
}

export function getFlowInput(flowName: string, instanceKey: string): _FlowInput {
  const flow = getFlowStateByName(flowName, instanceKey);
  if (!flow) {
    logger.error(`[FlowDataGetters] Flow ${flowName} not found`);
    return [];
  }
  return flow.input ?? [];
}

export function getFlowActions(flowName: string, instanceKey: string): Record<string, _FlowAction> {
  const flow = getFlowStateByName(flowName, instanceKey);
  if (!flow) {
    logger.error(`[FlowDataGetters] Flow ${flowName} not found`);
    return {};
  }
  return mapActions(flow.actions ?? {});
}

export function getFlowDataEntries(flowName: string, instanceKey: string): Record<string, any> {
  const flow = getFlowStateByName(flowName, instanceKey);
  if (!flow) {
    logger.error(`[FlowDataGetters] Flow ${flowName} not found`);
    return {};
  }
  return flow.data ?? {};
}

export function getFlowOptions(flowName: string, instanceKey: string): _FlowOptions | undefined {
  const flow = getFlowStateByName(flowName, instanceKey);
  if (!flow) {
    logger.error(`[FlowDataGetters] Flow ${flowName} not found`);
    return undefined;
  }
  return flow.options;
}

export function getFlowCurrentStep(flowName: string, instanceKey: string): _FlowStep | undefined {
  const flow = getFlowStateByName(flowName, instanceKey);
  if (!flow) {
    logger.error(`[FlowDataGetters] Flow ${flowName} not found, instanceKey: ${instanceKey}`);
    return undefined;
  }
  return flow.currentStep;
}

export function getFlowData(flowName: string, instanceKey: string): _FlowEvaluatorData {
  const flow = getFlowStateByName(flowName, instanceKey);
  if (!flow) {
    logger.error(`[FlowDataGetters] Flow ${flowName} not found`);
    return {};
  }
  return {
    steps: getFlowSteps(flowName, instanceKey),
    input: getFlowInput(flowName, instanceKey),
    actions: getFlowActions(flowName, instanceKey),
    data: getFlowDataEntries(flowName, instanceKey),
    options: getFlowOptions(flowName, instanceKey),
    currentStepIndex: getFlowCurrentStepIndex(flowName, instanceKey),
    flowState: getFlowStateByName(flowName, instanceKey),
    currentStep: getFlowCurrentStep(flowName, instanceKey),
  };
}

/**
 * Maps steps from a given steps object, optimizing the structure by merging data properties.
 * @param steps The steps object to map.
 * @returns A new object with mapped steps.
 */
function mapSteps(steps: Record<string, _FlowStep>): Record<string, _FlowStep> {
  const result: Record<string, any> = {};

  for (const stepName of Object.keys(steps || {})) {
    const entry = steps[stepName];
    result[stepName] = {
      ...entry,
      ...entry.data?.[stepName],
    };
    delete result[stepName].data;
  }

  return result;
}

/**
 * Maps actions from a given actions object, optimizing the structure by merging data properties.
 * @param actions The actions object to map.
 * @returns A new object with mapped actions.
 */
function mapActions(actions: Record<string, _FlowAction>): Record<string, _FlowAction> {
  const result: Record<string, any> = {};

  for (const actionName of Object.keys(actions || {})) {
    const entry = actions[actionName];
    result[actionName] = {
      ...entry,
      ...(entry.data ?? {}),
    };
    delete result[actionName].data;
  }

  return result;
}
