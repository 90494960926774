// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {addCN} from '@/_core/util/cssHelper';
import {IconProps, getSize} from '../types';

export const IconFavoriteCandidates: React.FC<IconProps> = ({size = 'md', color = 'fill-grey700'}) => {
  const finSize = getSize(size);
  return (
    <div>
      <svg
        className={addCN(finSize, color, 'transition')}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <mask
          id="mask0_3213_92"
          style={{maskType: 'alpha'}}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="24"
          height="24"
        >
          <rect width="24" height="24" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_3213_92)">
          <path d="M6.09627 20.452L2.45202 16.8078L6.09627 13.1635L7.14027 14.2078L5.29027 16.0578H18.7098L16.8598 14.2078L17.9038 13.1635L21.548 16.8078L17.9038 20.452L16.8598 19.4078L18.7098 17.5578H5.29027L7.14027 19.4078L6.09627 20.452ZM2.38477 10.75V10.377C2.38477 10.0347 2.48027 9.72409 2.67127 9.44526C2.86227 9.16643 3.12377 8.95326 3.45577 8.80576C3.86993 8.62243 4.29977 8.48401 4.74527 8.39051C5.19077 8.29684 5.65393 8.25001 6.13477 8.25001C6.61543 8.25001 7.07852 8.29684 7.52402 8.39051C7.96952 8.48401 8.39935 8.62243 8.81352 8.80576C9.14552 8.95326 9.40702 9.16643 9.59802 9.44526C9.78902 9.72409 9.88452 10.0347 9.88452 10.377V10.75H2.38477ZM14.1155 10.75V10.377C14.1155 10.0347 14.211 9.72409 14.402 9.44526C14.593 9.16643 14.8545 8.95326 15.1865 8.80576C15.6007 8.62243 16.0305 8.48401 16.476 8.39051C16.9215 8.29684 17.3846 8.25001 17.8653 8.25001C18.3461 8.25001 18.8093 8.29684 19.2548 8.39051C19.7003 8.48401 20.1301 8.62243 20.5443 8.80576C20.8763 8.95326 21.1378 9.16643 21.3288 9.44526C21.5198 9.72409 21.6153 10.0347 21.6153 10.377V10.75H14.1155ZM6.13477 6.94226C5.64877 6.94226 5.2356 6.77209 4.89527 6.43176C4.55493 6.09143 4.38477 5.67826 4.38477 5.19226C4.38477 4.70643 4.55493 4.29334 4.89527 3.95301C5.2356 3.61251 5.64877 3.44226 6.13477 3.44226C6.6206 3.44226 7.03368 3.61251 7.37402 3.95301C7.71435 4.29334 7.88452 4.70643 7.88452 5.19226C7.88452 5.67826 7.71435 6.09143 7.37402 6.43176C7.03368 6.77209 6.6206 6.94226 6.13477 6.94226ZM17.8653 6.94226C17.3794 6.94226 16.9663 6.77209 16.626 6.43176C16.2857 6.09143 16.1155 5.67826 16.1155 5.19226C16.1155 4.70643 16.2857 4.29334 16.626 3.95301C16.9663 3.61251 17.3794 3.44226 17.8653 3.44226C18.3513 3.44226 18.7644 3.61251 19.1048 3.95301C19.4451 4.29334 19.6153 4.70643 19.6153 5.19226C19.6153 5.67826 19.4451 6.09143 19.1048 6.43176C18.7644 6.77209 18.3513 6.94226 17.8653 6.94226Z" />
        </g>
      </svg>
    </div>
  );
};
