// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {addCN} from '@/_core/util/cssHelper';
import {IconProps, getSize} from '../types';

export const IconPdf: React.FC<IconProps> = ({size = 'md', color = 'fill-grey700'}) => {
  const finSize = getSize(size);
  return (
    <div>
      <svg
        className={addCN(finSize, color, 'transition')}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <mask
          id="mask0_3211_71"
          style={{maskType: 'alpha'}}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="24"
          height="24"
        >
          <rect width="24" height="24" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_3211_71)">
          <path d="M8.827 12.4423H9.7115V10.4423H10.827C11.0782 10.4423 11.2884 10.3577 11.4578 10.1885C11.6269 10.0192 11.7115 9.80892 11.7115 9.55775V8.44225C11.7115 8.19108 11.6269 7.98083 11.4578 7.8115C11.2884 7.64233 11.0782 7.55775 10.827 7.55775H8.827V12.4423ZM9.7115 9.55775V8.44225H10.827V9.55775H9.7115ZM12.7308 12.4423H14.6923C14.9436 12.4423 15.1538 12.3577 15.323 12.1885C15.4923 12.0192 15.577 11.8089 15.577 11.5577V8.44225C15.577 8.19108 15.4923 7.98083 15.323 7.8115C15.1538 7.64233 14.9436 7.55775 14.6923 7.55775H12.7308V12.4423ZM13.6152 11.5577V8.44225H14.6923V11.5577H13.6152ZM16.6923 12.4423H17.577V10.4423H18.7693V9.55775H17.577V8.44225H18.7693V7.55775H16.6923V12.4423ZM8.05775 17.5C7.55258 17.5 7.125 17.325 6.775 16.975C6.425 16.625 6.25 16.1974 6.25 15.6923V4.30775C6.25 3.80258 6.425 3.375 6.775 3.025C7.125 2.675 7.55258 2.5 8.05775 2.5H19.4423C19.9474 2.5 20.375 2.675 20.725 3.025C21.075 3.375 21.25 3.80258 21.25 4.30775V15.6923C21.25 16.1974 21.075 16.625 20.725 16.975C20.375 17.325 19.9474 17.5 19.4423 17.5H8.05775ZM8.05775 16H19.4423C19.5193 16 19.5898 15.9679 19.6538 15.9038C19.7179 15.8398 19.75 15.7692 19.75 15.6923V4.30775C19.75 4.23075 19.7179 4.16025 19.6538 4.09625C19.5898 4.03208 19.5193 4 19.4423 4H8.05775C7.98075 4 7.91025 4.03208 7.84625 4.09625C7.78208 4.16025 7.75 4.23075 7.75 4.30775V15.6923C7.75 15.7692 7.78208 15.8398 7.84625 15.9038C7.91025 15.9679 7.98075 16 8.05775 16ZM4.55775 21C4.05258 21 3.625 20.825 3.275 20.475C2.925 20.125 2.75 19.6974 2.75 19.1923V6.30775H4.25V19.1923C4.25 19.2693 4.28208 19.3398 4.34625 19.4038C4.41025 19.4679 4.48075 19.5 4.55775 19.5H17.4423V21H4.55775Z" />
        </g>
      </svg>
    </div>
  );
};
