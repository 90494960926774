// @ts-nocheck
/* eslint-disable */

import {ConfigureSoftFacts} from '@modules/ContentEngine/components/items/general/candidate-analysis/ConfigureSoftFacts';
import {SelectCustomSurveys} from '@modules/ContentEngine/components/items/general/candidate-analysis/SelectCustomSurveys';
import {SelectSoftFacts} from '@modules/ContentEngine/components/items/general/candidate-analysis/SelectSoftFacts';

export const RegisteredComponents = {
  // Candidate Analysis components
  candidateAnalysis: {
    selectSoftFacts: SelectSoftFacts,
    configureSoftFacts: ConfigureSoftFacts,
    selectCustomSurveys: SelectCustomSurveys,
  },
} as const;
