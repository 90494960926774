// @ts-nocheck
/* eslint-disable */

import React, {useEffect, useRef, useState} from 'react';
import {addCN} from '@/_core/util/cssHelper';
import {Indicator} from '../Indicator';
import {ToolTip} from '../ToolTip';

interface _style {
  zIndex?: number;
  hasBorder?: boolean;
}

const useGetStyle = (ref: React.RefObject<HTMLDivElement>, size: 'sm' | 'md' | 'lg'): _style | undefined => {
  const [style, setStyle] = useState<undefined | _style>(undefined);

  useEffect(() => {
    let zIndex;
    let hasBorder;
    if (ref?.current) {
      // Is avatarGroup in the classNames of the parent?

      if (ref.current?.parentNode instanceof Element && ref.current.parentNode.classList.contains('avatarGroup')) {
        hasBorder = true;
        const currentChildren = ref.current.parentNode.children;
        const indexOfElInParent = Array.from(currentChildren).indexOf(ref.current);
        const totalElementsInParent = ref.current.parentNode.childElementCount;
        zIndex = (-indexOfElInParent + totalElementsInParent) * 2;
      }

      setStyle({zIndex, hasBorder});
    }
  }, [size, ref]);

  return style ?? {zIndex: 10, hasBorder: false};
};

interface _Avatar {
  size?: 'sm' | 'md' | 'lg';
  image?: string;
  initials?: string;
  indicator?: 'enabled' | 'warning' | 'danger' | 'disabled' | 'success';
  icon?: React.ElementType;
  backgroundColor?: string;
  tooltip?: string;
}

export const Avatar: React.FC<_Avatar> = ({
  size = 'md',
  image,
  initials,
  indicator,
  icon: Icon,
  backgroundColor = 'bg-grey300',
  tooltip,
}) => {
  const ref = useRef<HTMLDivElement>(null);

  // checks if we are in a AvatarGroup
  const finStyle = useGetStyle(ref, size);

  let zIndex;
  let hasBorder;
  if (finStyle) ({zIndex, hasBorder} = finStyle);

  let finSize;
  if (size === 'sm') finSize = 'h-3 w-3';
  if (size === 'md') finSize = 'h-4 w-4';
  if (size === 'lg') finSize = 'h-5 w-5';

  if ((initials && image) ?? (initials && Icon) ?? (Icon && image)) {
    console.error('Avatar: You can only use one of the following: image or initials or icon');
  }
  if (initials && initials?.length > 2) {
    console.error('Avatar: Initials can only be 2 characters long');
  }

  const avatarContent = (
    <div style={{zIndex}} ref={ref} className={addCN('flex flex-row gap-1 items-center')}>
      <div className="relative">
        <div
          className={addCN(
            image ? '' : 'p-[8px]',
            'rounded-full',
            backgroundColor,
            'flex justify-center items-center gap-[8px] shrink-0',
            hasBorder ? 'border-1 border-white500' : '',
            finSize
          )}
        >
          {image && <img className="inline-block h-full w-full rounded-full" src={image} alt="" />}

          {initials && <span className="text-caption !text-typography-paragraph">{initials}</span>}

          {Icon && <Icon size="sm" />}

          {indicator && (
            <div className={addCN('absolute', size === 'lg' ? 'right-[2px] bottom-[2px]' : 'right-[0px] bottom-[0px]')}>
              <Indicator color={indicator} size={size === 'sm' ? 'xs' : 'sm'} />
            </div>
          )}
        </div>
      </div>
    </div>
  );

  if (tooltip) {
    return <ToolTip txt={tooltip}>{avatarContent}</ToolTip>;
  }

  return avatarContent;
};
