// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {addCN} from '@/_core/util/cssHelper';
import {IconProps, getSize} from '../types';

export const IconHome: React.FC<IconProps> = ({size = 'md', color = 'fill-grey700'}) => {
  const finSize = getSize(size);
  return (
    <div>
      <svg
        className={addCN(finSize, color, 'transition')}
        width="32"
        height="32"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M7.99997 25.3334H12.4615V17.4104H19.5384V25.3334H24V13.3334L16 7.3078L7.99997 13.3334V25.3334ZM6 27.3334V12.3335L16 4.80786L25.9999 12.3335V27.3334H17.5384V19.4103H14.4615V27.3334H6Z" />
      </svg>
    </div>
  );
};
