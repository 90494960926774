// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {addCN} from '@/_core/util/cssHelper';
import {IconProps, getSize} from '../types';

export const IconMic: React.FC<IconProps> = ({size = 'md', color = 'fill-grey700'}) => {
  const finSize = getSize(size);
  return (
    <div>
      <svg
        className={addCN(finSize, color, 'transition')}
        width="32"
        height="32"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M16.0001 17.9999C15.0685 17.9999 14.2801 17.6772 13.6348 17.0319C12.9895 16.3866 12.6668 15.5982 12.6668 14.6666V6.66655C12.6668 5.73495 12.9895 4.9465 13.6348 4.30119C14.2801 3.6559 15.0685 3.33325 16.0001 3.33325C16.9317 3.33325 17.7202 3.6559 18.3655 4.30119C19.0108 4.9465 19.3334 5.73495 19.3334 6.66655V14.6666C19.3334 15.5982 19.0108 16.3866 18.3655 17.0319C17.7202 17.6772 16.9317 17.9999 16.0001 17.9999ZM15.0002 27.6665V23.2716C12.8002 23.0204 10.9724 22.0768 9.51683 20.4409C8.06127 18.805 7.3335 16.8802 7.3335 14.6666H9.33346C9.33346 16.511 9.98346 18.0832 11.2835 19.3832C12.5835 20.6832 14.1557 21.3332 16.0001 21.3332C17.8446 21.3332 19.4168 20.6832 20.7168 19.3832C22.0168 18.0832 22.6668 16.511 22.6668 14.6666H24.6668C24.6668 16.8802 23.939 18.805 22.4834 20.4409C21.0279 22.0768 19.2001 23.0204 17.0001 23.2716V27.6665H15.0002ZM16.0001 15.9999C16.3779 15.9999 16.6946 15.8721 16.9501 15.6166C17.2057 15.361 17.3335 15.0443 17.3335 14.6666V6.66655C17.3335 6.28878 17.2057 5.97211 16.9501 5.71655C16.6946 5.461 16.3779 5.33322 16.0001 5.33322C15.6224 5.33322 15.3057 5.461 15.0501 5.71655C14.7946 5.97211 14.6668 6.28878 14.6668 6.66655V14.6666C14.6668 15.0443 14.7946 15.361 15.0501 15.6166C15.3057 15.8721 15.6224 15.9999 16.0001 15.9999Z" />
      </svg>
    </div>
  );
};
