// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {addCN} from '@/_core/util/cssHelper';
import {IconProps, getSize} from '../types';

export const IconSocialRole: React.FC<IconProps> = ({size = 'md', color = 'fill-grey700'}) => {
  const finSize = getSize(size);
  return (
    <div>
      <svg
        className={addCN(finSize, color, 'transition')}
        width="32"
        height="32"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M25.9667 27.3845L17.7052 19.1486L19.1103 17.7435L27.3718 26.005L25.9667 27.3845ZM7.94621 26.0921C6.82657 24.8699 5.9958 23.5152 5.45391 22.0281C4.91205 20.5409 4.64111 19.0247 4.64111 17.4794C4.64111 15.823 4.94838 14.2132 5.56291 12.6499C6.17745 11.0867 7.11719 9.67686 8.38215 8.42044C9.64708 7.1555 11.0646 6.21235 12.6347 5.59097C14.2048 4.96961 15.8095 4.65894 17.4487 4.65894C19.0026 4.65894 20.5175 4.93329 21.9936 5.482C23.4696 6.03071 24.8231 6.86489 26.0538 7.98454L7.94621 26.0921ZM8.16158 23.0255L10.2693 20.9178C9.91376 20.4341 9.56846 19.9307 9.23341 19.4076C8.89837 18.8845 8.59751 18.3443 8.33085 17.787C8.06418 17.2298 7.84152 16.6533 7.66288 16.0575C7.48426 15.4618 7.35136 14.8579 7.26418 14.246C6.73767 15.7109 6.55859 17.208 6.72695 18.737C6.89532 20.2661 7.37354 21.6956 8.16158 23.0255ZM11.741 19.5127L19.4744 11.7127C18.5189 10.9794 17.5513 10.3935 16.5718 9.95504C15.5924 9.51657 14.6697 9.22041 13.8039 9.06657C12.9381 8.91272 12.1556 8.8999 11.4564 9.0281C10.7573 9.1563 10.2146 9.41357 9.82825 9.7999C9.44191 10.1999 9.18465 10.7495 9.05645 11.4486C8.92825 12.1478 8.93894 12.9337 9.08851 13.8063C9.23807 14.679 9.53208 15.6038 9.97055 16.5807C10.409 17.5576 10.9992 18.535 11.741 19.5127ZM20.9051 10.3076L23.0539 8.1999C21.6881 7.38621 20.2385 6.89818 18.7051 6.7358C17.1718 6.5734 15.6726 6.75376 14.2076 7.27687C14.8418 7.35549 15.4568 7.48839 16.0525 7.67557C16.6483 7.86275 17.2248 8.08625 17.782 8.34607C18.3393 8.60591 18.8782 8.90335 19.3987 9.23837C19.9193 9.57341 20.4214 9.92982 20.9051 10.3076Z" />
      </svg>
    </div>
  );
};
