// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {addCN} from '@/_core/util/cssHelper';
import {IconProps, getSize} from '../types';

export const IconArrowDoubleLeft: React.FC<IconProps> = ({size = 'md', color = 'fill-grey700'}) => {
  const finSize = getSize(size);
  return (
    <div>
      <svg
        className={addCN(finSize, color, 'transition')}
        width="32"
        height="32"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M14.8333 23.5385L7.29492 16.0001L14.8333 8.46167L16.2384 9.86677L10.1256 16.0001L16.2384 22.1334L14.8333 23.5385ZM23.3 23.5385L15.7616 16.0001L23.3 8.46167L24.7051 9.86677L18.5923 16.0001L24.7051 22.1334L23.3 23.5385Z" />
      </svg>
    </div>
  );
};
