// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {addCN} from '@/_core/util/cssHelper';
import {IconProps, getSize} from '../types';

export const IconCollapseSettings: React.FC<IconProps> = ({size = 'md', color = 'fill-grey700'}) => {
  const finSize = getSize(size);
  return (
    <div>
      <svg
        className={addCN(finSize, color, 'transition')}
        width="25"
        height="24"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
      >
        <mask id="mask0_7137_11919" maskUnits="userSpaceOnUse" x="0" y="0" width="32" height="32">
          <rect width="32" height="32" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_7137_11919)">
          <path d="M26.6654 23.6667V8.33337H28.6654V23.6667H26.6654ZM16.2167 23.5257L14.791 22.1334L19.9244 17H3.33203V15H19.9117L14.8244 9.86671L16.2167 8.47437L23.7424 16L16.2167 23.5257Z" />
        </g>
      </svg>
    </div>
  );
};
