// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {addCN} from '@/_core/util/cssHelper';
import {IconProps, getSize} from '../types';

export const IconArrowDownward: React.FC<IconProps> = ({size = 'md', color = 'fill-[#ED6964]'}) => {
  const finSize = getSize(size);
  return (
    <div>
      <svg
        className={addCN(finSize, color, 'transition')}
        width="16"
        height="17"
        viewBox="0 0 16 17"
        xmlns="http://www.w3.org/2000/svg"
      >
        <mask
          id="mask0_7318_1229"
          style={{maskType: 'alpha'}}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="16"
          height="17"
        >
          <rect y="0.5" width="16" height="16" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_7318_1229)">
          <path d="M7.5 3.5V11.5847L3.7025 7.78717L3 8.5L8 13.5L13 8.5L12.2975 7.78717L8.5 11.5847V3.5H7.5Z" />
        </g>
      </svg>
    </div>
  );
};
