// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {addCN} from '@/_core/util/cssHelper';
import {IconProps, getSize} from '../types';

export const IconEnergySource: React.FC<IconProps> = ({size = 'md', color = 'fill-grey700'}) => {
  const finSize = getSize(size);
  return (
    <div>
      <svg
        className={addCN(finSize, color, 'transition')}
        width="32"
        height="32"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M11.1284 28.6665C10.787 28.6665 10.5008 28.551 10.2698 28.32C10.0388 28.089 9.92334 27.8028 9.92334 27.4614V6.85889C9.92334 6.51742 10.0388 6.2312 10.2698 6.00022C10.5008 5.76924 10.787 5.65375 11.1284 5.65375H13.5387V3.33325H18.4617V5.65375H20.8746C21.2164 5.65375 21.5023 5.76924 21.7322 6.00022C21.9621 6.2312 22.0771 6.51742 22.0771 6.85889V16.1281C20.0173 16.2614 18.2758 17.0747 16.8528 18.5679C15.4297 20.061 14.7182 21.8589 14.7182 23.9614C14.7182 24.8469 14.8575 25.6896 15.1361 26.4896C15.4147 27.2896 15.8122 28.0152 16.3284 28.6665H11.1284ZM11.9233 10.564H20.0771V7.64089H11.9233V10.564ZM22.0515 28.9871V25.2307H19.3592L23.2822 19.0127V22.7691H25.9745L22.0515 28.9871Z" />
      </svg>
    </div>
  );
};
