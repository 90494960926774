// @ts-nocheck
/* eslint-disable */

import React, {ReactElement, useState, useRef, useCallback} from 'react';
import {addCN} from '@/_core/util/cssHelper';
import {Typography} from '@modules/Core/components/base/Typography';
import {IconButton} from './buttons/IconButton';
import {ExpandableContent} from './ExpandableContent';
import {IconZoomIn} from './Icons/Icons';
import {ToolTip} from './ToolTip';

interface _IndicatorProps {}

interface _Slider {
  title?: string;
  subtitle?: string;
  bottomTitle?: string;
  bottomSubtitle?: string;
  leftText?: string;
  leftTooltip?: string;
  rightTooltip?: string;
  rightText?: string;
  rightIcon?: React.ElementType;
  leftIcon?: React.ElementType;
  components: Array<{id: string; progress: number; component: ReactElement; indicatorColor?: string}>;
  showExpand?: boolean;
  enableEndicator?: boolean;
  onTitleActionClick?: () => void;
  onIndicatorClick?: (id: string) => void;
  rangeStart?: number;
  rangeEnd?: number;
  rangeColor?: string;
  enableRangeSelection?: boolean;
  onRangeChange?: (changes: {id: string; progress: number} | {min: number; max: number}) => void;
  simpleStyle?: boolean;
}

export const Slider: React.FC<_Slider> = props => {
  const [isExpanded, setIsExpanded] = useState(false);
  const sliderRef = useRef<HTMLDivElement>(null);
  const isDraggingRange = useRef(false);
  const initialDragState = useRef<{startX: number; rangeStartValue: number; rangeEndValue: number} | null>(null);

  const {
    onTitleActionClick,
    title,
    subtitle,
    bottomTitle,
    bottomSubtitle,
    leftText,
    leftTooltip,
    rightTooltip,
    rightText,
    components,
    showExpand,
    enableEndicator,
    onIndicatorClick,
    rangeStart,
    rangeEnd,
    rangeColor,
    enableRangeSelection,
    onRangeChange,
    simpleStyle,
  } = props;

  const handleDrag = useCallback(
    (id: string, clientX: number) => {
      if (!sliderRef.current) return;

      const rect = sliderRef.current.getBoundingClientRect();
      const progress = Math.min(100, Math.max(0, ((clientX - rect.left) / rect.width) * 100));
      onRangeChange?.({id, progress});
    },
    [onRangeChange]
  );

  const handleRangeMouseDown = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      if (!sliderRef.current || rangeStart === undefined || rangeEnd === undefined) return;

      isDraggingRange.current = true;
      initialDragState.current = {
        startX: e.clientX,
        rangeStartValue: rangeStart,
        rangeEndValue: rangeEnd,
      };

      const handleRangeMouseMove = (e: MouseEvent) => {
        e.preventDefault();
        if (!isDraggingRange.current || !initialDragState.current || !sliderRef.current) return;

        const rect = sliderRef.current.getBoundingClientRect();
        const deltaX = e.clientX - initialDragState.current.startX;
        const deltaPercent = (deltaX / rect.width) * 100;

        const newStart = Math.min(100, Math.max(0, initialDragState.current.rangeStartValue + deltaPercent));
        const newEnd = Math.min(100, Math.max(0, initialDragState.current.rangeEndValue + deltaPercent));

        // Only move if both points stay within bounds
        if (newStart >= 0 && newEnd <= 100) {
          onRangeChange?.({min: newStart, max: newEnd});
        }
      };

      const handleRangeMouseUp = () => {
        isDraggingRange.current = false;
        initialDragState.current = null;
        document.removeEventListener('mousemove', handleRangeMouseMove);
        document.removeEventListener('mouseup', handleRangeMouseUp);
      };

      document.addEventListener('mousemove', handleRangeMouseMove);
      document.addEventListener('mouseup', handleRangeMouseUp);
    },
    [components, onRangeChange, rangeStart, rangeEnd]
  );

  const handleTrackClick = useCallback(
    (e: React.MouseEvent) => {
      if (!enableRangeSelection && !rangeStart && !rangeEnd) return;
      if (!sliderRef.current) return;

      const rect = sliderRef.current.getBoundingClientRect();
      const clickPosition = ((e.clientX - rect.left) / rect.width) * 100;

      if (rangeStart !== undefined && rangeEnd !== undefined) {
        // Move existing range, keeping its width
        const rangeWidth = rangeEnd - rangeStart;
        const halfWidth = rangeWidth / 2;
        const newStart = Math.max(0, clickPosition - halfWidth);
        const newEnd = Math.min(100, clickPosition + halfWidth);

        // Only update if the range fits within bounds
        if (newStart >= 0 && newEnd <= 100) {
          onRangeChange?.({min: newStart, max: newEnd});
        }
      } else {
        // Create new range
        const newStart = Math.max(0, clickPosition - 10);
        const newEnd = Math.min(100, clickPosition + 10);
        onRangeChange?.({min: newStart, max: newEnd});
      }
    },
    [enableRangeSelection, rangeStart, rangeEnd, onRangeChange]
  );

  return (
    <div className="flex flex-col items-start gap-5 w-full">
      {/* top text area */}
      {!simpleStyle && (
        <div className="flex w-full flex-col items-start gap-2">
          <div className="flex flex-row justify-between w-full">
            <Typography variant="h6">{title}</Typography>
            {onTitleActionClick && (
              <div>
                <IconButton onClick={onTitleActionClick} icon={IconZoomIn} />
              </div>
            )}
          </div>
          {subtitle ? <span className="">{subtitle}</span> : null}
        </div>
      )}

      <div className="sliderWrapper flex flex-col items-center gap-3 w-full">
        <div className="slider flex items-center gap-3 w-full">
          <div
            className={addCN(
              'sliderContent flex items-top justify-between gap-3 w-full',
              enableEndicator ? 'pb-5' : ''
            )}
          >
            {simpleStyle && title ? (
              <div className="relative-col-width-3">
                <Typography variant="body1" className="font-medium whitespace-nowrap">
                  {title}
                </Typography>
              </div>
            ) : (
              !simpleStyle && (
                <div className="flex justify-between items-center relative-col-width-1">
                  <span className="text-body2">{leftText}</span>
                  {props?.leftIcon ? (
                    <ToolTip plc="top-right" txt={leftTooltip}>
                      <props.leftIcon />
                    </ToolTip>
                  ) : null}
                </div>
              )
            )}

            <div ref={sliderRef} className={addCN('relative', simpleStyle ? 'flex-1 mt-1' : 'relative-col-width-8')}>
              {/* track */}
              <div
                className={addCN('w-full h-2 bg-grey100 rounded-lg flex items-center justify-start cursor-pointer')}
                onClick={handleTrackClick}
              />

              {/* Range indicator */}
              {rangeStart !== undefined && rangeEnd !== undefined && (
                <div
                  className={addCN(
                    'absolute h-2 rounded-lg cursor-grab',
                    rangeColor || 'bg-blue700',
                    isDraggingRange.current && 'cursor-grabbing'
                  )}
                  style={{
                    left: `${rangeStart}%`,
                    width: `${rangeEnd - rangeStart}%`,
                    top: 0,
                  }}
                  onMouseDown={handleRangeMouseDown}
                />
              )}

              {components.map(comp => (
                <CustomSlider
                  onDrag={clientX => handleDrag(comp.id, clientX)}
                  key={comp.id}
                  progress={comp.progress}
                  component={comp.component}
                  color={comp.indicatorColor}
                  enableIndicator={enableEndicator}
                />
              ))}

              {/* Labels for simpleStyle */}
              {simpleStyle && (
                <div className="w-full mt-1">
                  <div className="flex justify-between">
                    <Typography variant="body2" className="flex items-center gap-0 text-typography-secondary">
                      {leftText}
                      {props?.rightIcon && (
                        <ToolTip plc="top-left" txt={rightTooltip}>
                          <props.rightIcon />
                        </ToolTip>
                      )}
                    </Typography>
                    <Typography variant="body2" className="flex items-center gap-0 text-typography-secondary">
                      {props?.leftIcon && (
                        <ToolTip plc="top-left" txt={leftTooltip}>
                          <props.leftIcon />
                        </ToolTip>
                      )}
                      {rightText}
                    </Typography>
                  </div>
                </div>
              )}
            </div>

            {!simpleStyle && (
              <div className="flex justify-end items-center gap-1 relative-col-width-1">
                {props?.rightIcon ? (
                  <ToolTip plc="top-left" txt={rightTooltip}>
                    <props.rightIcon />
                  </ToolTip>
                ) : null}
                <span className="text-body2 text-right">{rightText}</span>
              </div>
            )}
          </div>
        </div>

        {showExpand && !simpleStyle && (
          <ExpandableContent>
            <div className="flex flex-col gap-2 relative-col-width-8 relative-col-start-2">
              {bottomTitle ? <p className="text-body1 text-typography-subheadings">{bottomTitle}</p> : null}
              {bottomSubtitle ? <p className="text-body">{bottomSubtitle}</p> : null}
            </div>
          </ExpandableContent>
        )}
      </div>
    </div>
  );
};

interface _CustomSlider {
  progress: number;
  component: ReactElement;
  color?: string;
  enableIndicator?: boolean;
  onDrag?: (clientX: number) => void;
  className?: string;
}

const CustomSlider: React.FC<_CustomSlider> = ({onDrag, color, progress, component, enableIndicator, className}) => {
  const isDragging = useRef(false);

  const handleMouseDown = (e: React.MouseEvent) => {
    e.preventDefault();
    isDragging.current = true;
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };

  const handleMouseMove = useCallback(
    (e: MouseEvent) => {
      if (isDragging.current) {
        e.preventDefault();
        onDrag?.(e.clientX);
      }
    },
    [onDrag]
  );

  const handleMouseUp = useCallback(() => {
    isDragging.current = false;
    document.removeEventListener('mousemove', handleMouseMove);
    document.removeEventListener('mouseup', handleMouseUp);
  }, [handleMouseMove]);

  return (
    <div
      className={addCN(
        'absolute z-10 top-[2px] select-none cursor-grab',
        isDragging.current && 'cursor-grabbing',
        className
      )}
      style={{
        top: 0,
        transform: 'translate(-25%, -25%)',
        left: `calc(${progress}% - 12px)`,
        touchAction: 'none',
      }}
      onMouseDown={handleMouseDown}
    >
      <div className="flex flex-col items-center" style={{pointerEvents: 'none'}}>
        {enableIndicator ? <div className={addCN('h-6 w-0', color ?? ' bg-green700')} /> : null}
        <div style={{pointerEvents: 'auto'}}>{component}</div>
      </div>
    </div>
  );
};
