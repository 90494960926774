// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {addCN} from '@/_core/util/cssHelper';
import {IconProps, getSize} from '../types';

export const IconSoftFact: React.FC<IconProps> = ({size = 'md', color = 'fill-red500'}) => {
  const finSize = getSize(size);
  return (
    <div>
      <svg
        className={addCN(finSize, color, 'transition')}
        width="26"
        height="22"
        viewBox="0 0 26 22"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.1154 2.22942C14.0633 2.06241 14.0781 1.88163 14.1568 1.7254C14.2356 1.56915 14.372 1.44973 14.5373 1.3924L18.0566 0.0507328C18.1727 0.0069082 18.2976 -0.00885106 18.4209 0.00473162C18.5443 0.0183143 18.6627 0.0608413 18.7665 0.12888L24 3.59397C24.1427 3.68679 24.2471 3.82816 24.2939 3.99197L25.8883 9.75584L25.9799 10.117C25.998 10.1881 26.0039 10.2617 25.9975 10.3347L25.9899 10.4216C25.983 10.4986 25.9625 10.5737 25.9294 10.6435L21.3576 20.2838C21.2889 20.4138 21.1841 20.5211 21.0558 20.5927C20.9275 20.6644 20.7812 20.6973 20.6345 20.6875C20.4879 20.6777 20.3473 20.6257 20.2296 20.5376C20.1119 20.4496 20.0222 20.3293 19.9714 20.1913L14.1154 2.22942Z"
          fill="#FF5C5C"
        />
        <path
          d="M19.8521 20.9907C19.9981 20.9797 20.132 20.8496 20.067 20.6823L16.9353 12.6195L13.031 2.83891C13.0114 2.7936 12.9789 2.75501 12.9377 2.7279C12.8965 2.7008 12.8482 2.68634 12.7989 2.68632C12.7495 2.6863 12.7012 2.7007 12.66 2.72778C12.6311 2.74672 12.6066 2.77125 12.5877 2.79977C12.5796 2.81206 12.5725 2.82507 12.5666 2.8387L10.8471 6.89471C10.8184 6.9575 10.8033 7.02568 10.8029 7.09473C10.8024 7.16379 10.8166 7.23216 10.8446 7.29531C10.8777 7.36688 10.9118 7.43492 10.9291 7.46311L16.2179 15.622L10.3442 7.79138C10.3076 7.74927 10.2687 7.70917 10.2278 7.67121C10.1194 7.57301 9.97914 7.51738 9.83296 7.51464L5.13724 7.30253C5.08876 7.29499 5.03912 7.3033 4.9957 7.32618C4.95228 7.34905 4.91739 7.38532 4.89614 7.42958C4.87491 7.47385 4.86849 7.52378 4.87781 7.57198C4.88714 7.6202 4.91172 7.66413 4.94793 7.69728L12.9641 14.9931L19.6084 20.9342C19.6849 21.0026 19.7751 21.0143 19.8521 20.9907Z"
          fill="#FF5C5C"
        />
        <path
          d="M3.96598 8.51348C3.8241 8.4004 3.64723 8.34042 3.46587 8.34387C3.28452 8.34731 3.11006 8.41398 2.97257 8.53238L0.226131 10.9802C0.13734 11.0575 0.0708673 11.1573 0.0336987 11.269C-0.00349085 11.3808 -0.0100377 11.5005 0.0147483 11.6157L1.29423 17.4723C1.3318 17.6379 1.42833 17.7841 1.56581 17.8836L6.58065 21.5786L6.89814 21.8031C6.96137 21.848 7.03102 21.8832 7.10473 21.9073L7.19321 21.936C7.27158 21.9614 7.35343 21.9743 7.4358 21.9745L18.7608 22C19.4471 22.0016 19.7564 21.2046 19.2326 20.7838L3.96598 8.51348Z"
          fill="#FF5C5C"
        />
      </svg>
    </div>
  );
};
