// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {addCN} from '@/_core/util/cssHelper';
import {IconProps, getSize} from '../types';

export const IconEvaluator: React.FC<IconProps> = ({size = 'md', color = 'fill-grey700'}) => {
  const finSize = getSize(size);
  return (
    <div>
      <svg
        className={addCN(finSize, color, 'transition')}
        width="32"
        height="32"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M5.74376 23.3333C5.07025 23.3333 4.50016 23.1 4.0335 22.6333C3.56683 22.1667 3.3335 21.5966 3.3335 20.9231V11.077C3.3335 10.4035 3.56683 9.83341 4.0335 9.36675C4.50016 8.90008 5.07025 8.66675 5.74376 8.66675H26.2565C26.93 8.66675 27.5001 8.90008 27.9668 9.36675C28.4334 9.83341 28.6668 10.4035 28.6668 11.077V20.9231C28.6668 21.5966 28.4334 22.1667 27.9668 22.6333C27.5001 23.1 26.93 23.3333 26.2565 23.3333H5.74376ZM5.74376 21.3334H26.2565C26.3591 21.3334 26.4531 21.2906 26.5386 21.2052C26.6241 21.1197 26.6668 21.0257 26.6668 20.9231V11.077C26.6668 10.9744 26.6241 10.8804 26.5386 10.7949C26.4531 10.7094 26.3591 10.6667 26.2565 10.6667H22.3334V15.4872H20.3335V10.6667H17.0001V15.4872H15.0002V10.6667H11.6668V15.4872H9.66683V10.6667H5.74376C5.64119 10.6667 5.54715 10.7094 5.46166 10.7949C5.3762 10.8804 5.33346 10.9744 5.33346 11.077V20.9231C5.33346 21.0257 5.3762 21.1197 5.46166 21.2052C5.54715 21.2906 5.64119 21.3334 5.74376 21.3334Z" />
      </svg>
    </div>
  );
};
