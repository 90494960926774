// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {_ContentEngineContext} from '@modules/ContentEngine/types/contentEngineItem.model';
import {Card} from '@modules/Core/components/base/cards/Card';
import {IconAdd, IconMore, IconNote, IconTrash, IconCheck} from '@modules/Core/components/base/Icons/Icons';
import {ContextMenu} from '@modules/Core/components/base/inputs/ContextMenu';
import {Typography} from '@modules/Core/components/base/Typography';
import {Section} from '@modules/Core/components/layout/Section';
import {logger} from '@modules/Core/util/Logger';
import {_CustomSurvey, _CustomSurveyStatus} from '@modules/CustomSurveys/types/customSurvey.model';
import {getLocalizedText} from '@modules/CustomSurveys/util/customSurveyMapperUtil';
import {useNavigateToFlow} from '@modules/FlowEngine/hooks/navigateToFlow';
import {currentLanguage, trans} from '@modules/Translations/util/i18n';

interface _Props {
  surveys: Record<_CustomSurveyStatus, _CustomSurvey[]>;
  context: _ContentEngineContext;
  tutorialKey?: string;
  tutorialService?: string;
  selectedCustomSurveys?: string[];
  onSelectSurvey?: (surveyId: string) => void;
}

export const CustomSurveysListItem: React.FC<_Props> = ({surveys, selectedCustomSurveys = [], onSelectSurvey}) => {
  const publishedSurveys = surveys?.published ?? [];
  const navigateToFlow = useNavigateToFlow();

  logger.debug('[CustomSurveysListItem] surveys', surveys);

  const handleCreateNew = () => {
    navigateToFlow('CustomSurvey', {new: true});
  };

  const handleCardClick = (surveyId: string) => {
    onSelectSurvey?.(surveyId);
  };

  if (!publishedSurveys.length) {
    return (
      <Section
        title={trans('candidate_analysis.custom_surveys_list.title')}
        description={trans('candidate_analysis.custom_surveys_list.no_surveys')}
        gap={6}
      >
        <Card
          padding={4}
          gap={3}
          width="relative-col-width-3"
          className="items-center cursor-pointer hover:shadow-lg transition-all duration-200"
          onClick={handleCreateNew}
        >
          <IconAdd size="lg" />
          <Typography variant="body">{trans('candidate_analysis.custom_surveys_list.create_new')}</Typography>
        </Card>
      </Section>
    );
  }

  return (
    <Section
      title={trans('candidate_analysis.custom_surveys_list.get_to_know')}
      description={trans('candidate_analysis.custom_surveys_list.get_to_know_desc')}
      descriptionVariant="body1"
      gap={6}
    >
      <Section
        title={trans('candidate_analysis.custom_surveys_list.start_existing')}
        description={trans('candidate_analysis.custom_surveys_list.start_existing_desc')}
      >
        <div className="flex flex-wrap gap-2">
          {publishedSurveys.map(survey => {
            const isSelected = selectedCustomSurveys.includes(survey.id);

            return (
              <Card
                key={survey.id}
                width="relative-col-width-3"
                padding={2}
                gap={2}
                onClick={() => handleCardClick(survey.id)}
                isSelected={isSelected}
                forSurvey
              >
                <Typography variant="body" bold className="line-clamp-1 overflow-hidden text-ellipsis">
                  {getLocalizedText(survey.title)}
                </Typography>

                <Typography variant="body2" className="h-7 line-clamp-2 overflow-hidden text-ellipsis">
                  {getLocalizedText(survey.description)}
                </Typography>

                <div className="flex justify-between gap-4">
                  <Typography variant="body2">
                    {trans('candidate_analysis.custom_surveys_list.questions_count', {
                      count: survey.questions?.length || 0,
                    })}
                  </Typography>
                </div>
              </Card>
            );
          })}
        </div>
      </Section>

      <Section
        title={trans('candidate_analysis.custom_surveys_list.start_scratch')}
        description={trans('candidate_analysis.custom_surveys_list.start_scratch_desc')}
      >
        <div className="flex-row">
          <Card
            padding={4}
            gap={3}
            width="relative-col-width-3"
            className="items-center cursor-pointer hover:shadow-lg transition-all duration-200"
            onClick={handleCreateNew}
          >
            <IconAdd size="lg" />
            <Typography variant="body">{trans('candidate_analysis.custom_surveys_list.create_new')}</Typography>
          </Card>
        </div>
      </Section>
    </Section>
  );
};
