// @ts-nocheck
/* eslint-disable */

import React, {useEffect, useState} from 'react';
import {Button} from '@modules/Core/components/base/buttons/Button';
import {SERVICE_INITIAL_SOFTFACTS_ANALYSIS} from '@modules/Core/config/services';
import {logger} from '@modules/Core/util/Logger';
import {INITIAL_SOFTFACTS_ANALYSIS_FLOW} from '@modules/FlowEngine/config/flowNames';
import {useNavigateToFlow} from '@modules/FlowEngine/hooks/navigateToFlow';
import {useProfile} from '@modules/Profile/hooks/profile';
import {isServiceDoneV2} from '@modules/Profile/util/serviceUtil';
import {trans} from '@modules/Translations/util/i18n';

interface _Props {
  label?: string;
  onClick?: () => void;
}

const ToInitialSurveyButton: React.FC<_Props> = ({label, onClick}) => {
  const navigateToFlow = useNavigateToFlow();
  const [currentProfile] = useProfile();
  const [initialSurveyDone, setInitialSurveyDone] = useState(false);

  useEffect(() => {
    setInitialSurveyDone(isServiceDoneV2(SERVICE_INITIAL_SOFTFACTS_ANALYSIS));
  }, [currentProfile]);

  const handleCLick = (): void => {
    if (onClick) {
      onClick();
      return;
    }
    navigateToFlow(INITIAL_SOFTFACTS_ANALYSIS_FLOW);
  };

  if (initialSurveyDone) {
    return null;
  }

  return (
    <Button onClick={handleCLick}>
      <div className="flex flex-row items-center">
        {label ?? trans('profile_modules.locked_exercises_initial_survey_cta')}
      </div>
    </Button>
  );
};

export default ToInitialSurveyButton;
