// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {addCN} from '@/_core/util/cssHelper';
import {_TypographyVariants, Typography} from '@modules/Core/components/base/Typography';

interface _CardProps {
  title?: string;
  titlePrefix?: React.ReactNode;
  titleSuffix?: React.ReactNode;
  titleVariant?: _TypographyVariants;
  description?: string;
  descriptionVariant?: _TypographyVariants;
  className?: string;
  titleClassName?: string;
  width?: string;
  height?: string;
  gap?: number;
  padding?: number;
  onClick?: () => void;
  preChildren?: React.ReactNode;
  containerRef?: React.RefObject<HTMLDivElement>;
  children?: React.ReactNode;
  isSelected?: boolean;
  forSurvey?: boolean;
}

export const Card: React.FC<_CardProps> = ({
  onClick,
  titleClassName,
  className,
  title,
  titlePrefix,
  titleSuffix,
  titleVariant = 'body1',
  description,
  descriptionVariant = 'body',
  width,
  gap = 3,
  padding = 3,
  preChildren,
  containerRef,
  height,
  children,
  isSelected = false,
  forSurvey = false,
}) => {
  const getSelectedStyle = (): string => {
    if (forSurvey && isSelected) {
      return 'border-green700 bg-green300';
    }
    if (isSelected) {
      return 'border-blue700';
    }
    return 'border-grey300 bg-white500';
  };

  return (
    <div
      ref={containerRef}
      className={addCN(
        'flex flex-col items-start rounded-sm border-1',
        `gap-${gap}`,
        `p-${padding}`,
        className ?? '',
        onClick ? 'cursor-pointer hover:bg-grey100' : '',
        width !== null && width !== undefined ? width : 'w-full',
        height !== null && height !== undefined ? height : 'h-full',
        getSelectedStyle()
      )}
      onClick={onClick}
    >
      {preChildren}

      {(title ?? titlePrefix ?? titleSuffix) && (
        <div className={addCN('flex flex-row gap-1', titleClassName)}>
          {titlePrefix && titlePrefix}
          {title && <Typography variant={titleVariant}>{title}</Typography>}
          {titleSuffix && titleSuffix}
        </div>
      )}

      {description && (
        <div className="flex flex-col gap-2">
          <Typography variant={descriptionVariant} dangerouslySetInnerHTML={{__html: description}} />
        </div>
      )}

      {children}
    </div>
  );
};
