// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {addCN} from '@/_core/util/cssHelper';
import {IconProps, getSize} from '../types';

export const IconClock: React.FC<IconProps> = ({size = 'md', color = 'fill-grey700'}) => {
  const finSize = getSize(size);
  return (
    <div>
      <svg
        className={addCN(finSize, color, 'transition')}
        width="32"
        height="32"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M19.6566 21.8488L15.0002 17.1924V10.6155H17.0001V16.3745L21.0488 20.4232L19.6566 21.8488ZM15.0002 8.00013V5.33346H17.0001V8.00013H15.0002ZM24.0001 17.0001V15.0002H26.6668V17.0001H24.0001ZM15.0002 26.6668V24.0001H17.0001V26.6668H15.0002ZM5.33346 17.0001V15.0002H8.00013V17.0001H5.33346ZM16.0024 28.6668C14.2505 28.6668 12.6037 28.3343 11.0622 27.6694C9.5207 27.0045 8.17979 26.1022 7.0395 24.9624C5.89919 23.8226 4.99644 22.4823 4.33126 20.9415C3.66609 19.4006 3.3335 17.7543 3.3335 16.0024C3.3335 14.2505 3.66594 12.6037 4.33083 11.0622C4.99572 9.5207 5.89805 8.17979 7.03783 7.0395C8.17763 5.89919 9.51795 4.99644 11.0588 4.33126C12.5996 3.66609 14.246 3.3335 15.9979 3.3335C17.7498 3.3335 19.3965 3.66594 20.938 4.33083C22.4796 4.99572 23.8205 5.89805 24.9608 7.03783C26.1011 8.17763 27.0038 9.51795 27.669 11.0588C28.3342 12.5996 28.6668 14.246 28.6668 15.9979C28.6668 17.7498 28.3343 19.3965 27.6694 20.938C27.0045 22.4796 26.1022 23.8205 24.9624 24.9608C23.8226 26.1011 22.4823 27.0038 20.9415 27.669C19.4006 28.3342 17.7543 28.6668 16.0024 28.6668ZM16.0001 26.6668C18.9779 26.6668 21.5001 25.6335 23.5668 23.5668C25.6335 21.5001 26.6668 18.9779 26.6668 16.0001C26.6668 13.0224 25.6335 10.5001 23.5668 8.43346C21.5001 6.3668 18.9779 5.33346 16.0001 5.33346C13.0224 5.33346 10.5001 6.3668 8.43346 8.43346C6.3668 10.5001 5.33346 13.0224 5.33346 16.0001C5.33346 18.9779 6.3668 21.5001 8.43346 23.5668C10.5001 25.6335 13.0224 26.6668 16.0001 26.6668Z" />
      </svg>
    </div>
  );
};
