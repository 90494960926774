// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {addCN} from '@/_core/util/cssHelper';
import {IconProps, getSize} from '../types';

export const IconPowerSeaker: React.FC<IconProps> = ({size = 'md', color = 'fill-grey700'}) => {
  const finSize = getSize(size);
  return (
    <div>
      <svg
        className={addCN(finSize, color, 'transition')}
        width="32"
        height="32"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M23.0361 17.3718V15.6718H27V17.3718H23.0361ZM24.2886 25L21.1175 22.5416L22.1169 21.1859L25.2879 23.6444L24.2886 25ZM22.0747 11.8141L21.0753 10.4585L24.2464 8L25.2458 9.35562L22.0747 11.8141ZM9.09941 23.8667V19.4423H7.98191C7.43514 19.4423 6.96813 19.2422 6.58087 18.8419C6.19362 18.4415 6 17.9588 6 17.3936V15.65C6 15.0848 6.19362 14.6021 6.58087 14.2017C6.96813 13.8014 7.43514 13.6013 7.98191 13.6013H12.2199L17.1325 10.5718V22.4718L12.2199 19.4423H10.7439V23.8667H9.09941ZM15.488 19.4511V13.5925L12.6753 15.3013H7.98191C7.89758 15.3013 7.82027 15.3376 7.74997 15.4103C7.67969 15.4829 7.64456 15.5628 7.64456 15.65V17.3936C7.64456 17.4808 7.67969 17.5607 7.74997 17.6334C7.82027 17.706 7.89758 17.7423 7.98191 17.7423H12.6753L15.488 19.4511ZM18.6506 20.0569V12.9867C19.0807 13.3892 19.4272 13.8962 19.6901 14.5079C19.9529 15.1197 20.0843 15.7909 20.0843 16.5218C20.0843 17.2527 19.9529 17.9239 19.6901 18.5357C19.4272 19.1474 19.0807 19.6545 18.6506 20.0569Z" />
      </svg>
    </div>
  );
};
