// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {addCN} from '@/_core/util/cssHelper';
import {IconProps, getSize} from '../types';

export const IconGoogle: React.FC<IconProps> = ({size = 'md', color = 'fill-grey700'}) => {
  const finSize = getSize(size);
  return (
    <div>
      <svg
        className={addCN(finSize, color, 'transition')}
        width="25"
        height="24"
        viewBox="0 0 25 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M12.6662 10.8545H20.2172C20.2945 11.2544 20.3399 11.715 20.3419 12.1856V12.1876C20.3425 12.2316 20.3432 12.2836 20.3432 12.3356C20.3432 13.7561 19.9919 15.0946 19.372 16.2691L19.394 16.2231C18.7667 17.3977 17.8449 18.3429 16.725 18.9808L16.691 18.9988C15.5745 19.6287 14.24 20 12.8188 20C12.7648 20 12.7108 19.9993 12.6575 19.9987H12.6655C12.6568 19.9987 12.6462 19.9987 12.6362 19.9987C11.5216 19.9987 10.4611 19.7667 9.50057 19.3487L9.55123 19.3687C7.6168 18.5562 6.10899 17.0477 5.31576 15.1653L5.29643 15.1139C4.89781 14.1947 4.6665 13.1242 4.6665 11.9997C4.6665 10.8751 4.89847 9.80461 5.31642 8.8334L5.29643 8.88539C6.10899 6.95096 7.61747 5.44315 9.4999 4.64992L9.55123 4.63059C10.4604 4.23264 11.5196 4.00133 12.6335 4.00133H12.6675H12.6655C12.7048 4.00067 12.7515 4 12.7982 4C14.8279 4 16.6717 4.7979 18.0328 6.09774L18.0302 6.09507L15.8531 8.18881C15.0612 7.42091 13.98 6.94696 12.7882 6.94696C12.7448 6.94696 12.7022 6.94763 12.6588 6.94896H12.6648C12.6555 6.94896 12.6448 6.94896 12.6335 6.94896C11.7283 6.94896 10.8811 7.20093 10.1598 7.63821L10.1811 7.62621C8.684 8.53143 7.69812 10.1512 7.69812 12.0003C7.69812 13.8494 8.684 15.4692 10.1585 16.3618L10.1811 16.3745C10.8817 16.7997 11.7283 17.0517 12.6335 17.0517H12.6668H12.6648H12.7202C13.2961 17.0517 13.85 16.9604 14.3693 16.7904L14.332 16.8011C14.8092 16.6531 15.2245 16.4398 15.5938 16.1678L15.5825 16.1758C15.9064 15.9272 16.1871 15.6465 16.4277 15.3332L16.4357 15.3226C16.6437 15.0606 16.8237 14.762 16.9623 14.442L16.9723 14.416C17.0716 14.1827 17.1529 13.9101 17.2029 13.6268L17.2063 13.6035H12.6662V10.8545Z" />
      </svg>
    </div>
  );
};
